import { useEffect } from 'react';
import { getMessagePlusConfig } from '../../utils/translation';
import { useTranslation } from 'react-i18next';

const MessagePlus = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const { brand, chatWidget } = getMessagePlusConfig(t)

    if(brand?.phoneNumber && brand?.qrUrl) {
      const url = 'https://cdn.jsdelivr.net/gh/messageplusio/public@1d672144215a2e897e6521ded64dc0bde44625c9/wawidget.js';
      const script = document.createElement('script');
      
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;
  
      script.onload = () => {
        const options = {
          enabled: true,
          chatButtonSetting: {
            backgroundColor: "#00e785",
            ctaText: chatWidget?.ctaText,
            borderRadius: "25",
            marginLeft: "0",
            marginRight: "20",
            marginBottom: "20",
            ctaIconMP: false,
            position: "right",
            desktopText: chatWidget?.desktopText,
            mobileText: chatWidget?.mobileText,
          },
          brandSetting: {
            brandName: "Message Plus",
            messageText: brand?.messageText,
            backgroundColor: "#00e785",
            ctaText: brand?.ctaText,
            borderRadius: "25",
            autoShow: false,
            phoneNumber: brand?.phoneNumber,
            qrUrl: brand?.qrUrl,
            showButton: true,
          },
        };
        
        window.CreateWhatsappChatWidget(options);
      };
  
      document.body.appendChild(script);
      
      // Cleanup function to remove the script and widget when the component unmounts
      return () => {
        const scriptToRemove = document.querySelector('script[src*="wawidget.js"]');
        if (scriptToRemove) {
          document.body.removeChild(scriptToRemove);
        }
        
        const widgetToRemove = document.getElementById('whatsapp-chat-widget');
        if (widgetToRemove) {
          widgetToRemove.remove();
        }
      };
    }
  }, [t]);

  return null;
};

export default MessagePlus;
