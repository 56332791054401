const getRouteTrans = {
    'buy-cars': 'buy-cars',
    'acheter-voitures': 'buy-cars',
    'shira-alsayarat': 'buy-cars',
    'kugura-imodoka': 'buy-cars',
    'thenga-imoto': 'buy-cars',
    'thenga-iimoto': 'buy-cars',

    buy: 'buy',
    acheter: 'buy',
    shira: 'buy',
    kugura: 'buy',
    thenga: 'buy',
    goxor: 'buy',

    'sell-car': 'sell-car',
    'vendre-voiture': 'sell-car',
    'bay-alsayara': 'sell-car',
    'gura-imodoka': 'sell-car',
    'thengisa-imoto': 'sell-car',

    'car-finance': 'car-finance',
    'financement-voiture': 'car-finance',
    'tamwil-alsayarat': 'car-finance',
    'finansi-iyomodoka': 'car-finance',
    'imali-yemoto': 'car-finance',

    'car-insurance': 'car-insurance',
    'assurance-voiture': 'car-insurance',
    'taimn-alsayarat': 'car-insurance',
    'ubwishingizi-bwimodoka': 'car-insurance',
    'umshwalense-wemoto': 'car-insurance',

    about: 'about',
    'a-propos': 'about',
    hawl: 'about',
    'turi-iki': 'about',
    'mayelana-nathi': 'about',
    ngathi: 'about',
    'turu-iki': 'about',

    faqs: 'faqs',
    faq: 'faqs',
    'alasilat-almutakarrira': 'faqs',
    'ibibazo-bikunze-gusubirwamo': 'faqs',
    'imibuzo-evame-ukuphindaphindwa': 'faqs',
    'imibuzo-ebuzwayo': 'faqs',
    'ibungoro-bikunze-gusubirwamo': 'faqs',

    'contact-us': 'contact-us',
    'contactez-nous': 'contact-us',
    'aitisal-bina': 'contact-us',
    tuvugishe: 'contact-us',
    'xhumana-nathi': 'contact-us',
    'qhagamshelana-nathi': 'contact-us',

    blog: 'blog',
    madwina: 'blog',
    ibhulogi: 'blog',

    account: 'account',
    compte: 'account',
    hisab: 'account',
    konti: 'account',
    akhawunti: 'account',
    'i-akhawunti': 'account',

    press: 'press',
    presse: 'press',
    alsahafa: 'press',
    itangazamakuru: 'press',
    izindaba: 'press',
    imibhalo: 'press',

    category: 'category',
    categorie: 'category',
    fia: 'category',
    kategori: 'category',
    umkhakha: 'category',

    'privacy-policy': 'privacy-policy',
    'politique-de-confidentialite': 'privacy-policy',
    'siyasat-alkhususiya': 'privacy-policy',
    'politik-pribacy': 'privacy-policy',
    'umgomo-wobumfihlo': 'privacy-policy',

    'terms-of-use': 'terms-of-use',
    'conditions-dutilisation': 'terms-of-use',
    'shurut-alaistikhdam': 'terms-of-use',
    'amategeko-yakoreshejwe': 'terms-of-use',
    'imigomo-nokusetshenziswa': 'terms-of-use',
    'imigomo-nokusebenzisa': 'terms-of-use',

    'unsubscribe-blog': 'unsubscribe-blog',
    'se-desabonner-blog': 'unsubscribe-blog',
    'ilgha-alaishtirak-blog': 'unsubscribe-blog',
    'kureka-kwiyandikisha-mu-blog': 'unsubscribe-blog',
    'khansela-ibhulogi': 'unsubscribe-blog',

    'edit-profile': 'edit-profile',
    'modifier-profil': 'edit-profile',
    'taedil-almilaf': 'edit-profile',
    'hindura-porofayilo': 'edit-profile',
    'hlela-iphrofayela': 'edit-profile',

    'sell-your-car': 'sell-your-car',
    'vendre-votre-voiture': 'sell-your-car',
    'qum-bay-sayaratik': 'sell-your-car',
    'gura-imodoka-yawe': 'sell-your-car',
    'thengisa-imoto yakho': 'sell-your-car',

    financing: 'financing',
    financement: 'financing',
    tamwil: 'financing',
    inguzanyo: 'financing',
    mali: 'financing',
    'uxhaso-mali': 'financing',
    'imali-mboleko': 'financing',

    favourites: 'favourites',
    favoris: 'favourites',
    almufaddal: 'favourites',
    ibikundwa: 'favourites',
    dithoto: 'favourites',
    'izinto-ezithandwayo': 'favourites',
    izintandokazi: 'favourites',

    'reset-password': 'reset-password',
    'reinitialiser-mot-de-passe': 'reset-password',
    'aeadat-taein-alssir': 'reset-password',
    'subiramo-ijambo-banga': 'reset-password',
    'seta-fhorete': 'reset-password',
    'setha-iphasiwedi': 'reset-password',

    'body-type': 'body-type',
    'type-de-carrosserie': 'body-type',
    'ubwoko-bwimodoka': 'body-type',
    'uhlobo-lwemoto': 'body-type',
    'rudzi-rwemota': 'body-type',

    'buy-cars-under': 'buy-cars-under',
    'acheter-voiture-sous': 'buy-cars-under',
    'thenga-izimoto-ngaphansi-kwe': 'buy-cars-under',
    'thenga-iimoto-phantsi-kwe': 'buy-cars-under',
    'tenga-motokari-pasi-pe': 'buy-cars-under',
    'gura-imodoka-munsi-ya': 'buy-cars-under',

    'suivi-prix-carburant': 'fuel-price-tracker',
    'ikurikiranabiciro-biyagaz': 'fuel-price-tracker',
    'umkhondo-wamaxabiso-epehlo': 'fuel-price-tracker',
    'ukulandelela-amanani-petroli': 'fuel-price-tracker',
    'kutevera-mutengo-wemafuta': 'fuel-price-tracker',
    'fuel-price-tracker': 'fuel-price-tracker',

    'suivi-des-prix-de-l-electricite': 'electricity-price-tracker',
    'ikurikiranabiciro-byumuriro': 'electricity-price-tracker',
    'umkhondo-wamanani-kagesi': 'electricity-price-tracker',
    'umkhondo-wamaxabiso-ombane': 'electricity-price-tracker',
    'suivi-deretu-korenti': 'electricity-price-tracker',
    'electricity-price-tracker': 'electricity-price-tracker',

    'convertisseur-de-devises': 'currency-converter',
    'guhindura-ifaranga': 'currency-converter',
    'ukuguqula-imali': 'currency-converter',
    'uguqulelo-lwemali': 'currency-converter',
    'shanduko-yemari': 'currency-converter',
    'currency-converter': 'currency-converter',

    'convertisseur-distance': 'distance-converter',
    'guhindura-intera': 'distance-converter',
    'ukuguqula-ibanga': 'distance-converter',
    'uguqula-umgama': 'distance-converter',
    'shandura-chinhambwe': 'distance-converter',
    'distance-converter': 'distance-converter',

    'calculateur-taxe-vehicules': 'vehicle-tax-calculator',
    'umubarizi-umusoro-ku-modoka': 'vehicle-tax-calculator',
    'isibali-serhafu-yezithuthi': 'vehicle-tax-calculator',
    'isibali-sentela-yezimoto': 'vehicle-tax-calculator',
    'wannekaay-galag-u-watiir': 'vehicle-tax-calculator',

    'best-selling-cars-of-the-year': 'best-selling-cars-of-the-year',
    'njaaykat-yi-gen-a-jefandikoo-ci-annee': 'best-selling-cars-of-the-year',
    'voitures-les-plus-vendues-de-lannee': 'best-selling-cars-of-the-year',
    'imodoka-zigurishwa-cyane-mu-mwaka': 'best-selling-cars-of-the-year',
    'iimoto-ezithengiswa-kakhulu-zonyaka': 'best-selling-cars-of-the-year',
    'izimoto-ezithengiswa-kakhulu-zonyaka': 'best-selling-cars-of-the-year',

    'umubarizi-igiciro-cyo-gutwara': 'driving-cost-calculator',
    'isibali-sendleko-zokuqhuba': 'driving-cost-calculator',
    'isibali-sezindleko-zokushayela': 'driving-cost-calculator',
    'wannekaay-njegu-watu': 'driving-cost-calculator',
    'calculateur-cout-conduite': 'driving-cost-calculator',
    'driving-cost-calculator': 'driving-cost-calculator',

    'calculateur-pret-automobile': 'car-loan-calculator',
    'isibali-semali-mboleko-yemoto': 'car-loan-calculator',
    'umubarizi-inguzanyo-imodoka': 'car-loan-calculator',
    'isibali-sesikoloto-semoto': 'car-loan-calculator',
    'wannekaay-lebu-watiir': 'car-loan-calculator',
    'car-loan-calculator': 'car-loan-calculator',

    'estimateur-emissions-co2': 'co2-emission-estimator',
    'umugenagaciro-wo-gusohora-co2': 'co2-emission-estimator',
    'umlinganiseli-wokukhutshwa-kwe-co2': 'co2-emission-estimator',
    'umlinganisi-wokukhishwa-kwe-co2': 'co2-emission-estimator',
    'seetlukaay-gaz-co2': 'co2-emission-estimator',
    'co2-emission-estimator': 'co2-emission-estimator',

    'umlinganiseli-werhafu-ye-inshorensi': 'insurance-premium-estimator',
    'umugenagaciro-ubwishingizi': 'insurance-premium-estimator',
    'isilinganiso-semali-ye-inshuwa': 'insurance-premium-estimator',
    'estimateur-prime-assurance': 'insurance-premium-estimator',
    'seetlukaay-njegu-asuraas': 'insurance-premium-estimator',
    'insurance-premium-estimator': 'insurance-premium-estimator',

    'documents-techniques-des-vehicules': 'vehicle-technical-documents',
    'impapuro-za-tekiniki-zimodoka': 'vehicle-technical-documents',
    'amadokhumenti-ezobuchwepheshe-ezimoto': 'vehicle-technical-documents',
    'amaxwebhu-ezobuchwepheshe-zeemoto': 'vehicle-technical-documents',
    'dokkument-yu-teknikal-ji-motos': 'vehicle-technical-documents',
    'vehicle-technical-documents': 'vehicle-technical-documents'
};

export default getRouteTrans;
