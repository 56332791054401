import { useEffect, useState } from 'react';

import Listings from '../components/Listings';
import SearchFilter from '../components/SearchFilter';
import Layout from '../layout/Layout';

import { contentAccordToCurrentLang, SetMeta } from '../utils/Helper/jsHelpers';
import { useParams } from 'react-router-dom';
import { getBrand } from '../redux/actions/carBrandsAction';
import ListingOverviewWidget from '../components/ListingOverviewWidget';
import { IMAGE_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import useBrandAndModel from '../utils/Hooks/useBrandModel';
import { getModelBySlug } from '../redux/actions/carModelsAction';
import Notfound from './Notfound';

const Brand = () => {
    // This ensures the page scrolls to the top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();

    const [resetFilter, setResetFilter] = useState(false);

    const { brandName: URLParam } = useParams();

    const { brand, activeModelFromURL } = useBrandAndModel(
        URLParam || window.location.pathname,
        getBrand,
        getModelBySlug
    );

    const metaTitle = brand
        ? t('brandPageTitle', {
              ns: 'meta',
              BRAND: brand.brand
          })
        : 'Loading...';

    return (
        <>
            <SetMeta
                title={metaTitle}
                desc={
                    brand &&
                    t('buyPageDesc', {
                        ns: 'meta',
                        BRAND: brand.brand
                    })
                }
                keywords="buyPageKeywords"
                properties={[
                    {
                        key: 'og:title',
                        value: metaTitle
                    },
                    {
                        key: 'og:description',
                        value:
                            brand &&
                            t('buyPageDesc', {
                                ns: 'meta',
                                BRAND: brand.brand
                            })
                    },
                    {
                        key: 'og:keywords',
                        value: 'buyPageKeywordsOg'
                    }
                ]}
                image={IMAGE_URL + brand?.image}
            />
            <Layout showWhiteNavbar>
                {brand && (
                    <div className="listing-page">
                        <SearchFilter
                            resetFilter={resetFilter}
                            setResetFilter={setResetFilter}
                            activeBrand={brand?.slug}
                            activeModel={activeModelFromURL}
                        />

                        <ListingOverviewWidget
                            title={brand.brand}
                            description={
                                contentAccordToCurrentLang(brand)?.description
                            }
                            image={brand.image}
                        />
                        <Listings
                            setResetFilter={setResetFilter}
                            activeBrand={brand?.slug}
                            activeModel={activeModelFromURL}
                        />
                    </div>
                )}
                {!brand && <Notfound hideNav />}
            </Layout>
        </>
    );
};
export default Brand;
