import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFooterContent } from '../redux/actions/footerContentActions';
import { ICombineReducerType } from '../redux/reducers';
import SecSeperator from './ui/SecSeperator';

interface IFooterDetails {
    footerTitle: string;
    footerSubTitle: string;
    footerDesc: string;
}

const FooterContent = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const footerContentData = useSelector(
        (state: ICombineReducerType) => state.footerContent
    );

    const [footerContent, setFooterContent] = useState<IFooterDetails>({
        footerTitle: '',
        footerSubTitle: '',
        footerDesc: ''
    });

    useEffect(() => {
        getFooterContent(dispatch);
    }, []);

    useEffect(() => {
        let currentLang = i18n.language;
        if (footerContentData) {
            const footerDetails =
                footerContentData?.footerContent?.details.find(
                    (item) => item?.lang === currentLang
                );
            if (footerDetails) {
                setFooterContent({
                    footerTitle: footerDetails.title,
                    footerSubTitle: footerDetails.sub_title,
                    footerDesc: footerDetails.description
                });
            } else {
                setFooterContent({
                    footerTitle:
                        footerContentData?.footerContent?.details[0].title ??
                        '',
                    footerSubTitle:
                        footerContentData?.footerContent?.details[0]
                            .sub_title ?? '',
                    footerDesc:
                        footerContentData?.footerContent?.details[0]
                            .description ?? ''
                });
            }
        }
    }, [footerContentData, i18n.language]);

    return (
        <>
            {footerContentData && (
                <div className="press">
                    <div className="padding">
                        <SecSeperator className="center" />
                        <h2
                            className="title"
                            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                        >
                            {footerContent.footerTitle}
                        </h2>
                        <div
                            className="subtitle"
                            dangerouslySetInnerHTML={{
                                __html: footerContent.footerSubTitle
                            }}
                            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                        />
                        <div
                            className="subtitle"
                            dangerouslySetInnerHTML={{
                                __html: footerContent.footerDesc
                            }}
                            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default FooterContent;
