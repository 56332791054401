import { useEffect, useState } from 'react';

import Listings from '../components/Listings';
import SearchFilter from '../components/SearchFilter';
import Layout from '../layout/Layout';

import { contentAccordToCurrentLang, SetMeta } from '../utils/Helper/jsHelpers';
import ListingOverviewWidget from '../components/ListingOverviewWidget';
import { useParams } from 'react-router-dom';
import { getBodyType } from '../redux/actions/carBodyTypeAction';
import { IMAGE_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import Notfound from './Notfound';

const BodyType = () => {
    // This ensures the page scrolls to the top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();

    const [resetFilter, setResetFilter] = useState(false);

    const { bodyTypeName } = useParams();
    const [bodyType, setBodyType] = useState<any>(null);

    useEffect(() => {
        getBodyType(bodyTypeName).then((bodyType: any) => {
            setBodyType(bodyType?.data?.carBodyType);
        });
    }, [bodyTypeName]);

    const metaTitle = bodyType
        ? t('bodyTypePageTitle', {
              ns: 'meta',
              BODY_TYPE: contentAccordToCurrentLang(bodyType)?.bodyType
          })
        : 'Loading...';

    return (
        <>
            <SetMeta
                title={metaTitle}
                desc={
                    bodyType &&
                    t('buyPageDesc', {
                        ns: 'meta',
                        BRAND: contentAccordToCurrentLang(bodyType)?.bodyType
                    })
                }
                keywords="buyPageKeywords"
                properties={[
                    {
                        key: 'og:title',
                        value:
                            bodyType &&
                            contentAccordToCurrentLang(bodyType)?.bodyType
                    },
                    {
                        key: 'og:description',
                        value:
                            bodyType &&
                            t('buyPageDesc', {
                                ns: 'meta',
                                BRAND: contentAccordToCurrentLang(bodyType)
                                    ?.bodyType
                            })
                    },
                    {
                        key: 'og:keywords',
                        value: 'buyPageKeywordsOg'
                    }
                ]}
                image={IMAGE_URL + bodyType?.image}
            />
            <Layout showWhiteNavbar>
                {bodyType && (
                    <div className="listing-page">
                        <SearchFilter
                            resetFilter={resetFilter}
                            setResetFilter={setResetFilter}
                        />

                        <ListingOverviewWidget
                            title={
                                contentAccordToCurrentLang(bodyType)?.bodyType
                            }
                            description={
                                contentAccordToCurrentLang(bodyType)
                                    ?.description
                            }
                            image={bodyType.image}
                        />

                        <Listings setResetFilter={setResetFilter} />
                    </div>
                )}
                {!bodyType && <Notfound hideNav />}
            </Layout>
        </>
    );
};
export default BodyType;
