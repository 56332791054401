import React from 'react';
import { useTranslation } from 'react-i18next';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const BestSellingHero = ({ bestSellerYear }: { bestSellerYear: number }) => {
    const { t } = useTranslation();

    return (
        <div className="best-seller-hero">
            <div className="padding">
                <div className="best-seller-hero-content">
                    <h6>{t('topCarSales')}</h6>
                    <h2>
                        {t('bestSellingCarsOfYear', {
                            CurrentYear: bestSellerYear
                        })}
                    </h2>
                    <span>
                        {t('discoverBestSellingCarsOfYear', {
                            CurrentYear: bestSellerYear
                        })}
                    </span>
                </div>
                <img
                    src="/images/hero/best-seller.webp"
                    alt="Best Selling cars preview"
                    onError={addImageFallback}
                    height={430}
                />
            </div>
        </div>
    );
};

export default BestSellingHero;
