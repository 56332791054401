const metaXhosa = {
    homePageTitle:
        'AUTO24.co.za - Gesertifiseerde Gebruikte Motors in Suid-Afrika | Koop & Verkoop Plaaslik',
    homePageDesc:
        "Soek jy 'n gesertifiseerde gebruikte motor in Suid-Afrika? AUTO24.co.za is jou plaaslike mark vir die koop en verkoop van kwaliteit gebruikte motors.",
    homePageKeywords:
        'Gesertifiseerde Gebruikte Motors, Koop Motors Suid-Afrika, Verkoop Motors, Motor Mark, AUTO24 Suid-Afrika',
    homePageTitleOg:
        'AUTO24.co.za - Gesertifiseerde Gebruikte Motors in Suid-Afrika | Koop & Verkoop Plaaslik',
    homePageDescOg:
        "Soek jy 'n gesertifiseerde gebruikte motor in Suid-Afrika? AUTO24.co.za is jou plaaslike mark vir die koop en verkoop van kwaliteit gebruikte motors.",
    homePageKeywordsOg:
        'Gesertifiseerde Gebruikte Motors, Koop Motors Suid-Afrika, Verkoop Motors, Motor Mark, AUTO24 Suid-Afrika',
    buyPageTitle:
        'Iimoto Ezithengiswayo eNingizimu Afrika | Fumana amaxabiso aphezulu kwiimoto namhlanje - auto24.co.za',
    buyPageDesc:
        "Verken 'n wye reeks gesertifiseerde gebruikte {{ BRAND }} motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    buyCarKeywords:
        'Koop Motors, Gesertifiseerde Gebruikte Motors, Kwaliteit Motors, Bekostigbare Motors',
    buyPageTitleOg:
        'Iimoto Ezithengiswayo eNingizimu Afrika | Fumana amaxabiso aphezulu kwiimoto namhlanje - auto24.co.za',
    buyPageDescOg:
        "Verken 'n wye reeks gesertifiseerde gebruikte {{ BRAND }} motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    buyCarKeywordsOg:
        'Koop Motors, Gesertifiseerde Gebruikte Motors, Kwaliteit Motors, Bekostigbare Motors',
    sellPageTitle:
        'Thengisa Imoto Yakho Ngokukhawuleza & Ngokuqhagamshelana Kalula | Fumana Izicelo Zomxhelo Ngqo Namhlanje - auto24.co.za',
    sellPageDesc:
        'Ufuna ukuthengisa imoto yakho? Fumana isikhokelo semali ngokukhawuleza ku-auto24.co.za! Ngendlela esheshayo nefanelekileyo, ungathengisa imoto yakho namhlanje kwaye ufumane imali ngokukhawuleza. Sukuba ulinde—funda ixabiso lemoto yakho ngoku!',
    sellPageKeywords:
        'Verkoop Motors, Gebruikte Motor Verkope, Vinnige Verkope, Motor Evaluering',
    sellPageTitleOg:
        'Thengisa Imoto Yakho Ngokukhawuleza & Ngokuqhagamshelana Kalula | Fumana Izicelo Zomxhelo Ngqo Namhlanje - auto24.co.za',
    sellPageDescOg:
        'Ufuna ukuthengisa imoto yakho? Fumana isikhokelo semali ngokukhawuleza ku-auto24.co.za! Ngendlela esheshayo nefanelekileyo, ungathengisa imoto yakho namhlanje kwaye ufumane imali ngokukhawuleza. Sukuba ulinde—funda ixabiso lemoto yakho ngoku!',
    sellPageKeywordOg:
        'Verkoop Motors, Gebruikte Motor Verkope, Vinnige Verkope, Motor Evaluering',
    aboutPageTitle:
        'Oor AUTO24.co.za - Jou Betroubare Plaaslike Gebruikte Motor Mark',
    aboutPageDesc:
        'Leer meer oor AUTO24.co.za, Suid-Afrika se voorste gesertifiseerde gebruikte motor mark.',
    aboutPageKeywords:
        'Oor AUTO24, Gesertifiseerde Gebruikte Motor Mark, Betroubare Motor Verkope',
    aboutPageTitleOg:
        'Oor AUTO24.co.za - Jou Betroubare Plaaslike Gebruikte Motor Mark',
    aboutPageDescOg:
        'Leer meer oor AUTO24.co.za, Suid-Afrika se voorste gesertifiseerde gebruikte motor mark.',
    aboutPageKeywordsOg:
        'Oor AUTO24, Gesertifiseerde Gebruikte Motor Mark, Betroubare Motor Verkope',
    financePageTitle:
        'Iimali Zemoto Zenziwe Zilula | 100% Elinezinga Eliphakathi kwezeMali - auto24.co.za',
    financePageDesc:
        'Phonononga ukufumana imali yemoto ngaphandle kwemingeni kunye ne- Auto24.co.za - 100% kwi-intanethi, amaxabiso aphakathi kunye nemigomo enokuguquguquka. Fumana imoto yakho yomphupha namhlanje!',
    financePageKeywords:
        'Motor Finansiering, AUTO24 Finansiering, Maklike Finansiering',
    financePageTitleOg:
        'Iimali Zemoto Zenziwe Zilula | 100% Elinezinga Eliphakathi kwezeMali - auto24.co.za',
    financePageDescOg:
        'Phonononga ukufumana imali yemoto ngaphandle kwemingeni kunye ne- Auto24.co.za - 100% kwi-intanethi, amaxabiso aphakathi kunye nemigomo enokuguquguquka. Fumana imoto yakho yomphupha namhlanje!',
    financePageKeywordOg:
        'Motor Finansiering, AUTO24 Finansiering, Maklike Finansiering',
    insurePageTitle:
        'Qinisekisa Umshwalense Weemoto, Ukuvikeleka Okungenangxaki KwiXabiso Eliphezulu - auto24.co.za',
    insurePageDesc:
        'Fumana umshwalense wemoto ophumelelayo ngexabiso elihle ku-auto24.co.za. Sinikezela ngokuvikeleka okungenangxaki okuhlangabezana nezidingo zakho—vikele imoto yakho namhlanje!',
    insurePageKeywords:
        'Motor Versekering, AUTO24 Versekering, Voertuig Dekking',
    insurePageTitleOg:
        'Qinisekisa Umshwalense Weemoto, Ukuvikeleka Okungenangxaki KwiXabiso Eliphezulu - auto24.co.za',
    insurePageDescOg:
        'Fumana umshwalense wemoto ophumelelayo ngexabiso elihle ku-auto24.co.za. Sinikezela ngokuvikeleka okungenangxaki okuhlangabezana nezidingo zakho—vikele imoto yakho namhlanje!',
    insurePageKeywordOg:
        'Motor Versekering, AUTO24 Versekering, Voertuig Dekking',
    blogPageTitle:
        'Iingcebiso Zokugcinwa Kweemoto kunye nezeMveliso Zokuvelisa Iimoto - auto24.co.za',
    blogPageDesc:
        'Vakashela auto24.co.za ukufumana iingcebiso ezivela kubachwephesha zokugcinwa kweemoto, iimeko zezimakethe kunye neendaba zezeMveliso. Hlala uphambili ekugcinweni kweemoto kunye nophuculo lwezithuthi!',
    blogPageKeywords:
        'AUTO24 Blog, Motor Wenke, Motor Nuus, Suid-Afrikaanse Motor Mark',
    blogPageTitleOg:
        'Iingcebiso Zokugcinwa Kweemoto kunye nezeMveliso Zokuvelisa Iimoto - auto24.co.za',
    blogPageDescOg:
        'Vakashela auto24.co.za ukufumana iingcebiso ezivela kubachwephesha zokugcinwa kweemoto, iimeko zezimakethe kunye neendaba zezeMveliso. Hlala uphambili ekugcinweni kweemoto kunye nophuculo lwezithuthi!',
    blogPageKeywordsOg:
        'AUTO24 Blog, Motor Wenke, Motor Nuus, Suid-Afrikaanse Motor Mark',
    qNaPageTitle: 'AUTO24- Imibuzo ebuzwa qho',
    contactUsPageTitle: 'AUTO24- Qhagamshelana nathi',
    privacyPolicyPageTitle: 'AUTO24- Umthetho wabucala',
    termsNusePageTitle: 'AUTO24- Imimmiselo nemiqathango',
    carDetailPageTitle: 'Thenga {{make}}, {{price}} ZAR - AUTO24',
    carDetailPageDesc:
        'Thenga {{make}} imoto esetyenzisiweyo eqinisekisiweyo kwi {{PRIX}} ZAR nge-AUTO24, eMzantsi Afrika',
    buyPageDescWhenBrand:
        'Amaxabiso angcono kunye neenkonzo ze {{BRAND}} iimoto ezisetyenzisiweyo eMzantsi Afrika. Ipolisi yembuyekezo,iwaranti, i-inshurensi,ukugcinwa kunye nazo zonke zibandakanyiwe!',
    buyCarTitle:
        'Koop Gesertifiseerde Kwaliteit Gebruikte Motors in Suid-Afrika | AUTO24.co.za',
    buyCarDesc:
        "Verken 'n wye reeks gesertifiseerde gebruikte motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    carFinanceFormPageTitle: `Fumana ikhwelela yakho yemoto ethengiselwe ngu-AUTO24.co.za`,
    carFinanceFormPageDesc: `Fumana izicelo ezimbalwa zokufumana ikhwelela yakho yemoto ethengiselwe ngu-AUTO24.co.za, uqinisekise ukuba uxhumane nabasolwa bethu xa ufuna usizo! Isicelo esiqinile!`,
    carFinanceFormPageTitleOg: `Fumana ikhwelela yakho yemoto ethengiselwe ngu-AUTO24.co.za`,
    carFinanceFormPageDescOg: `Fumana izicelo ezimbalwa zokufumana ikhwelela yakho yemoto ethengiselwe ngu-AUTO24.co.za, uqinisekise ukuba uxhumane nabasolwa bethu xa ufuna usizo! Isicelo esiqinile!`,
    brandPageTitle:
        '{{ BRAND }} izithuthi zithengiswa Suid-Afrika | Fumana ixabiso elifanelekileyo kwi-AUTO24 Suid-Afrika',
    bodyTypePageTitle:
        '{{ BODY_TYPE }} izithuthi zithengiswa Suid-Afrika | Fumana ixabiso elifanelekileyo kwi-AUTO24 Suid-Afrika',
    carsUnderPageTitle:
        'Thenga izithuthi ezingaphantsi kwe-{{ PRICE }} Suid-Afrika | Fumana ixabiso elifanelekileyo kwi-AUTO24 Suid-Afrika',
    carsUnderPageDesc:
        "Verken 'n wye reeks gesertifiseerde gebruikte motors under {{ PRICE }} by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    fuelPriceTrackerTitle:
        'Amaxabiso amatsha e-fuel Suid-Afrika - AUTO24.co.za',
    fuelPriceTrackerDescMeta:
        'Hlala usazi ngexabiso lamafutha Suid-Afrika. Jonga amaxabiso epetroli, idizili, neLPG ngoku',
    fuelPriceTrackerKeywords:
        'Amaxabiso amatsha e-fuel Suid-Afrika, iindaba zamafutha Suid-Afrika, umkhondo wamaxabiso e-fuel, uthelekiso lwamaxabiso e-fuel, ixabiso lamafutha eSuid-Afrika 2025',
    electricityPriceTrackerTitle:
        'Amaxabiso Amva nje Ombane eSuid-Afrika - AUTO24.co.za',
    electricityPriceTrackerDescMeta:
        'Hlala uhlaziywa ngamaxabiso ombane amva nje eSuid-Afrika. Landela amaxabiso ombane, jonga ukutshintsha kwamaxabiso, kwaye ulawule iindleko zakho zamandla ngokufanelekileyo.',
    electricityPriceTrackerKeywords:
        'Amaxabiso ombane amva nje eSuid-Afrika, uhlaziyo lwamaxabiso ombane, isixhobo sokulandela amaxabiso ombane, uthelekiso lweendleko zamandla, amaxabiso ombane eSuid-Afrika 2025',
    currencyConverterTitle:
        'Isiguquli semali - Amanani otshintshiselwano aphilayo namhlanje e {{ Country_name }} - Auto24.co.za',
    currencyConverterDescMeta:
        'Bala amanani otshintshiselwano emali kwi-Intanethi usebenzisa i-Auto24 Currency Converter e {{ Country_name }}. Guqula phakathi kweemali ezinkulu zehlabathi usebenzisa esi sixhobo kwaye ubone amaxabiso aphilayo.',
    currencyConverterKeywords:
        'Amaxabiso otshintshiselwano aphilayo, Isibali-mali se-Forex, Amanani otshintshiselwano lwemali, Isiguquli semali se-AUTO 24, Amaxabiso otshintshiselwano angcono, Amanani e-Forex, Isixhobo sotshintshiselwano lwemali e {{ Country_name }}',
    distanceConverterTitle: '{{ Name }} - Auto24.co.za',
    distanceConverterDescMeta:
        '{{ title }} e {{ Country_name }}. {{ howToConvert }} lula',
    distanceConverterKeywords:
        '{{ title }}. Guqula {{ op1 }} no {{ op2 }} e {{ Country_name }}',
    emissionCalculatorTitle:
        'Isibali seGesi ye-CO2 e-{{ Country_name }} – Linganisa Umkhondo Wakho Wekhabhoni - Auto24.co.za',
    emissionCalculatorDescMeta:
        'Sebenzisa isibali sethu segesi ye-CO2 e-{{ Country_name }} ukuze uqikelele umkhondo wakho wekhabhoni. Bala izikhuphi ezisuka kwezothutho, ukusetyenziswa kwamandla, kunye nemisebenzi yemihla ngemihla ukunciphisa impembelelo kokusingqongileyo.',
    emissionCalculatorKeywords:
        'Isibali segesi ye-CO2 {{ Country_name }}, isibali somkhondo wekhabhoni {{ Country_name }}, izikhuphi zegesi ezifudumeza umhlaba {{ Country_name }}, ukulandela i-CO2 {{ Country_name }}, ukuncitshiswa kwezikhuphi {{ Country_name }}',
    bestSellerTitle:
        'Iimoto ezithengiswa kakhulu zonyaka {{ CurrentYear }} e Suid-Afrika - Auto24.co.za',
    bestSellerDescMeta:
        'Fumanisa iimoto ezithengiswa kakhulu zonyaka {{ CurrentYear }} e Suid-Afrika. Phonononga iimoto eziphezulu ngokusekelwe kwiithengiso, ukusebenza, kunye neengxelo zabathengi. Fumana imoto yakho elandelayo ne-Auto24.ma namhlanje!',
    bestSellerKeywords:
        'Iimoto ezithengiswa kakhulu {{ CurrentYear }}, Iimoto eziphezulu {{ CurrentYear }}, iimoto ezithandwayo {{ CurrentYear }}, Iimoto ezilungileyo e Suid-Afrika, Iimoto ezithengiswa kakhulu e Suid-Afrika, Uluhlu lweemoto zonyaka {{ CurrentYear }}, Iimoto ezilungileyo e Suid-Afrika, Imarike yemoto {{ CurrentYear }}, Idatha yokuthengisa iimoto e Suid-Afrika',
    carLoanCalculatorTitle:
        'Isibali Semali-mboleko Yemoto Suid-Afrika - Bala Iintlawulo Zakho Zenyanga Nenzala - Auto24.co.za',
    carLoanCalculatorDescMeta:
        'Sebenzisa isibali sethu semali-mboleko yemoto ukubala iintlawulo zakho zenyanga Suid-Afrika, inzala epheleleyo, kunye neendleko ezipheleleyo zemali-mboleko. Ceba ukuthenga imoto yakho elandelayo Suid-Afrika ngokungazingisi kwaye ufumane imali-mboleko engcono kuhlahlo-lwabiwo mali lwakho',
    carLoanCalculatorKeywords:
        'Isibali semali-mboleko yemoto, Isibali semali-mboleko yesithuthi, Ukuxhaswa kwezithuthi, Inzala yemali-mboleko yemoto, Intlawulo yenyanga yemoto, Ukuhlawulwa kwemali-mboleko, Isixhobo sokuxhasa imoto, EMI yemali-mboleko yemoto, Isibali semali-mboleko yemoto'
};

export default metaXhosa;
