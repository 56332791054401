import React, { useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import { SetMeta } from '../utils/Helper/jsHelpers';
import { useTranslation } from 'react-i18next';
import BestSellingHero from '../components/BestSellingHero';
import BestSellingCarsShowcase from '../components/BestSellingCarsShowcase';
import BestSellingCarsList from '../components/BestSellingCarsList';
import {
    CarSale,
    CarSalesData,
    getBestSellingCars
} from '../redux/actions/bestSellingCarsAction';
import AllToolsLink from '../components/AllToolsLink';
import RecentlyAdded from '../components/RecentlyAdded';
import FooterContent from '../components/FooterContent';
import NeedHelp from '../components/NeedHelp';
import { getCars } from '../redux/actions/carActions';
import { useDispatch, useSelector } from 'react-redux';
import { ICombineReducerType } from '../redux/reducers';
import { getCountryNameFromDomain } from '../utils/translation';

const BestSellingCars = () => {
    const [bestSellingCars, setBestSellingCars] = useState<CarSale[]>([]);
    const [bestSellerYear, setBestSellerYear] = useState<number | null>(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        getCars(dispatch, { page: 1, limit: 6 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { cars: recentlyAdded } = useSelector(
        (state: ICombineReducerType) => state.car
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getBestSellingCars()
            .then((data: CarSalesData) => {
                setBestSellingCars(data?.car_sales);
                setBestSellerYear(data.year);
            })
            .catch((error) => {
                console.error('Error fetching best selling cars:', error);
            });
    }, []);

    return (
        <>
            <SetMeta
                title={t('bestSellerTitle', {
                    ns: 'meta',
                    CurrentYear: bestSellerYear
                })}
                desc={t('bestSellerDescMeta', {
                    ns: 'meta',
                    CurrentYear: bestSellerYear
                })}
                keywords={t('bestSellerKeywords', {
                    ns: 'meta',
                    CurrentYear: bestSellerYear
                })}
                properties={[
                    {
                        key: 'og:title',
                        value: t('bestSellerTitle', {
                            ns: 'meta',
                            CurrentYear: bestSellerYear
                        })
                    },
                    {
                        key: 'og:description',
                        value: t('bestSellerDescMeta', {
                            ns: 'meta',
                            CurrentYear: bestSellerYear
                        })
                    },
                    {
                        key: 'og:keywords',
                        value: t('bestSellerKeywords', {
                            ns: 'meta',
                            CurrentYear: bestSellerYear
                        })
                    }
                ]}
            />
            <Layout showWhiteNavbar>
                <BestSellingHero bestSellerYear={bestSellerYear!} />
                <div className="padding">
                    {bestSellingCars.length > 0 && (
                        <div className="best-selling-container">
                            <BestSellingCarsShowcase
                                bestSellingCars={bestSellingCars.slice(0, 3)}
                            />
                            <BestSellingCarsList
                                bestSellingCars={bestSellingCars}
                                bestSellerYear={bestSellerYear!}
                            />
                        </div>
                    )}
                    <div className="tracker-content best-selling-cars-content">
                        <div
                            className="tracker-content-data"
                            dangerouslySetInnerHTML={{
                                __html: t('bestSellingCarsContent', {
                                    ns: 'content'
                                })
                            }}
                        />
                    </div>
                </div>

                <AllToolsLink />
                <div className="tracker-layout-cars">
                    <RecentlyAdded
                        title="recentlyAdded"
                        subtitle="recentlyAddedSubtitle"
                        carData={recentlyAdded.cars}
                        total={recentlyAdded.total}
                    />
                </div>
                <FooterContent />
                <NeedHelp />
            </Layout>
        </>
    );
};

export default BestSellingCars;
