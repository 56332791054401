import { useEffect, useState } from 'react';

const useBrandAndModel = (
    url: string,
    getBrand: (brandName: string) => Promise<any>,
    getModelBySlug: (modelName: string) => Promise<any>
) => {
    const [brand, setBrand] = useState<any>(null);
    const [activeModelFromURL, setActiveModelFromURL] = useState<string>('');

    useEffect(() => {
        let foundBrand = false;

        const fetchBrand = async () => {
            const response = await getBrand(url);

            if (response?.data?.carBrand) {
                setBrand(response.data.carBrand);
                setActiveModelFromURL('');
                foundBrand = true;
            } else {
                const response = await getModelBySlug(url);
                if (response?.data?.carModel) {
                    setBrand(response.data.carModel.brand);
                    setActiveModelFromURL(response.data.carModel.slug);
                    foundBrand = true;
                }
            }

            if (!foundBrand) {
                setBrand(null);
                setActiveModelFromURL('');
            }
        };

        fetchBrand();
    }, [url, getBrand, getModelBySlug]);

    return { brand, activeModelFromURL };
};

export default useBrandAndModel;
