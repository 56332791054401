import { ALL_LANGUAGES } from '../../constants';
import contentArabic from './arabic';
import contentEnglish from './english';
import contentFrench from './french';
import contentKinyarnwanda from './kinyarnwanda';
import contentXhosa from './xhosa';
import contentZulu from './zulu';
import contentWolof from './wolof';
import { getTopLevelDomain } from '../../utils/translation';

const topLevelDomain = getTopLevelDomain();

const contentLang = {
    [ALL_LANGUAGES.english.value]: contentEnglish(topLevelDomain),
    [ALL_LANGUAGES.french.value]: contentFrench(topLevelDomain),
    [ALL_LANGUAGES.arabic.value]: contentArabic,
    [ALL_LANGUAGES.zulu.value]: contentZulu,
    [ALL_LANGUAGES.xhosa.value]: contentXhosa,
    [ALL_LANGUAGES.kinyarnwanda.value]: contentKinyarnwanda,
    [ALL_LANGUAGES.wolof.value]: contentWolof
};

export default contentLang;
