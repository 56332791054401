import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../constants';
import { NavLink, useLocation } from 'react-router-dom';
import { CaretRight } from 'phosphor-react';

const AllToolsLink = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const linkList = [
        {
            label: t('fuelPriceTracker'),
            link: t(`${ROUTES.fuelPriceTracker}`, {
                ns: 'routes'
            }),
            icon: '/icon/fuel-price-tracker.svg'
        },
        {
            label: t('electricityPriceTracker'),
            link: t(`${ROUTES.electricityPriceTracker}`, {
                ns: 'routes'
            }),
            icon: '/icon/electricity-price-tracker.svg'
        },
        {
            label: t('miToKmConverter'),
            link: t(`${ROUTES.distanceConverter}`, {
                ns: 'routes'
            }),
            icon: '/icon/mi-to-km.svg'
        },
        {
            label: t('vehicleTaxCalculator'),
            link: '#',
            // link: ROUTES.taxCalculator,
            icon: '/icon/tax-calculator.svg'
        },
        {
            label: t('currencyConverter'),
            link: t(`${ROUTES.currencyConverter}`, {
                ns: 'routes'
            }),
            icon: '/icon/currency-converter.svg'
        },
        {
            label: t('topCarSales'),
            link: t(`${ROUTES.topCarSales}`, {
                ns: 'routes'
            }),
            icon: '/icon/top-car-sales.svg'
        },
        {
            label: t('drivingCostCalculator'),
            link: t(`${ROUTES.drivingCostCalculator}`, {
                ns: 'routes'
            }),
            icon: '/icon/driving-cost-calculator.svg'
        },
        {
            label: t('emissionEstimator'),
            link: t(`${ROUTES.emissionEstimator}`, {
                ns: 'routes'
            }),
            icon: '/icon/co2-emission.svg'
        },
        {
            label: t('insuranceEstimator'),
            // link: ROUTES.insuranceEstimator,
            link: '#',
            icon: '/icon/insurance-estimator.svg'
        },
        {
            label: t('carLoanCalculator'),
            link: t(`${ROUTES.carLoanCalculator}`, {
                ns: 'routes'
            }),
            icon: '/icon/car-loan-calculator.svg'
        }
    ];

    const filteredLinks = linkList
        .filter((item) => {
            if (item.link === '#') return true;

            const currentPath = location.pathname.replace(/^\//, '');
            const itemPath = item.link.replace(/^\//, '');

            return !currentPath.includes(itemPath);
        })
        .sort((a, b) => {
            return t(a.label).localeCompare(t(b.label));
        });

    return (
        <div className="all-tools-container padding">
            <h4>{t('exploreOtherTools')}</h4>
            <div className="tools-card-wrapper">
                {filteredLinks.map(
                    (item, idx) =>
                        item.link !== '#' && (
                            <NavLink
                                to={item.link}
                                className={`${
                                    item.link === '#' &&
                                    'tool-link-card-disabled'
                                } tool-link-card`}
                            >
                                <div className="tools-desc">
                                    <img src={item.icon} alt={item.label} />
                                    <span>{item.label}</span>
                                </div>
                                <div className="tools-arrow">
                                    <CaretRight weight="bold" />
                                </div>
                            </NavLink>
                        )
                )}
            </div>
        </div>
    );
};

export default AllToolsLink;
