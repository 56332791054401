const text_truncate = (
    str: string | null,
    length: number | null,
    ending: string | null
) => {
    if (length == null) {
        length = 10;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str && str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};
export default text_truncate;
