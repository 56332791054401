import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translation from './translation';
import { DEFAULT_LANG } from './utils/translation';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: translation,
        backend: {
            // translation file path
            loadPath: '/locale/{{ns}}/{{lng}}.json'
        },
        debug: false,
        defaultNS: 'common',
        // fallbackLng: 'fr',
        fallbackLng: DEFAULT_LANG,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        detection: {
            order: ['path', 'localStorage', 'cookie', 'htmTag', 'subdomain'],
            caches: ['localStorage'],
            lookupPath: 'locale'
        },
        react: {
            useSuspense: true
        }
    });

export default i18n;
