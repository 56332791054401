import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Form, InputNumber, Select, Slider } from 'antd';

import { formatMoney } from './SearchFilter';
import GetACallBack from './ui/GetACallBack';
import SecSeperator from './ui/SecSeperator';
import { COUNTRY_TOP_LEVEL_DOMAIN } from '../constants';
import { getTopLevelDomain } from '../utils/translation';

const BANK_LOGO_ARR = [
    "/images/logos/wesbank.webp",
    "/images/logos/absa.svg",
    "/images/logos/standardbank.webp",
    "/images/logos/marquis.png",
    "/images/logos/mfc.webp",
    "/images/logos/fnb.jpg",
]

const minDownPayment = 100000;
const maxDownPayment = 20000000;
// const downPaymentIncrBy = 100000;
const downPaymentIncrBy = 1000;

const minInterest = 5;
const maxInterest = 20;

export const sliderFormatter = (value: any) => {
    const topLevelDomain = getTopLevelDomain();
    return `${topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''} ${formatMoney(value)} ${topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}`;
  };
interface ILoanCalculatorProps {
    carPrice?: number | null;
    downPayment?: number;
    interestRate?: number;
    timeFrame?: number;
    carId?: string;
}

interface ILoanData {
    carPrice: number | null;
    downpayment: number | null;
    interestRate: number | null;
    timeFrame: number | null;
}

const LoanCalculator = (props: ILoanCalculatorProps) => {
    const [loanCalculationForm] = Form.useForm();
    const [monthlyRepayment, setMonthlyRepayment] = useState<number>(0);
    const [interestRate, setInterestRate] = useState<number>(0);
    const [totalRepayment, setTotalRepayment] = useState<number>(0);

    const [loanData, setLoanData] = useState<ILoanData>({
        carPrice: props.carPrice ?? null,
        downpayment: props.downPayment ?? minDownPayment,
        interestRate: props.interestRate ?? null,
        timeFrame: props.timeFrame ?? null
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();
    const topLevelDomain = getTopLevelDomain();

    const handleDataChange = (name: string, value: string | number | null) => {
        setLoanData({ ...loanData, [name]: value });
    };

    const showModal = (e: any) => {
        loanCalculationForm
            .validateFields()
            .then(() => setIsModalVisible(true))
            .catch(() => {});
        e.preventDefault();
    };

    const onFinish = (values: any) => {
        let p = loanData.downpayment || 0;
        let r = values['Interest Rate'] / 100 / 12;
        let t = values['Loan Tenure'];
        let emi = (p * r * Math.pow(1 + r, t)) / (Math.pow(1 + r, t) - 1);
        let total = emi * t;
        setMonthlyRepayment(parseInt(emi.toFixed(0)));
        setInterestRate(values['Interest Rate']);
        setTotalRepayment(parseInt(total.toFixed(0)));
    };

    return (
        <div className="loan-calculator padding" id="#loan-calculator">
            <SecSeperator />
            <h1 className="title">{t('loanCalculatorTitle')}</h1>
            <p className="subtitle">{t('loadCalculatorSubtitle')}</p>
            { topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza &&
            <div className='bankLogos'>
                {
                    BANK_LOGO_ARR.map((item, index) => (
                        <img
                            src={item}
                            alt={`${index}`}
                            style={{
                                scale: item.includes("marquis") ? "1.5" : "1"
                            }}
                        />
                    ))
                }
            </div>
            }
            <div className="form-container">
                <div className="form-inputs">
                    <Form form={loanCalculationForm} onFinish={onFinish}>
                        <div className="form-input-container">
                            <label>
                                <span className="label-title">
                                    {t('downPayment')}
                                </span>
                            </label>
                            <div className="loan-range-cal slider-values">
                                <div className="min-value-container">
                                    <span>{t('min')}</span>
                                    <p>
                                        <span id="min-cal">
                                            {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}{' '}
                                            {loanData.carPrice &&
                                            loanData.carPrice < minDownPayment
                                                ? minDownPayment
                                                : formatMoney(
                                                      minDownPayment
                                                  )}{' '}
                                            {topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}
                                        </span>
                                    </p>
                                </div>
                                <p>
                                    <span id="loan-value-cal">
                                        {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''} {formatMoney(loanData.downpayment)} {topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}
                                    </span>
                                </p>
                                <div className="max-value-container">
                                    <span>{t('max')}</span>
                                    <p>
                                        <span id="max-cal">
                                            {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''} {' '}
                                            {loanData.carPrice ??
                                                formatMoney(
                                                    maxDownPayment
                                                )}{' '}
                                            {topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <Form.Item name="Down Payment">
                                <Slider
                                    className="loan-slider"
                                    min={
                                        loanData?.carPrice &&
                                        loanData.carPrice < minDownPayment
                                            ? 0
                                            : minDownPayment
                                    }
                                    max={loanData.carPrice ?? maxDownPayment}
                                    step={downPaymentIncrBy}
                                    defaultValue={0}
                                    onChange={(e) =>
                                        handleDataChange('downpayment', e)
                                    }
                                    tooltip={
                                        {
                                            formatter: sliderFormatter

                                        }
                                    }
                                />
                            </Form.Item>
                        </div>
                        <div className="form-input-container">
                            <label>
                                <span className="label-title">
                                    {t('loanTenure')}
                                </span>
                            </label>
                            <Form.Item
                                name="Loan Tenure"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    size="large"
                                    placeholder={t('selectLoanTenure')}
                                    onChange={(e) =>
                                        handleDataChange('timeFrame', e)
                                    }
                                    options={[
                                        {
                                            value: 12,
                                            label: `12 ${t('months')}`
                                        },
                                        {
                                            value: 24,
                                            label: `24 ${t('months')}`
                                        },
                                        {
                                            value: 36,
                                            label: `36 ${t('months')}`
                                        },
                                        {
                                            value: 48,
                                            label: `48 ${t('months')}`
                                        },
                                        {
                                            value: 60,
                                            label: `60 ${t('months')}`
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="form-input-container">
                            <label>
                                <span className="label-title">
                                    {t('interestRate')}
                                </span>
                            </label>
                            <Form.Item
                                name="Interest Rate"
                                rules={[
                                    {
                                        required: true,
                                        min: minInterest,
                                        max: maxInterest,
                                        type: 'number'
                                    }
                                ]}
                            >
                                <InputNumber
                                    size="large"
                                    addonAfter="%"
                                    placeholder={t('interestInput', {
                                        X: minInterest,
                                        Y: maxInterest
                                    })}
                                    onChange={(e) =>
                                        handleDataChange('interestRate', e)
                                    }
                                />
                            </Form.Item>
                        </div>
                        <div className="btn-container">
                            <Button className="bottom-btn text-uppercase" htmlType="submit">
                                {t('calculateButton')}
                            </Button>
                        </div>
                    </Form>
                </div>

                <div className="loan-total">
                    <div className="total-loan-container">
                        <p className="total-loan-title">
                            {t('carLoanSubTitle')}{' '}
                        </p>
                        <p className="total-loan">
                            {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''} {formatMoney(monthlyRepayment) ?? 0} {topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}
                        </p>
                    </div>
                    <div className="total-loan-container">
                        <p className="total-loan-title">{t('interestRate')} </p>
                        <p className="total-loan">
                            {formatMoney(interestRate) ?? 0} %
                        </p>
                    </div>
                    <div className="total-loan-container">
                        <p className="total-loan-title">
                            {t('carLoanTotalRepayment')}{' '}
                        </p>
                        <p className="total-loan">
                            {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''} {formatMoney(totalRepayment) ?? 0} {topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}
                        </p>
                    </div>
                    <button className="bottom-btn text-uppercase" onClick={showModal}>
                        {t('getACallBack')}
                    </button>
                    <GetACallBack
                        open={isModalVisible}
                        setModalOpen={setIsModalVisible}
                        category="financing"
                        interestRate={loanData.interestRate}
                        timeFrame={loanData.timeFrame}
                        downPayment={loanData.downpayment}
                        carId={props.carId}
                    />
                </div>
            </div>
        </div>
    );
};

export default LoanCalculator;
