import { useCallback, useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import { SetMeta } from '../utils/Helper/jsHelpers';
import { useTranslation } from 'react-i18next';
import TrackerLayout from '../components/ui/TrackerLayout';
import { getCountryNameFromDomain } from '../utils/translation';
import { CaretDown, CarSimple, GasPump, Gear, Path } from 'phosphor-react';
import { Input, Select, Space } from 'antd';

const EmissionCalculator = () => {
    const [distanceUnit, setDistanceUnit] = useState('km');
    const [fuelType, setFuelType] = useState('petrol');
    const [emissionFactor, setEmissionFactor] = useState(2.31);
    const [efficiencyUnit, setEfficiencyUnit] = useState('ltr/100km');
    const [distance, setDistance] = useState(100);
    const [efficiency, setEfficiency] = useState(10);
    const [calculatedEmission, setCalculatedEmission] = useState(0);
    const [measureUnit, setMeasureUnit] = useState('ltr');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();

    const calculateEmission = useCallback(() => {
        if (!distance || !emissionFactor || !efficiency) {
            setCalculatedEmission(0);
            return;
        }

        let calculatedCO2;
        let convertedDistance;
        let convertedEfficiency;
        let adjustedEmissionFactor = emissionFactor;

        // Convert distance to kilometers
        convertedDistance =
            distanceUnit === 'mi' ? distance * 1.60934 : distance;

        // Convert fuel economy based on selected unit
        if (efficiencyUnit === 'ltr/100km') {
            convertedEfficiency = efficiency; // Already in L/100km
        } else if (efficiencyUnit === 'mpg') {
            if (efficiency <= 0) return;
            convertedEfficiency = 235.214 / efficiency; // Convert MPG to ltr/100km
            adjustedEmissionFactor = emissionFactor / 3.78541; // Convert kg CO₂/Ltr to kg CO₂/gallon
        } else {
            convertedEfficiency = efficiency;
        }

        // Calculate CO2 emissions: (distance * fuel economy / 100) * emission factor
        calculatedCO2 =
            ((convertedDistance * convertedEfficiency) / 100) *
            adjustedEmissionFactor;
        calculatedCO2 = Math.round(calculatedCO2 * 100) / 100; // Round to 2 decimals

        setCalculatedEmission(calculatedCO2);
    }, [distance, emissionFactor, efficiency, distanceUnit, efficiencyUnit]);

    useEffect(() => {
        calculateEmission();
    }, [calculateEmission]);
    // useEffect(() => {
    //     if (distanceUnit === 'km') {
    //         if (fuelType === 'petrol') {
    //             setEmissionFactor(2.31);
    //             setMeasureUnit('ltr');
    //             setEfficiencyUnit('ltr/100km');
    //         } else if (fuelType === 'diesel') {
    //             setEmissionFactor(2.68);
    //             setMeasureUnit('ltr');
    //             setEfficiencyUnit('ltr/100km');
    //         }
    //     } else if (distanceUnit === 'mi') {
    //         if (fuelType === 'petrol') {
    //             setEmissionFactor(8.89);
    //             setMeasureUnit('gallon');
    //             setEfficiencyUnit('mpg');
    //         } else if (fuelType === 'diesel') {
    //             setEmissionFactor(10.16);
    //             setMeasureUnit('gallon');
    //             setEfficiencyUnit('mpg');
    //         }
    //     }
    // }, [fuelType, distanceUnit]);

    useEffect(() => {
        if (fuelType === 'petrol') {
            if (measureUnit === 'ltr') {
                setEmissionFactor(2.31); // For liters
                setEfficiencyUnit('ltr/100km');
            } else if (measureUnit === 'gallon') {
                setEmissionFactor(8.89); // For gallons
                setEfficiencyUnit('mpg');
            }
        } else if (fuelType === 'diesel') {
            if (measureUnit === 'ltr') {
                setEmissionFactor(2.68); // For liters
                setEfficiencyUnit('ltr/100km');
            } else if (measureUnit === 'gallon') {
                setEmissionFactor(10.16); // For gallons
                setEfficiencyUnit('mpg');
            }
        }
    }, [fuelType, measureUnit]);

    useEffect(() => {
        if (efficiencyUnit === 'ltr/100km') {
            setMeasureUnit('ltr');
            // setDistanceUnit('km');
        } else if (efficiencyUnit === 'mpg') {
            setMeasureUnit('gallon');
            // setDistanceUnit('mi');
        }
    }, [efficiencyUnit]);

    const formatCalculatedEmission = () => {
        return calculatedEmission === 0 ? (
            <span>{t('enterAllValues')}</span>
        ) : (
            <h3>
                {calculatedEmission} kg co<sub>2</sub>
            </h3>
        );
    };

    const contentData = () => {
        return (
            <div className="tracker-content">
                <div
                    className="tracker-content-data"
                    dangerouslySetInnerHTML={{
                        __html: t('emissionContent', {
                            ns: 'content'
                        })
                    }}
                />
            </div>
        );
    };

    const converterNode = () => {
        const { Option } = Select;

        const selectAfter = (
            <Select
                value={distanceUnit}
                className="select-after"
                popupClassName="calculator-options"
                onChange={(value) => setDistanceUnit(value)}
                suffixIcon={
                    <CaretDown size={24} color="#454545" weight="bold" />
                }
            >
                <Option value="mi">mi</Option>
                <Option value="km">km</Option>
            </Select>
        );

        const selectAfterEfficiencyUnit = (
            <Select
                value={efficiencyUnit}
                className="select-after"
                popupClassName="calculator-options"
                onChange={(value) => setEfficiencyUnit(value)}
                suffixIcon={
                    <CaretDown size={24} color="#454545" weight="bold" />
                }
            >
                <Option value="ltr/100km">ltr/100km</Option>
                <Option value="mpg">MPG</Option>
            </Select>
        );

        return (
            <div className="calculator-ui-container">
                <div className="calculator-ui">
                    <div className="calc-form" style={{ width: '100%' }}>
                        <div className="calc-form-row">
                            <Space direction="vertical">
                                <label>{t('fuelType')}</label>
                                <div className="custom-select">
                                    <div className="cus-icon">
                                        <GasPump size={20} weight="bold" />
                                    </div>
                                    <Select
                                        defaultValue={fuelType}
                                        onChange={(value) => setFuelType(value)}
                                        suffixIcon={
                                            <CaretDown
                                                size={24}
                                                color="#454545"
                                                weight="bold"
                                            />
                                        }
                                        popupClassName="converter-options"
                                    >
                                        <Option value="petrol">Petrol</Option>
                                        <Option value="diesel">Diesel</Option>
                                    </Select>
                                </div>
                            </Space>
                            <Space direction="vertical">
                                <label>{t('emissionFactor')}</label>
                                <Input
                                    prefix={<Gear weight="bold" size={20} />}
                                    addonAfter={
                                        <>
                                            kg co<sub>2</sub>/{measureUnit}
                                        </>
                                    }
                                    value={emissionFactor}
                                    onChange={(e) =>
                                        setEmissionFactor(
                                            Number(e.target.value)
                                        )
                                    }
                                    type="number"
                                />
                            </Space>
                        </div>
                        <div className="calc-form-row">
                            <Space direction="vertical">
                                <label>{t('distance')}</label>
                                <Input
                                    prefix={<Path size={20} weight="bold" />}
                                    addonAfter={selectAfter}
                                    value={distance}
                                    onChange={(e) =>
                                        setDistance(Number(e.target.value))
                                    }
                                    type="number"
                                />
                            </Space>

                            <Space direction="vertical">
                                <label>{t('efficiency')}</label>
                                <Input
                                    prefix={
                                        <CarSimple weight="bold" size={20} />
                                    }
                                    addonAfter={selectAfterEfficiencyUnit}
                                    value={efficiency}
                                    onChange={(e) =>
                                        setEfficiency(Number(e.target.value))
                                    }
                                    type="number"
                                />
                            </Space>
                        </div>
                    </div>
                    <div className="calc-result">
                        <h6>{t('estimatedEmission')}</h6>
                        {formatCalculatedEmission()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <SetMeta
                title={t('emissionCalculatorTitle', {
                    ns: 'meta',
                    Country_name: getCountryNameFromDomain()
                })}
                desc={t('emissionCalculatorDescMeta', {
                    ns: 'meta',
                    Country_name: getCountryNameFromDomain()
                })}
                keywords={t('emissionCalculatorKeywords', {
                    ns: 'meta',
                    Country_name: getCountryNameFromDomain()
                })}
                properties={[
                    {
                        key: 'og:title',
                        value: t('emissionCalculatorTitle', {
                            ns: 'meta',
                            Country_name: getCountryNameFromDomain()
                        })
                    },
                    {
                        key: 'og:description',
                        value: t('emissionCalculatorDescMeta', {
                            ns: 'meta',
                            Country_name: getCountryNameFromDomain()
                        })
                    },
                    {
                        key: 'og:keywords',
                        value: t('emissionCalculatorKeywords', {
                            ns: 'meta',
                            Country_name: getCountryNameFromDomain()
                        })
                    }
                ]}
            />
            <Layout showWhiteNavbar>
                <TrackerLayout
                    effectiveDate={''}
                    tableData={[]}
                    tableHeading={''}
                    coverImage="/images/hero/emission-calculator.webp"
                    title={t('emissionEstimator')}
                    description={t('emissionEstimatorDesc')}
                    content={contentData()}
                    converterNode={converterNode()}
                />
            </Layout>
        </>
    );
};
export default EmissionCalculator;
