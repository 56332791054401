const metaWolof = {
    homePageTitle: 'Certified used cars in Senegal with AUTO24',
    homePageDesc:
        'Used car price, new cars service! Buy reconditioned and certified used cars with 6 to 12 months warranty, all inspected by our auto experts !',
    sellPageTitle:
        'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.sn',
    sellPageDesc:
        "Looking to sell your car?Get a top cash offer instantly at auto24.sn! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
    buyPageTitle:
        'Cars for Sale in Senegal| Get the best prices on cars today-auto24.sn',
    buyPageDesc:
        'Find the best prices for used {{ BRAND }} cars to buy in Dakar, Senegal, with 100 points inspection, 5 days refund policy, 6 months warranty and more.',
    financePageTitle:
        'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.sn',
    financePageDesc:
        'Explore hassle-free car finance with Auto24.sn-100% online, competitive rates and flexible terms. Secure your dream car today!',
    aboutPageTitle: ' AUTO24, a new way to buy and sell cars in Senegal',
    blogPageTitle: 'Car Maintenance Tips & Auto Industry Insights-auto24.sn',
    blogPageDesc:
        'Visit Auto24.sn for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
    qNaPageTitle: 'AUTO24 - Frequently asked questions ',
    contactUsPageTitle: ' AUTO24 - Contact us',
    privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
    termsNusePageTitle: 'AUTO24 - Terms & conditions',
    insurePageTitle:
        'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.sn',
    insurePageDesc:
        'Obtain the best car insurance policy at the best price with Auto24.sn. Here to provide stress-free coverage tailored for you - protect your car today!',
    carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
    carDetailPageDesc:
        'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Senegal',
    buyPageDescWhenBrand:
        'Best prices and services for {{BRAND}} used cars in Senegal. Refund policy, warranty, insurance, maintenance, and all included!',
    brandPageTitle:
        '{{ BRAND }} xeeti fëri ci Senegal | Jëndee benn jaamu ci AUTO24 Senegal',
    bodyTypePageTitle:
        '{{ BODY_TYPE }} xeeti fëri ci Senegal | Jëndee benn jaamu ci AUTO24 Senegal',
    carsUnderPageTitle:
        'Jëndee xeeti fëri bu mujj ci {{ PRICE }} ci Senegal | Jëndee benn jaamu ci AUTO24 Senegal',
    carsUnderPageDesc:
        'Find the best prices for used cars under {{ PRICE }} to buy in Dakar, Senegal, with 100 points inspection, 5 days refund policy, 6 months warranty and more.',
    fuelPriceTrackerTitle: 'Prix yi essence yi ci Senegal - AUTO24.sn',
    fuelPriceTrackerDescMeta:
        'Bëgg nga xam prix yi essence yi ci Senegal? Gisal xaalis bi essence, disel ak gaz bi di tollu',
    fuelPriceTrackerKeywords:
        'Prix yi essence yi ci Senegal, xibaar yi essence ci Senegal, suivri prix essence, ay compare prix essence, prix essence Senegal 2025',
    electricityPriceTrackerTitle:
        'Biir deretu korenti bu yagg ci Senegal - AUTO24.sn',
    electricityPriceTrackerDescMeta:
        'Bëgg nga xam deretu korenti bu yagg ci Senegal? Xoolal ay tarif yu bees, toppal yéenekaay yi, te doxal sa njumtey enerji ci yoon wu rafet.',
    electricityPriceTrackerKeywords:
        'Deretu korenti bu yagg ci Senegal, xibaar yu bees ci tarif yu korenti, yéenekaay deretu korenti, bennal deretu enerji, tarif yu korenti ci Senegal 2025',
    currencyConverterTitle:
        'Jumtel bu jëm ci ñeme - Tax yu ñeme bu bopp ci {{ Country_name }} - Auto24.sn Senegal',

    currencyConverterDescMeta:
        'Xoolal tax yu ñeme yu deñk ak jumtel Auto24 ci {{ Country_name }}. Ñeme ak tax yu am solo ci adduna, di gis tax yu jëm ci marché.',

    currencyConverterKeywords:
        'Tax yu ñeme ci waxtaan wi, Jumtel Forex, Tax yu jëm ci marché, Jumtel bu jëm ci ñeme AUTO 24, Tax yu am solo, Forex tax, Jumtel bu jëm ci ñeme ci {{ Country_name }}',
    distanceConverterTitle: '{{ Name }} - Auto24.sn',
    distanceConverterDescMeta:
        '{{ title }} ci {{ Country_name }}. {{ howToConvert }} yomb',
    distanceConverterKeywords:
        '{{ title }}. Soppi {{ op1 }} ak {{ op2 }} ci {{ Country_name }}',
    emissionCalculatorTitle:
        'Ñettal bu CO2 ci {{ Country_name }} – Natt sa Tànki Karbon - Auto24.sn',
    emissionCalculatorDescMeta:
        'Jëfandikool sunu ñettal bu CO2 ci {{ Country_name }} ngir xayma sa tànki karbon. Ñettal gaasukaay yi jóge ci transport, jëfandikug ndeyu mbëj, ak jëf yu bés bu nekk ngir wañi diiru bi ci mboolem xët yi.',
    emissionCalculatorKeywords:
        'Ñettal bu CO2 ci {{ Country_name }}, ñettal tànki karbon ci {{ Country_name }}, gaasukaay yu seere nëtënku ci {{ Country_name }}, toppatub CO2 ci {{ Country_name }}, wañi gaasukaay ci {{ Country_name }}',
    bestSellerTitle:
        'Njaaykat yi gën a jëfandikoo ci {{ CurrentYear }} ci Senegal - Auto24.sn',
    bestSellerDescMeta:
        'Dëggal njaaykat yi gën a jëfandikoo ci {{ CurrentYear }} ci Senegal. Sëf njaaykat yi gën a jëfandikoo ci jàmmu, jàmmu ak jàmmu, ak jàmmu. Jëfandikoo sa njaaykat bu next ak Auto24.ma loolu!',
    bestSellerKeywords:
        'Njaaykat yi gën a jëfandikoo {{ CurrentYear }}, Njaaykat yu baax {{ CurrentYear }}, njaaykat yi gën a jëfandikoo {{ CurrentYear }}, Njaaykat yu baax ci Senegal, Njaaykat yi gën a jëfandikoo ci Senegal, Njaaykat yi ci {{ CurrentYear }}, Njaaykat yu baax ci Senegal, Suq bi ci {{ CurrentYear }}, Njaaykat yi gën a jëfandikoo ci Senegal',
    carLoanCalculatorTitle:
        'Xayma bu Leeb Watiir ci Senegal - Seetlu sa Fey yi ci Weer ak Njëg yi - Auto24.sn',
    carLoanCalculatorDescMeta:
        'Jëfandikool sunu xayma bu leeb watiir ngir seetlu sa peyum weer ci Senegal, njëg gépp, ak njëgal lépp bu leeb bi. Tëralal jënd sa watiir bu jot ci Senegal ak wóolu te gis leeb bi gënë baax ngir sa xaalis',
    carLoanCalculatorKeywords:
        'Xayma bu leeb watiir, Xayma bu leeb oto, Finanse watiir, Njëg bu leeb watiir, Peyum weer bu watiir, Njeexital leeb, Jumtukaay bu finanse watiir, EMI bu leeb watiir, Seetlukaay bu leeb watiir'
};

export default metaWolof;
