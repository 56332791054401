import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { FullNameInputComp } from './CountryCodeDropdown';
import LoadingButton from './LoadingButton';
import SecSeperator from './SecSeperator';

import { $FIXME } from '../../constants';
import {
    defaultDailing_Code,
    getCountryByCode
} from '../../helpers/countryCode';
import { getNumbersOnly } from '../../helpers/functions';
import { InquiryType, request } from '../../helpers/request';
import { ICombineReducerType } from '../../redux/reducers';
import {
    fullNameValidate
} from '../../utils/Helper/jsHelpers';
import { languagesList } from '../../utils/translation';
import { setFullNameError } from '../Certification';
import PhoneNumberInput from './PhoneNumberInput';

const GetACallBack = (props: any) => {
    const { t } = useTranslation();
    const [isvisiable, setVisiable] = useState(false);
    const [error, setError] = useState<any>({});
    const [disabled, setDisabled] = useState(true);
    let { user } = useSelector((state: ICombineReducerType) => state.auth);
    const [userData, setUserData] = useState({
        phoneNumber: {
            code: user?.phoneNumber?.code
                ? String(user?.phoneNumber?.code)
                : defaultDailing_Code,
            number: user?.phoneNumber?.number
                ? getNumbersOnly(`${user?.phoneNumber?.number}`)
                : ''
        },
        fullName: user?.name ?? '',
        category: '',
        subCategory: ''
    });
    const [loading, setLoading] = useState(false);
    const [phoneValidErr, setPhoneValidErr] = useState({
        number: true
    });

    const navigate = useNavigate();
    const currentPath = useLocation();
    useEffect(() => {
        setUserData({
            phoneNumber: {
                code: user?.phoneNumber?.code ?? defaultDailing_Code,
                number: user?.phoneNumber?.number ?? ''
            },
            fullName: user?.name ?? '',
            category: props.category,
            subCategory: props.subCategory ?? ''
        });
        setError({});
    }, [props.open]);

    useEffect(() => {
        if (userData.fullName) {
            if (fullNameValidate(userData.fullName)) {
                setError({});
            }
        }
    }, [userData.fullName]);

    useEffect(() => {
        if (!userData.fullName && !userData.phoneNumber.number) {
            setError({});
        }
        if (
            fullNameValidate(userData.fullName) &&
            !phoneValidErr?.number
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [userData, phoneValidErr]);

    async function submit(e: any) {
        e.preventDefault();
        setLoading(true);
        try {
            // NOTE: THIS IS ONLY TEMPORARY SINCE VALIDATION DOES NOT WORK RIGHT NOW
            // if (true || (await numcheck(userData?.phoneNumber as any, setError))) {
            let {
                fullName,
                phoneNumber: { code, number },
                category,
                subCategory
            } = userData;
            let requestData: InquiryType = {
                fullName,
                code,
                number: String(getNumbersOnly(number)),
                category,
                subCategory,
                ...(props?.interestRate
                    ? { interest_rate: props.interestRate }
                    : {}),
                ...(props?.timeFrame ? { loan_tenure: props.timeFrame } : {}),
                ...(props?.downPayment
                    ? { down_payment: props.downPayment }
                    : {})
            };
            if (props?.carId) {
                requestData.car_id = props.carId;
            }
            await request.sendInquiry(requestData);
            props.setModalOpen(false);
            setVisiable(true);
        } catch (err: any) {
        } finally {
            setLoading(false);
        }
    }

    const closeModal = () => {
        props.setModalOpen(false);
        setLoading(false);
    };

    const goToHome = () => {
        const languagesObj = languagesList();
        const routeArr = currentPath.pathname.split('/');
        let langCode = '';
        Object.values(languagesObj).forEach((item) => {
            if (routeArr.includes(item)) {
                langCode = item;
            }
        });
        navigate(`/${langCode}`);
        setVisiable(false);
    };

    const SuccessModal = () => {
        return (
            <Modal
                className="save-changes-modal ants-modal"
                title={t('Detailssentsuccessfully')}
                open={isvisiable}
                onCancel={() => setVisiable(false)}
                closable={true}
                centered={true}
            >
                <p className="subtitle">{t('DetailChangeSubtitle')}</p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="done" />
                </div>
                <div className="get-call-back">
                    <button className="back-to-home" onClick={() => goToHome()}>
                        {t('backToHome')}
                    </button>
                </div>
            </Modal>
        );
    };
    return (
        <>
            <Modal
                className="callback-modal ants-modal "
                open={props.open}
                closable={true}
                onCancel={closeModal}
            >
                <SecSeperator className="modal-sec-seperator" />
                <div className="getacallback-header">
                    {t('provideUsYourDetails')}
                </div>
                <span className="subtitle">{t('callBackSubtitle')}</span>
                <form onSubmit={submit}>
                    <FullNameInputComp
                        setFullNameError={() => {
                            if (userData.fullName) {
                                setFullNameError(
                                    userData.fullName,
                                    setError,
                                    t
                                );
                            }
                        }}
                        handleChange={(e: $FIXME) => {
                            setUserData({
                                ...userData,
                                [e.target.name]: e.target.value
                            });
                        }}
                        value={userData.fullName}
                        t={t}
                        err={error.fullName}
                    />
                    <div className="phone-number">
                        <PhoneNumberInput
                            onChange={(value: any, key: string) => {
                                setUserData((prev) => ({
                                    ...prev,
                                    phoneNumber: {
                                        ...prev.phoneNumber,
                                        [key]: value[key],
                                        code: value?.code
                                    }
                                }));
                            }}
                            name="number"
                            value={getNumbersOnly(
                                `${userData?.phoneNumber?.number}`
                            )}
                            defaultSelect={
                                userData?.phoneNumber?.code
                                    ? getCountryByCode(
                                          userData?.phoneNumber?.code
                                      )
                                    : getCountryByCode(defaultDailing_Code)
                            }
                            setError={setPhoneValidErr}
                        />
                    </div>
                    <div className="cert-submit callback-footer">
                        <span>
                            {t('byClickingXButton', {
                                name: t('send')
                            })}{' '}
                            <Link
                                to={t('/terms-of-use', { ns: 'routes' })}
                                className="termandpolicy"
                            >
                                {t('termOfUse')}
                            </Link>{' '}
                            {t('and')}{' '}
                            <Link
                                to={t('/privacy-policy', { ns: 'routes' })}
                                className="termandpolicy"
                            >
                                {t('privacyPolicy')}
                            </Link>
                        </span>
                        {loading ? (
                            <LoadingButton buttonText="sending" />
                        ) : (
                            <button
                                className="bottom-btn text-uppercase"
                                disabled={disabled}
                                type="submit"
                            >
                                {t('send')}
                            </button>
                        )}
                    </div>
                </form>
            </Modal>
            <SuccessModal />
        </>
    );
};
export default GetACallBack;
