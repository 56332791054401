const getCommonFrench = (auto24Domain: string) => ({
    and: 'et notre',
    buy: 'Acheter',
    sell: 'Vendre',
    send: 'Envoyer',
    finance: 'Financer',
    about: 'À Propos',
    blogs: 'Blogs',
    blog: 'Blog',
    tools: 'Outils',
    callUsAt: 'Appelez-nous au',
    signIn: 'Se Connecter',
    signUp: 'S’enregistrer',
    or: 'ou',
    insure: 'Assurer',
    enteremailAddress: 'Votre Email',
    enterPassword: 'Votre Mot de Passe',
    rememberMe: 'Se souvenir de moi',
    forgotPassword: 'Mot de passe oublié',
    carsAvailable: 'Voitures Disponibles en ce moment',
    cancel: 'Annuler',
    enterFullName: 'Entrer votre nom',
    byClickingXButton: 'En cliquant {{name}} vous acceptez nos',
    termOfUse: 'Conditions d’utilisation',
    privacyPolicy: 'Politique de confidentialité',
    alreadyhaveanAccount: 'Vous avez déjà un compte ?',
    howItWorks: 'Comment ça marche',
    howtoBuy: 'Comment acheter',
    howtoSell: 'Comment vendre',
    exploreBlogs: 'Découvrez notre blog',
    car: 'Voiture',
    search: 'Rechercher',
    Certified: 'Certifiée',
    Inspected: 'Inspectée',
    ev: 'Électrique',
    hybrid: 'Hybride',
    phev: 'PHEV',
    petrol: 'Essence',
    diesel: 'Diesel',
    carburant: 'Carburant',
    other: 'Autre',
    electric: 'Électrique',
    automatic: 'Automatique',
    pleaseprovideusyourdetails: 'Vos informations personnelles',
    pleaseprovideusyourcardetails:
        'Donnez-nous des informations sur votre voiture',
    desiredPleaseprovideusyourcardetails:
        'Donnez-nous quelques détails sur ce que vous cherchez comme voiture et un de nos conseillers vous contactera dans les meilleurs délais',
    phonenumberisnotvalid: "Le numéro de téléphone n'est pas valide",
    internalError: 'Erreur interne. Merci de réessayer ou de nous contacter.',
    knowaboutyourcarbetterandgetevaluation:
        'Une offre de rachat express en moins d’une heure',
    cardetailssendSuccessfully: 'Détails de la voiture envoyés avec succès',
    thanyouforprovidingusdetail:
        'Un de nos conseillers va vous rappeler dans les plus brefs délais pour en discuter.',
    selectbrand: 'Choisissez une marque',
    selectmodel: 'Choisissez un modèle',
    selectcountry: 'Choisissez le pays',
    enterMileage: 'Entrez le kilométrage',
    minMileage: 'Kilométrage minimum',
    maxMileage: 'Kilométrage maximal',
    selectYear: 'Choisissez une année',
    selectorenterbrand: 'Sélectionnez ou saisissez une marque',
    selectorentermodel: 'Sélectionnez ou entrez un modèle',
    selectorenterbodyType: '',
    brand: 'Marque',
    model: 'Modèle',
    modelYear: 'Année',
    selectFuelType: 'Type de Carburant',
    selectACondition: 'Condition du Véhicule',
    phoneNumber: 'Téléphone',
    loanTitle: 'Bénéficiez des meilleures offres de financement',
    loanHappyCusSubtitle:
        // 'Grâce à nos partenaires financiers, nous vous proposons des offres de financement sur nos voitures d’occasion certifiées',
        'Vous pouvez nous faire confiance, comme ces clients déjà heureux !',
    loanButton: 'Calculer mon prêt',
    calculateButton: 'Calculer',
    blogTitle: 'Découvrez la voiture avant de l’acheter',
    blogSubtitle:
        "Ce sont les choses que vous devez savoir avant d'acheter n'importe quel type de voiture",
    viewAllCars: 'Voir toutes les voitures',
    availableNowOn: 'Retrouvez-nous sur',
    account: 'Compte',
    myAccount: 'Mon Compte',
    ourBrands: 'Nos marques',
    contactUs: 'Contactez-nous',
    aboutUs: 'À propos',
    addressLine: 'Adresse',
    contact: 'Contact',
    whyChooseAuto: 'Pourquoi choisir AUTO24 ?',
    allcarshavebeenthoroughlyinspectedandreconditionedHowweguaranteecarquality:
        'Toutes nos voitures ont été inspectées et reconditionnées rigoureusement par nos experts',
    imInterested: 'Je suis intéressé',
    buyOnline: 'Acheter en ligne',
    downPayment: 'Apport',
    hotDeal: 'Bonne affaire',
    lowPrice: 'Pas chère',
    likeNew: 'Comme neuve',
    moneybackSubtitle: 'La voiture ne vous convient pas ? On vous la rembourse',
    warrentyTitle: 'Garantie 6 mois',
    warrentySubtitle:
        'Garantie extensible à 12 mois sur le moteur, la boîte et le pont',
    inspectionPointsSubtitle:
        'Examinée par nos experts automobile sous tous les angles',
    roadSideAssistanceTitle: '1 année d’assistance',
    roadSideAssistanceSubtitle:
        'Dépannage et remorquage inclus sur tous nos véhicules, pendant un an',
    deliveryTitle: 'Livraison à domicile',
    deliverySubtitle:
        'Nous pouvons livrer votre voiture où vous le souhaitez en Côte d’Ivoire',
    xDeliverySubtitle:
        'Nous pouvons livrer votre voiture où vous le souhaitez en {{country}}',
    Summary: 'Résumé',
    engine: 'Moteur',
    enginePower: 'Moteur',
    fuelType: 'Type de carburant',
    bodyType: 'Carrosserie',
    numberofkeys: 'Nombre de clés',
    exteriorColor: 'Couleur Extérieure',
    interiorColor: 'Couleur Intérieure',
    engineSize: 'Cylindrée',
    fiscalPower: 'Puissance fiscale',
    specifications: 'Specifications',
    lengthWidthHeight: 'Longueur, Largeur & Hauteur',
    weight: 'poids',
    emissionCO2: 'Émission CO2',
    fuelConsumption: 'Consommation',
    enginepower: 'Puissance moteur',
    fuelTankCapacity: 'Capacité du Réservoir',
    'performance&Economy': 'Performance et economies',
    features: 'Options',
    'weight&Dimensions': 'Poids & Dimensions',
    showMoreFeatures: 'Afficher plus d’options',
    showLess: 'Montrer moins',
    serviceHistory: 'Historique des services',
    whyAUTO24: 'Pourquoi AUTO24',
    serviceCenter: 'Lieu de service',
    kilometers: 'Kilomètres',
    lookingForaCarQtellUs: 'Vous cherchez une voiture ? Dites-le nous !',
    sellPageTitle: 'Vendez votre voiture rapidement',
    sellPageSubtitle:
        'Vous souhaitez vendre votre voiture ? Elle peut nous intéresser !',
    findCarPricePageTitle: 'Trouvez le prix de votre voiture',
    findCarPricePageSubtitle:
        'Vous pouvez fournir des détails sur votre voiture et nous vous rappellerons pour d’autres offres.',
    whyFinanceYourCarwithAuto24: 'Pourquoi un prêt Auto24',
    financeSubtitle:
        'Nous avons les meilleures offres de financement avec des intérêts et des acomptes imbattables.',
    competitiveRates: 'Tarifs compétitifs',
    competitiveDesc: 'Taux d’intérêt annuel à partir de 5%',
    competitiveDescMA: 'Possibilité de crédit gratuit sous conditions',
    flexiableDuration: 'Durée flexible',
    flexiableDes: 'Achète ta voiture entre 12 et 72 mensualités',
    Percent100OnlineApproach: '100% en ligne',
    Percent100OnlineApproachDesc: 'Signe les documents depuis chez toi',
    loanCalculatorTitle: 'Calculatrice de prêt auto',
    loadCalculatorSubtitle:
        'Le taux d’intérêt change en fonction du partenaire financier avec lequel nous travaillons.',
    interestRate: 'Taux d’Intérêt ',
    years: 'Années',
    ourFinancingPartners: 'Notre partenaire financement',
    ourInsurePartners: 'Notre partenaire assurance',
    ourPartners: 'Nos partenaires',
    copyrightfooter: 'Copyright © Africar Group Pty Ltd - Tous droits réservés',
    insureSubtitle: 'Bénéficiez des meilleures assurances au meilleur prix',
    aboutFinanceSub: 'Ils nous soutiennent dans notre démarche',
    selectMileageRange: 'Sélectionnez la plage de kilométrage',
    selectTransmissionType: 'Choisissez le type de transmission',
    backToHome: 'Retour à l’Accueil',
    getACallBack: 'Être rappelé',
    personalDetails: 'Données personnelles',
    carDetails: 'Votre Voiture',
    enterCarDetails: 'Entrez les détails de la voiture',
    ourBrandsSubtitle:
        'Nous sommes partenaires des meilleures marques de voitures qui ont remporté de nombreuses récompenses pour leurs performances',
    ourTeam: 'Nos équipes',
    findCarPrice: 'Outil de tarification',
    teamMembers: `Membres de l'équipe`,
    ourTeamSubtitle: 'Les avis de nos équipes',
    latestPosts: 'Dernières publications',
    lastestPostSubtitle: 'Les dernières publications de nos utilisateurs',
    featuredPosts: 'Articles à la une',
    featuredPostSubtitle: 'Toute l’actualité automobile, vue par AUTO24',
    newsLetterTitle: 'Abonnez-vous',
    newsLetterSubtitle:
        'Inscrivez votre email ci-dessous et vous recevrez notre newsletter',
    buySellTitle: 'C’est parti?',
    buySellSubtitle:
        'Changeons ensemble l’expérience de vente et d’achat de voitures d’occasion en Afrique',
    sellYourCar: 'Vendre ma voiture',
    buyaCar: 'Acheter une voiture',
    footerInfo:
        'AUTO24.ci est une filiale d’Africar Group Pty Ltd, enregistrée en Côte d’Ivoire sous l’IDU CI-2022-0027564 O.',
    FAQ: 'Q&A',
    buyCars: 'Acheter une voiture',
    sellCars: 'Vendre une voiture',
    categories: 'Catégories',
    topStories: 'A la une',
    pleaseInputYourPassword: 'S’il vous plaît entrer votre mot de passe!',
    donthaveanaccount: 'Vous n’avez pas de compte',
    resetPassword: 'Changer de mot de passe',
    editProfile: 'Mon profil',
    createNewPassword: 'Créer un Mot de Passe',
    passwordresetsuccessfully: 'Mot de passe réinitialisé',
    pleaseInputYourName: 'S’il vous plaît entrer votre nom!',
    pleaseInputYourEmail: 'S’il vous plaît entrer votre adresse e-mail!',
    pleaseInputValidEmail: 'S’il vous plaît entrer une adresse e-mail valide!',
    pleaseInputYourPhoneNumber:
        'S’il vous plaît entrer votre numéro de téléphone!',
    maximumXdigitsisallowed: 'Un maximum de {{X}} chiffres est autorisé',
    minimumXdigit: 'Un minimum de {{X}} chiffres est autorisé',
    passwordisRequired: 'Le mot de passe est requis!',
    minimum8chars: 'Minimum de 8 caractères requis',
    usersuccessfullyregistered: 'Utilisateur enregistré avec succès !',
    shortBio: 'A propos de moi',
    Save: 'Enregistrer',
    doyouwantDeleteAccount:
        'Souhaitez-vous supprimer votre compte définitivement?',
    delete: 'Supprimer',
    saveChanges: 'Enregistrer les modifications?',
    youcanstillchangetheseinformation:
        'Vous pourrez toujours modifier ces informations',
    changessavedsuccessfully: 'Modifications enregistrées avec succès',
    yourchangessavedsuccessfully:
        'Vos modifications ont été enregistrées avec succès',
    deleteyourAccount: 'Supprimer votre compte?',
    deleteAccountsubtitile:
        'Une fois votre compte supprimé, toutes vos données seront perdues et vous ne pourrez plus y accéder.',
    accountdeletedsuccessfully: 'Compte supprimé avec succès',
    youcanstillexpolrecarwithouthavinganaccount:
        'Vous pourrez toujours consulter la liste des voitures sans avoir de compte.',
    logout: 'Deconnexion',
    xInspectionPoints: '{{number}} points d’inspection',
    xlistingPageResultTitle: '{{number}} Voitures en vente',
    xlistingPageResultsTitle: '{{number}} Voitures en vente',
    sortBy: 'Classer par:',
    filterBy: 'Filtrer par:',
    FilterBy: 'Classer par:',
    lowestPriceFirst: 'Du moins cher au plus cher',
    highestMileageFirst: 'Plus de kilométrages en premier',
    lowestMileageFirst: 'Petit kilométrages en premier',
    price: 'prix',
    mileage: 'Kilométrage',
    selectBodyType: 'Type de carrosserie',
    filter: 'Filtres',
    moreFilters: 'Plus de filtres',
    color: 'Couleur',
    transmission: 'Boite de vitesse',
    highestPriceFirst: 'Du plus cher au moins cher',
    provideUsYourDetail: 'Donnez-nous vos coordonnées',
    interestcartitle: 'Vous êtes intéressé par cette voiture?',
    interestcarsubtitle:
        'Choisissez l’option qui vous convient et nous vous contacterons dans les plus brefs délais',
    bookThisCar: 'Je réserve cette voiture',
    bookThisCarSubtitle:
        'Cliquez ici pour envoyer un paiement de 3000 DH, nous bloquons la voiture pendant 1 semaine, rien que pour vous',
    scheduleAVisit: 'Programmez une visite',
    scheduleAVisitSubtitle:
        'Cliquez-ici pour programmer une visite avec nos conseillers de vente. Attention, la voiture peut être vendue entre-temps! Faites vite',
    popularblog: 'Articles populaires',
    popularblogSub:
        'Toute l’actualité autour des voitures d’occasion en Côte d’Ivoire',
    category: 'Catégorie',
    carOverview: 'Informations générales',
    DetailChangeSubtitle:
        'Merci de nous avoir fourni vos coordonnées. Nous vous rappellerons bientôt.',
    Detailssentsuccessfully: 'Informations enregistrées avec succès',
    continue: 'Continuer',
    carnotfoundSubtitle:
        'Malheureusement nous n’avons pas de voitures dans vos critères. Renseignez vos coordonnées et nous vous contacterons dès que possible !. ',
    logoutModelTitle: 'Voulez-vous vous déconnecter?',
    french: 'French',
    QuickLinks: 'Liens',
    similarResults: 'Résultats similaires',
    copyrightInfo:
        'AUTO24.CI est une filiale de Africar Group Pty Ltd, enregistree sous le numero d’identification unique CI-2022-0027564 O en République de Côte d’Ivoire',
    recentlyAdded: 'Les dernières voitures en vente',
    recentlyAddedSubtitle:
        'Ici vous pouvez voir différentes voitures qui ont été récemment ajoutées',
    newCarList: 'Nouvelles voitures',
    newCarListSubtitle: 'Ici vous pouvez voir différentes nouvelles voitures',
    electricCarList: 'Voiture électrique',
    electricCarListSubtitle:
        'Ici vous pouvez voir différentes voitures électriques',
    importedCarList: 'Voitures importées',
    importedCarListSubtitle:
        'Ici vous pouvez voir différentes voitures importées',
    cheapestCarList: 'Voitures à moins de {{X}}',
    cheapestCarListSubtitle:
        'Ici vous pouvez voir différentes voitures bon marché en dessous de {{X}}',
    hereyoucanseedifferentsimilarcars:
        'Ces voitures peuvent aussi vous intéresser',
    carNotFound: 'Aucune voiture trouvée',
    whatsappUrlText: 'Bonjour! Je suis intéressé par cette voiture',
    latestService: 'Dernier service',
    resetSuccessMsg:
        'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe',
    bothPasswordMustMatch: 'Vérifiez votre mot de passe',
    mustBeAtleast8Characters: 'Mot de Passe',
    sendResetLink: 'Envoyer l’email de réinitialisation',
    enterEmailToGetResetLinkInfo:
        'Indiquez votre email afin de réinitialiser votre mot de passe',
    resetPasswordModalTitle: 'Voulez vous réinitialiser votre mot de passe ?',
    yourPasswordChangedSuccessfully:
        'Votre mot de passe à été changé avec succès',
    confirmPassword: 'Confirmez votre mot de passe',
    carViewsXTimes: 'Vues {{number}} fois aujourd’hui',
    sellNow: 'Cliquez-ici',
    shortDescription: 'Courte description',
    carImages: 'Images de voiture',
    uploadImage: 'Télécharger l’image',
    browse: 'Parcourir',
    listAnother: 'Je vends une autre voiture',
    listCarRequestSentSucessfully: 'Merci !',
    basicDetails: 'Informations basiques',
    manual: 'Manuelle',
    postYouHaveLiked: 'Publications que vous avez aimé',
    enterOldPassword: 'Entrer ancien mot de passe',
    enterNewPassword: 'Entrer nouveau mot de passe',
    confirmNewPassword: 'Confirmer nouveau mot de passe',
    passwordChangeSuccessfully: 'Mot de passe changé avec succès',
    verificationTitle:
        'Nous vous avons envoyé un lien de vérification à votre adresse e-mail',
    viewLink: 'Voir le lien',
    resendingLink: 'Renvoyer le lien',
    tokenExpired: 'Your token is expired please create new one',
    tokenExpiredTitle: 'Account Verification Link Expired',
    callBackSubtitle:
        'Entrez votre nom et numéro de téléphone, vous serez contacté sous peu',
    provideUsYourDetails: 'Donnez-nous vos coordonnées',
    showCars: 'Afficher les Voitures',
    accountVerifiedSuccessfully: 'Compte vérifié avec succès',
    accountVerifiedSuccessfullySubtitle:
        'Votre compte a été vérifié ! Voulez-vous vous connecter ?',
    returnTo: 'Retour à',
    engineSound: 'Son du moteur',
    showAllImage: 'Voir toutes les images',
    ourBenefits: 'Nos avantages',
    inspectionPoints: '100 points inspections par nos experts',
    warranties: '6 mois de garantie (moteur, transmission,pont)',
    maintenances: '1 an de maintenance avec AUTO24.ma',
    assistance: "12 mois d'assistance remorquage et dépannage",
    multiCurrency: 'Paiement multi devises: DH, €, $, £',
    moneyBack: '5 Jours satisfait ou remboursé',
    financing: 'Financement',
    dragAndDrop: "Glisser-déposer l'image ou",
    imageFormat: 'Formats pris en charge : JPEG, PNG',
    confirm: 'Confirmer',
    didntFindQuestion: "Vous n'avez pas trouvé la question que vous cherchez ?",
    yourMessage: 'Votre message',
    sendMessage: 'Envoyer message',
    contactInformation: 'Coordonnées',
    loanTenure: 'Durée du Prêt',
    selectLoanTenure: 'sélectionner la Durée du Prêt',
    interestInput: 'Entrez un montant entre ({{X}} et {{Y}})',
    months: 'Mois',
    from: 'A partir de',
    upto: "Jusqu'à",
    rateOfInterest: "Taux d'intérêt",
    tenure: 'Durée du prêt ',
    monthlyEmi: 'Mensualités',
    loadMore: 'Plus de chargement',
    contactSubtitle: 'Des questions ? Envoyez nous un message.',
    inspector: 'Inspection',
    inspectorSubtitle: 'Demandez à nos experts',
    insurance: 'Assurance',
    insuranceSubtitle: 'Nous comparons pour vous',
    financeSubtitles: 'Comparez les offres',
    diagnostic: 'Diagnostic',
    minPrice: 'Minimum',
    maxPrice: 'Maximum',
    minDistance: 'Minimum',
    maxDistance: 'Maximum',
    min: 'Minimum',
    max: 'Maximum',
    diagonosticSubtitle: 'Diagnostiquez votre voiture',
    VINVerify: 'Vérification VIN',
    VINVerifySubtitle: 'Nous vérifions les voitures à distance',
    learnMore: 'En Savoir Plus',
    speedRegular: 'Régulateur de vitesse',
    airCondition: 'Climatisation',
    sequential: 'Manettes de changement de vitesse ',
    CDPlayer: 'Lecteur CD',
    reversingCamera: 'Camera de recul',
    reversingRadar: 'Radar de recul',
    GPSNavigation: 'Navigation GPS ',
    parkAssist: 'Park Assist',
    startAndStop: 'Start and Stop',
    airbag: 'Airbag',
    ABS: 'ABS',
    computer: 'Ordinateur de bord',
    rims: 'Jantes',
    sunroof: 'Toit ouvrant',
    electricMirrors: 'Rétroviseurs électriques',
    electricWindows: 'Vitres électriques',
    dateFirstUse: 'Date de mise en circulation',
    seats: 'Places',
    certifiedUsedCars: 'Occasions Certifiées',
    certifiedUsedCarsSubtitle: 'Acheter avec confiance et transparence',
    inpectionsPoints: 'Rapport Inspection 100 Points',
    inpectionsPointsSubtitle:
        'Toutes nos voitures ont été minutieusement inspectées',
    refundPolicy: '5 Jours Satisfait ou Remboursé',
    refundPolicySubtitle: 'Vous n’aimez pas la voiture, on la reprend ',
    warrentySubtitles:
        ' Moteur, boîte de transmission et pont, garantis 6 mois',
    excellentCondition: 'Excellente condition, comme neuf',
    goodCondition: 'Bon état',
    averageCondition: 'Etat moyen',
    damagedCar: 'Voiture accidentée',
    lessThan10: 'Moins de 10 000 Km',
    betweenXAndY: ' Entre {{X}} et {{Y}} Km',
    moreThan100: 'Plus de 100 000 Km',
    serviceTitle: 'Vous Achetez ou Vendez ? Nous Pouvons Vous Aider !',
    serviceSubtitle:
        'Nous mettons à disposition de tous nos experts et notre expertise dans l’automobile',
    verifyAccount: 'Vérifiez votre compte',
    verifyNumber: 'Vérifier le Numéro',
    otpVerificationSubtitle:
        'Entrez les 5 chiffres reçus sur {{code}} {{number}}',
    otpVerificationEmail: 'Entrez les 5 chiffres reçus sur {{email}}',
    didntGetTheCode: ' Vous n’avez pas reçu le code?',
    resendCode: 'Renvoyer le code',
    bluetooth: 'Bluetooth',
    home: 'Accueil',
    lookingForBestInsurance: 'Vous recherchez la meilleure assurance?',
    ourCustomerRepresentativesWillGetBackInTouchWithYou:
        'Nos conseillers vont prendre contact avec vous dans les meilleurs délais.',
    upload: 'Upload',
    profileUpload: 'Ajouter une nouvelle photo',
    remove: 'Supprimer',
    xIsrequired: '{{name}} is required',
    number: 'Téléphone',
    backToTheCarDescription: 'Retour à la description du véhicule',
    backToCarDetail: 'Retour au détail de la voiture',
    backToTheCarListing: 'Retour à la liste des voitures',
    phoneVerifiedSuccesfully: 'Numéro vérifié',
    phoneVerifiedSubtitle:
        'Votre numéro a été vérifié avec succès. Vous pouvez vous connecter',
    signWithPhone: 'Se connecter avec son téléphone',
    signWithEmail: 'Sign in with Email',
    newPriceFrom: 'Prix neuf à partir de',
    theySpeakAboutUs: 'Ils parlent de nous',
    theySpeakAboutUsSubTitle:
        'Une sélection de médias internationaux qui parlent d’AUTO24',
    carLoanSubTitle: 'Repaiement Mensuel Estimé',
    carLoanTotalRepayment: 'Montant Total',
    forgotTokenExpired: 'Password Reset Link is Expired',
    discover: 'Découvrez la voiture',
    paperWork: 'Les papiers du véhicules sont à jour et vérifiés ',
    verifyhistory: 'Nous vérifions l’historique des véhicules',
    buyBack: 'Nous reprenons votre ancienne voiture ',
    additionalPhotos: 'Photos supplémentaires',
    technicalVisit: 'Certificat de visite technique',
    keysPhotos: 'Photo des clés',
    seeKeyPhotos: 'Voir la photo',
    pleaseTryAfterSomeTime: 'Please try after some time',
    favourites: 'Favoris',
    autonomy: 'Autonomie',
    productionYear: 'Année de production',
    payWithOtherCurrencytitle: 'Payez dans une autre devise avec AUTO24',
    payWithOtherCurrencySubtitle:
        'Avec AUTO24, vous pouvez payez dans la devise de votre choix avec nos comptes bancaires internationaux.',
    convertedPrice: 'Prix dans votre devise',
    favouritesNotFoundSubtitle:
        'Vous n’avez pas encore ajouté de voitures dans vos favoris. Découvrez nos voitures et ajoutez les dans vos favoris.',
    noFavouritesFound: 'Pas de favoris',
    frontCrankOilSeal: "Joint d'huile avant",
    engineOverHaul: 'Révision moteur',
    rearCrankOilSeal: "Joint d'huile arrière",
    engineTuneUp: 'Mise au point moteur',
    engineMount: 'Montage moteur',
    oilPanGasket: "Joint de carter d'huile",
    valveCoverGasket: 'Joint de cache culbuteur',
    oilFilterBracketOring: 'Joint thorique de filtre à huile ',
    intakeManifoldGasket: "Joint de collecteur d'admission",
    waterLeak: "Fuite d'eau",
    radiator: 'Radiateur',
    engineTimingCoverGasket: 'Couvercle de carter de distribution',
    waterPump: 'Pompe a eau',
    belts: 'Ceintures',
    fuelFilter: 'Filtre à essence',
    airFilter: 'Filtre à air',
    underChassis: 'Sousbassement ',
    tieRod: 'Rotule de direction',
    ballJointLandR: 'Joint de rotule',
    idlerArm: 'Relais de direction',
    pitmanArm: 'Bras Pitman',
    steeringPump: 'Pompe de direction assistée',
    steeringHoses: 'Tuyau de servodirection ',
    steeringBox: 'Boitier de direction',
    steeringRackAndPinion: 'Cémaillère de direction & Pignons',
    frontShockLandR: 'Amortisseurs avants gauche et droite',
    rearShockLandR: 'Amortisseurs arrière gauche et droite',
    stabilizerLinkage: 'Tringlerie de stabilisateur ',
    crossJointPropellerShaft: 'Arbre de transmission transversal',
    upperArmBushing: 'Bague du bras supérieur',
    rearLowerArmBushing: 'Bague du bras inférieur droit',
    suspensionSpring: 'Ressort de suspension',
    breakSystem: 'Système de frein',
    brakePad: 'Plaquettes de frein',
    brakeDiscRotor: 'Disque de freins',
    wheelBearing: 'Roulement de roue',
    brakeMasterCLY: 'Maitre-frein',
    backBooster: 'Siège enfant ',
    brakeWheelCall: 'Etrier de frein ',
    handBrakeCable: 'Cable de frein à main',
    brakeHoses: 'Durit de frein',
    rearAxlesAndDriveShafts: 'Essieu arrière -  Arbre de transmission',
    frontAxle: 'Essieu avant',
    rearAxle: 'Essieu arrière',
    axleDifference: 'Différentiel',
    differenceFrontOrRear: 'Différentiel avant ou arrière',
    axleSealFrontOrRear: "Joint d'essieu avant ou arrière",
    oilPumpGasket: 'Joint de pompe à huile ',
    frontOilSeal: 'Front Oil Seal',
    rearOilSeal: 'Rear Oil Seal',
    transmissionMount: 'Support de boite de vitesses',
    gearStickSouta: 'Levier de boite de vitesse',
    oilCollerPipe: 'Durit de radiateur à huile',
    gearPerformance: 'Gear Performance',
    liquidSystem: 'Liquid System',
    engineOil: 'Huile moteur',
    transmissionOil: 'Huile de transmission',
    brakeFluid: 'Liquide de frein',
    engineCoolant: 'Liquide de refroidissement ',
    washerFluid: 'Lave-glaces',
    electricitySystem: 'Système électrique',
    battery: 'Batterie',
    alternator: 'Alternateur ',
    starler: 'Starter',
    lights: 'Phares',
    ac: 'Climatisation',
    centerLock: 'Verrouillage centralisé ',
    wiperBlades: 'Essuie glaces',
    troubleCode: 'Trouble Code',
    computerCods: 'Computer Cods',
    acCondensor: 'Condensateur de climatisation',
    acRadiator: 'Radiateur de climatisation ',
    interior: 'Intérieur',
    seatAndBelts: 'Sièges et ceintures',
    interiorControlsAndSwitches: 'Controles et interupteurs intérieurs',
    sunRoofAndWindows: 'Toit ouvrant et fenetres ',
    fuelAndTemperatureGauge: 'Jauge carburant et température ',
    dashboardAndGauges: 'Tableau de bord et jauges ',
    radioOrMusicSystem: 'Radio',
    sRSAirbags: 'Airbags SRS',
    steeringWheelTiltOrlock: 'Volant réglable/Neiman',
    mirrors: 'Rétroviseurs',
    bodyInspection: 'Inspection de la carrosserie',
    inspectionDate: 'Date de l’inspection',
    carInspectionReport: 'Rapport d’inspection',
    showPicture: 'Afficher la photo',
    pageNotFound: 'Page Inactive',
    pageRemoved: 'Cette page n’existe pas ou a été supprimée.',
    askQuestionOnWhatsapp: 'Une question? Contactez-nous sur whatsapp!',
    whatsappsPlaceholder: 'Une Question?',
    customerSupport: 'Service Client',
    needHelpChatWithUs: 'Discutez avec nos conseillers sur Whatsapp',
    instaTitle: 'Suivez-Nous Sur Instagram',
    instaSubTitle:
        'Soyez au courant de nos dernières actualités, offres et produits!',
    questionSentSuccessfully: 'Votre question a été envoyée',
    questionSentSuccessfullySubtitle:
        ' Votre question a bien été envoyée, nous vous reviendrons rapidement.',
    unSubscribeMessage: 'Vous avez été désinscrit des blogs Auto24.',
    numberMustBeUnique: 'Le numéro doit être unique',
    pleaseEnterFullName: 'Veuillez indiquer votre nom complet',
    saving: 'Enregistrement en cours',
    sending: ' Envoi en cours',
    signingIn: 'Connexion en cours',
    signingUp: "S'enregistre",
    loggingout: 'Déconnexion en cours',
    deleting: 'Suppression',
    querySubmittedSuccessfully: 'Demande envoyée',
    somethingWentWrong: " Une erreur s'est produite!",
    removedFromWishlist: 'Supprimé de vos favoris !!',
    addedToWishlist: 'Ajouté dans vos favoris !!',
    subscribedSuccessfully: 'Enregistrement réussi',
    alreadySubscribed: 'Déjà enregistré',
    messageIsRequired: 'Un message est requis',
    fullName: 'Nom',
    phoneNumberVerifiedSuccess:
        'Numéro vérifié avec succès ! Veuillez vous connecter.',
    verifyEmail: 'Veuillez cliquer sur le lien de vérification reçu par email.',
    sold: 'Vendu',
    new: 'nouvelle',
    used: 'Utilisée',
    popular: 'Populaires',
    makes: 'Marques',
    carTypes: 'Types de Voitures',
    explore: 'Explorer',
    followUS: 'Suivez Nous',
    none: 'Aucune',
    all: 'Toute',
    discount: 'Rabais',
    year: '{{X}} et au-delà',
    careers: 'Carrières',
    discountOff: '{{X}} de Remise',
    financeYourCarWith: 'Financez votre voiture avec',
    creditButton: 'CREDIT GRATUIT',
    loanPaymentSimulator: 'Calculateur de prêt auto',
    loanPaymentContent:
        "Nous vous facilitons l'acquisition de la voiture que vous désirez. Laissez-nous vous aider à obtenir un prêt, de la soumission des documents à l'approbation rapide du prêt.",
    vehiclePrice: 'Prix ​​du véhicule',
    noOfMonthlyPayments: 'Nombre de mensualités',
    monthlyPaymentAmount: 'Montant du paiement mensuel',
    creditRequestedAmount: 'Montant du crédit demandé',
    overallCostOfCredit: 'Crédit global',
    simulatorContribution: 'Contribution du simulateur',
    percentageOfContribution: 'Pourcentage de contribution',
    applicationFees: 'Frais de dossier',
    monthlyInsurance: 'Assurance mensuelle',
    noOfOwners: 'Nombre de propriétaires',
    condition: 'Condition',
    maisonMere: 'Maison Mere',
    imported: 'Importé',
    tamperedOdometer: 'Compteur kilométrique falsifié',
    carIssue: 'Problèmes de voiture',
    evaluateAgain: 'Évaluer à nouveau',
    marketValue: 'valeur marchande',
    downloadAuto24App: `Téléchargez l'application Auto24`,
    browseMore: `Parcourez, achetez et vendez facilement - votre compagnon automobile ultime`,
    saveTimeAndEffort: 'Économisez du temps et des efforts',
    saveTimeAndEffortSubtitle:
        'Laissez-nous vous faire gagner du temps précieux en nous occupant de tout, rendant la vente sans effort et sans stress',
    freeNoCost: 'Gratuit Sans Frais',
    freeNoCostSubtitle: `Laissez-nous nous occuper de l'inspection, de la photographie, des frais de transaction et des procédures - nous avons tout prévu`,
    competetivePrice: 'Prix compétitif',
    competetivePriceSubtitle:
        'Obtenez le meilleur prix sans tracas avec nous ; nous gérons toutes les communications avec les acheteurs et les demandes de renseignements',
    carTitle: `Détails de la voiture`,
    carDesc: `Fournissez-nous les informations sur votre voiture`,
    personalDetail: `Détails personnels`,
    personalDetailDesc: `Fournissez-nous vos informations personnelles`,
    sellCarHere: `Vendre la voiture`,
    sellCarHereDesc: `Vous pouvez obtenir une offre parfaite et nous publierons votre voiture`,
    buySellUsedCar: `Achetez et vendez des voitures d'occasion en`,
    auto24Press: `Auto24 Presse`,
    auto24PressDesc1: `Auto24 a été reconnu dans différents médias à travers le monde.`,
    auto24PressDesc2: `Auto24 a également été présenté lors de conférences à Paris/France, Lisbonne/Portugal, Barcelone/Espagne, Sydney/Australie, Londres/Royaume-Uni, Kigali/Rwanda, Bangkok/Thaïlande, Abidjan/Côte d'Ivoire, Johannesburg/Afrique du Sud, Dakar/Sénégal, Nairobi/Kenya, Casablanca/Maroc…`,
    mediaExposure: `Notre exposition médiatique`,
    mediaExposureDesc: `Ci-dessous est une sélection de notre exposition médiatique`,
    press: `Presse`,
    enterValidPhoneNumber: 'Entrez un numéro de téléphone valide',
    sellCar: 'Cliquez-ici',
    findUsGoogle: 'Trouvez-nous sur Google Maps',
    locateUs: 'Localisez-nous',
    whereFindUs: 'Où nous trouver?',
    aroundLocationText: 'Vous pouvez nous trouver autour de ces emplacements',
    facebook: 'Facebook',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    twitter: 'Twitter',
    youtube: 'Youtube',
    linkedin: 'LinkedIn',
    carsUnderPriceDescription:
        "Auto24 est votre destination idéale pour explorer une large variété de marques automobiles du monde entier. Que vous recherchiez une citadine compacte, un SUV familial ou une berline de luxe, notre plateforme vous connecte à des options qui répondent à tous les besoins et préférences. Chaque marque présentée sur Auto24 est reconnue pour son savoir-faire, sa technologie et sa fiabilité, vous offrant ainsi une tranquillité d'esprit dans votre recherche du véhicule parfait.",
    fuelPriceTracker: 'Suivi des prix du carburant',
    fuelPriceTrackerDesc:
        "Surveillez les prix du carburant avec notre outil facile à utiliser ! Recevez des mises à jour en temps réel sur les prix de l'essence dans votre région, comparez les tarifs entre différentes stations et trouvez les meilleures offres à proximité. Économisez de l'argent et prenez des décisions plus avisées à chaque plein. Gardez le contrôle de votre budget—suivez les prix du carburant dès aujourd'hui !",
    fuelPriceList: 'Liste des prix du carburant',
    lastUpdateDate: 'Date de la dernière mise à jour',
    electricityPriceTracker: "Suivi des Prix de l'Électricité",
    electricityPriceTrackerDesc:
        "Les prix de l'électricité en {{ Country_name }} peuvent fluctuer en raison des tarifs, des coupures de courant et de la demande énergétique. Notre Suivi des Prix de l'Électricité vous tient informé des derniers tarifs d'Eskom et de l'électricité afin que vous puissiez gérer efficacement vos coûts énergétiques.",
    electricityPriceList: `Liste des Prix de l'Électricité`,
    currencyConverter: 'Convertisseur de devises',
    currencyConverterDesc:
        'Restez à jour avec les taux de change en temps réel. Que vous voyagiez, fassiez des achats en ligne ou négociiez sur le marché des devises, notre Convertisseur de devises vous aide à obtenir les meilleurs taux instantanément.',
    liveExchangeRates: 'Taux de change en direct',
    updatedAsOf: 'Mis à jour le',
    kmToMi: 'Kilomètres en Miles',
    miToKm: 'Miles en Kilomètres',
    kmToMiConverter: 'Convertisseur de Kilomètres en Miles',
    miToKmConverter: 'Convertisseur de Miles en Kilomètres',
    distanceConverterDesc:
        'Convertissez rapidement et avec précision les miles en kilomètres et vice versa—idéal pour les voyages, la conduite et les calculs quotidiens !',
    mile: 'Mile',
    kilometer: 'Kilomètre',
    howToConvert: 'Comment convertir {{ unit }} ?',
    benin: 'Bénin',
    coteDIvora: "Côte d'Ivoire",
    kenya: 'Kenya',
    southAfrica: 'Afrique du Sud',
    morocco: 'Maroc',
    rwanda: 'Rwanda',
    senegal: 'Sénégal',
    tanzania: 'Tanzanie',
    togo: 'Togo',
    uganda: 'Ouganda',
    vehicleTaxCalculator: 'Calculateur de taxe sur les véhicules',
    topCarSales: 'Meilleures ventes de voitures neuves',
    drivingCostCalculator: 'Calculateur du coût de conduite',
    insuranceEstimator: "Estimateur de prime d'assurance",
    carLoanCalculator: 'Calculateur de prêt automobile',
    exploreOtherTools: "Explorer d'autres outils",
    drivingCostCalculatorDesc:
        "Comprendre vos coûts de conduite est essentiel pour budgétiser et optimiser vos dépenses de voyage. Que vous fassiez la navette quotidiennement, que vous partiez pour un long voyage en voiture ou que vous gériez une flotte de véhicules, notre Calculateur de Coût de Conduite vous aide à estimer précisément les dépenses de carburant. Planifiez à l'avance, comparez les itinéraires et prenez des décisions de voyage rentables en toute simplicité.",
    distance: 'Distance',
    fuelPrice: 'Prix du carburant',
    fuelEconomy: 'Économie de carburant',
    estimatedDrivingCost: 'Votre coût de conduite estimé est de',
    enterAllValues: 'Entrez toutes les valeurs pour calculer',
    emissionEstimator: "Calculateur d'émissions de CO2",
    emissionEstimatorDesc:
        "Mesurez votre impact sur l'environnement et découvrez des moyens de réduire vos émissions de carbone dans les transports, la consommation d'énergie et les habitudes quotidiennes. Prenez le contrôle de vos efforts de durabilité et contribuez à une planète plus saine.",
    estimatedEmission: 'Votre émission estimée de CO2 est de',
    emissionFactor: "Facteur d'émission",
    efficiency: 'Efficacité',
    bestSellingCarsOfYear: 'Voitures les plus vendues de {{ CurrentYear }}',
    discoverBestSellingCarsOfYear:
        "Découvrez les voitures les plus vendues de {{ CurrentYear }} : Un aperçu complet des tendances automobiles mondiales, des modèles les plus vendus et de l'essor des véhicules électriques.",
    loanAmount: 'Montant du prêt',
    carLoanCalculatorDesc:
        "Planifiez votre prochain achat de voiture en toute confiance en calculant les mensualités, les intérêts totaux et le coût global du prêt. Que vous achetiez un véhicule neuf ou d'occasion, cet outil vous aide à trouver un prêt adapté à votre budget.",
    yourEstimatedMonthlyPayment: 'Votre paiement mensuel estimé',
    vehicleTechnicalDocuments: 'Documents techniques des véhicules',
    vehicleTechnicalDocumentsDesc:
        'Voici tous les véhicules avec leurs documents techniques.',
    vehicleName: 'Nom du véhicule',
    fileSize: 'Taille du fichier',
    modifiedDate: 'Date de modification',
    action: 'Action',
    downloadDoc: 'Télécharger le document',
    technicalDocs: 'Documents techniques',
    dataNotAvailable: 'Enregistrements non disponibles.',
    documentsAvailable: 'documents disponibles',
    downloadError: 'Échec du téléchargement',
    failedToDownload:
        "Une erreur s'est produite lors du téléchargement du fichier. Veuillez réessayer plus tard ou nous contacter pour obtenir de l'aide.",
    close: 'Fermer',

    // MessagePlus
    mp_brand_ctaText: 'Discutez avec un expert Auto24 sur WhatsApp',
    mp_brand_messageText: `Bonjour, j'ai besoin d'aide.`,
    mp_chatWidget_ctaText: 'Cliquez ici',
    mp_chatWidget_desktopText:
        'Discutez avec un expert AUTO24 sur WhatsApp en scannant ce QR code',
    mp_chatWidget_mobileText:
        'Discutez avec un expert AUTO24 sur WhatsApp en cliquant sur ce bouton'
});

export default getCommonFrench;
