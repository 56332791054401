import React from 'react';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import { useTranslation } from 'react-i18next';
import { CarSale } from '../redux/actions/bestSellingCarsAction';
import { IMAGE_URL } from '../constants';
import { formatNumberCompact } from '../utils/Helper/PriceFormatter';

const BestSellingCarsList = ({
    bestSellingCars,
    bestSellerYear
}: {
    bestSellingCars: CarSale[];
    bestSellerYear: number;
}) => {
    const { t } = useTranslation();

    const renderPositionNumber = (position: number) => {
        return position === 1
            ? '/icon/best-selling/list-1.svg'
            : position === 2
            ? '/icon/best-selling/list-2.svg'
            : position === 3
            ? '/icon/best-selling/list-3.svg'
            : '';
    };

    const BestSellingCard = ({
        position,
        car
    }: {
        position: number;
        car: CarSale;
    }) => {
        return (
            <div className="best-selling-card">
                <div className="list-number">
                    {position === 1 || position === 2 || position === 3 ? (
                        <img
                            src={renderPositionNumber(position)}
                            alt="Best Selling cars position"
                            onError={addImageFallback}
                            className="list-number-img"
                        />
                    ) : (
                        <h2>{position}</h2>
                    )}

                    <img
                        src={`${IMAGE_URL}/${car.brand.image}`}
                        alt="Best Selling cars preview"
                        onError={addImageFallback}
                        className="list-brand-logo"
                    />
                    <div className="list-brand-sold">
                        <span>
                            {car.brand.brand} - {car.model.model}
                        </span>
                        <div className="list-sold-number">
                            <span>{formatNumberCompact(car.sold)} sold</span>
                            {position === 1
                                ? '👑'
                                : position === 2
                                ? '🎉'
                                : position === 3
                                ? '😲'
                                : ''}
                        </div>
                    </div>
                </div>
                <div className="list-sold-number">
                    {position === 1
                        ? '👑'
                        : position === 2
                        ? '🎉'
                        : position === 3
                        ? '😲'
                        : ''}

                    <span>{formatNumberCompact(car.sold)} sold</span>
                </div>
            </div>
        );
    };

    return (
        <div className="best-selling-list">
            <h2>
                {t('bestSellingCarsOfYear', {
                    CurrentYear: bestSellerYear
                })}
            </h2>
            <div className="best-selling-card-group">
                {bestSellingCars.map((car, index) => (
                    <BestSellingCard
                        key={index}
                        position={index + 1}
                        car={car}
                    />
                ))}
            </div>
        </div>
    );
};

export default BestSellingCarsList;
