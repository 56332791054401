import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from './ui/Button';
import Card from './ui/Card';
import SecSeperator from './ui/SecSeperator';
import { $FIXME } from '../constants';
import { NextChevron } from './icons/common.icons';

interface IPropType {
    title: string;
    subtitle: string;
    classes?: string;
    carData?: $FIXME;
    total?: number | null;
}

const RecentlyAdded = (props: IPropType) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const goToListing = () => {
        navigate(t('/buy-cars', { ns: 'routes' }));
    };

    return props.carData.length ? (
        <div className="recently-added-container">
            <div className={`recently-added padding ${props.classes}`}>
                <SecSeperator />
                <div className="recently-header">
                    <h1
                        className="title"
                        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                        {t(props.title, { ns: 'common' })}
                    </h1>
                    <p
                        className="subtitle"
                        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    >
                        {t(props.subtitle)}
                    </p>
                </div>

                <div className="recent-card__holder">
                    {props?.carData?.map((items: $FIXME, index: number) => {
                        return (
                            <div key={index} className="card-holder">
                                <Card item={items} />
                            </div>
                        );
                    })}
                </div>
                {!props.classes && (
                    <>
                        <a href={"#"} className={"recently-added-link text-uppercase"} onClick={goToListing}>{`${t('viewAllCars')}`}<NextChevron className='position-absolute top--2px' /></a>
                        <Button
                            class="mobile-btn text-uppercase"
                            text={t('viewAllCars')}
                            onClick={goToListing} />
                    </>
                )}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default RecentlyAdded;
