import { COUNTRY_TOP_LEVEL_DOMAIN } from '../../constants';

const getMetaEnglish = (topLevelDomain: string) => {
    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.bj:
            return {
                homePageTitle:
                    'AUTO24.bj - Certified Used Cars in Benin | Buy & Sell Cars',
                homePageDesc:
                    'Looking for a certified used car in Benin? AUTO24.bj is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywords:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Benin, Quality Used Cars, Auto Sales Benin',
                homePageTitleOg:
                    'AUTO24.bj - Certified Used Cars in Benin | Buy & Sell Cars',
                homePageDescOg:
                    'Looking for a certified used car in Benin? AUTO24.bj is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Benin, Quality Used Cars, Auto Sales',
                buyPageTitle:
                    'Cars for Sale in Benin| Get the best prices on cars today-auto24.bj',
                buyPageDesc:
                    'Explore a wide range of certified used {{ BRAND }} cars at AUTO24.bj. Simplifying your search for the ideal car that fits your budget and lifestyle in Benin.',
                buyPageKeywords:
                    'Buy Cars, Certified Used Cars, AUTO24, Car Shopping, Affordable Cars in Benin',
                buyPageTitleOg:
                    'Cars for Sale in Benin| Get the best prices on cars today-auto24.bj',
                buyPageDescOg:
                    'Explore a wide range of certified used cars at AUTO24.bj. Simplifying your search for the ideal car that fits your budget and lifestyle in Benin.',
                buyPageKeywordsOg:
                    'Buy Cars Benin, Certified Used Cars, AUTO24 Benin, Car Shopping, Affordable Cars Benin',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.bj',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.bj! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Car Benin, Used Car Sales Benin, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Benin',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.bj',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.bj! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    'Sell Car Benin, Used Car Sales Benin, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Benin',
                aboutPageTitle:
                    'About AUTO24.bj - Your Trusted Local Used Car Marketplace in Benin',
                aboutPageDesc:
                    "Learn more about AUTO24.bj , Benin's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywords:
                    'About AUTO24 Benin, Certified Used Car Marketplace, Trusted Car Sales, Benin Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.bj - Your Trusted Local Used Car Marketplace in Benin',
                aboutPageDescOg:
                    "Learn more about AUTO24.bj , Benin's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywordsOg:
                    'About AUTO24 Benin, Certified Used Car Marketplace, Trusted Car Sales, Benin Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.bj',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.bj-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Benin, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.bj',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.bj-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Benin, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.bj',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.bj. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Benin, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.bj',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.bj. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Benin, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.bj',
                blogPageDesc:
                    'Visit Auto24.bj for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Benin, Car Tricks',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.bj',
                blogPageDescOg:
                    'Visit Auto24.bj for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Benin, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Benin',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Benin. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Benin | Get the best price in AUTO24 Benin',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Benin | Get the best price in AUTO24 Benin',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Benin | Get the best price in AUTO24 Benin',
                carsUnderPageDesc:
                    "Explore a wide range of certified used cars under {{ PRICE }} at AUTO24.ci. Simplifying your search for the ideal car that fits your budget and lifestyle in Côte d'Ivoire.",
                fuelPriceTrackerTitle:
                    'Latest fuel prices in Benin - AUTO24.bj',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Benin. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Benin, Benin fuel news, fuel price tracker, fuel price comparison, Benin fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Benin - AUTO24.bj',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Benin. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Benin, electricity tariff updates, electricity price tracker, energy cost comparison, Benin electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.bj',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.bj',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.bj',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.bj. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.bj',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in Benin - Auto24.bj',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in Benin. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Benin, Most sold cars in Benin, Car rankings of {{ CurrentYear }}, Best vehicles in Benin, Auto market {{ CurrentYear }}, Car sales data in Benin',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Benin - Estimate Your Monthly Payments & Interest - Auto24.bj',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Benin, total interest, and overall loan costs. Plan your next car purchase in Benin with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.bj | Certified Used Cars in Benin',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.bj. Find specifications, manuals, and guides to help you make informed decisions in Benin.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 Benin, car specifications, vehicle guides'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            return {
                homePageTitle:
                    "AUTO24.ci - Voiture Occasion Abidjan, Côte d'Ivoire | Buy & Sell Certified Used Cars",
                homePageDesc:
                    "Looking for a certified used car, Voiture Occasion in Abidjan, Côte d'Ivoire? AUTO24.ci is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.",
                homePageKeywords:
                    "Certified Used Cars, Voiture Occasion, Buy Used Cars Abidjan, Sell Used Cars Côte d'Ivoire, Car Marketplace, AUTO24.ci, Quality Used Cars, Auto Sales Côte d'Ivoire",
                homePageTitleOg:
                    "AUTO24.ci - Certified Used Cars in Abidjan, Côte d'Ivoire | Buy & Sell Cars Locally",
                homePageDescOg:
                    "Looking for a certified used car in Abidjan, Côte d'Ivoire? AUTO24.ci is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.",
                homePageKeywordsOg:
                    "Certified Used Cars, Buy Used Cars Abidjan, Sell Used Cars Côte d'Ivoire, Car Marketplace, AUTO24 Côte d'Ivoire, Quality Used Cars, Auto Sales Côte d'Ivoire",
                buyPageTitle:
                    "Cars for Sale in Côte d'Ivoire| Get the best prices on cars today-auto24.ci",
                buyPageDesc:
                    "Explore a wide range of certified used {{ BRAND }} cars at AUTO24.ci. Simplifying your search for the ideal car that fits your budget and lifestyle in Côte d'Ivoire.",
                buyPageKeywords:
                    "Buy Cars Abidjan, Certified Used Cars, AUTO24 Côte d'Ivoire, Car Shopping, Affordable Cars Côte d'Ivoire",
                buyPageTitleOg:
                    "Cars for Sale in Côte d'Ivoire| Get the best prices on cars today-auto24.ci",
                buyPageDescOg:
                    "Explore a wide range of certified used cars at AUTO24.ci. Simplifying your search for the ideal car that fits your budget and lifestyle in Côte d'Ivoire.",
                buyPageKeywordsOg:
                    "Buy Cars Abidjan, Certified Used Cars, AUTO24 Côte d'Ivoire, Car Shopping, Affordable Cars Côte d'Ivoire",
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.ci',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.ci! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    "Sell Car Abidjan, Used Car Sales Côte d'Ivoire, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Côte d'Ivoire",
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.ci',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.ci! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    "Sell Car Abidjan, Used Car Sales Côte d'Ivoire, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Côte d'Ivoire",
                aboutPageTitle:
                    "About AUTO24.ci - Your Trusted Local Used Car Marketplace in Abidjan, Côte d'Ivoire",
                aboutPageDesc:
                    "Learn more about AUTO24.ci , Côte d'Ivoire's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywords:
                    "About AUTO24 Côte d'Ivoire, Certified Used Car Marketplace, Trusted Car Sales, Côte d'Ivoire Car Dealership",
                aboutPageTitleOg:
                    "About AUTO24.ci - Your Trusted Local Used Car Marketplace in Abidjan, Côte d'Ivoire",
                aboutPageDescOg:
                    "Learn more about AUTO24.ci , Côte d'Ivoire's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywordsOg:
                    "About AUTO24 Côte d'Ivoire, Certified Used Car Marketplace, Trusted Car Sales, Côte d'Ivoire Car Dealership",
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.ci',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.ci-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    "Car Financing, Auto Credit, AUTO24 Côte d'Ivoire, Financing Options, Auto Loan",
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.ci',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.ci-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    "Car Financing, Auto Credit, AUTO24 Côte d'Ivoire, Financing Options, Auto Loan",
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.ci',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.ci. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    "Car Insurance, Insurance Coverage, AUTO24 Côte d'Ivoire, Used Car Insurance, Insurance Options",
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.ci',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.ci. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordsOg:
                    "Car Insurance, Insurance Coverage, AUTO24 Côte d'Ivoire, Used Car Insurance, Insurance Options",
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.ci',
                blogPageDesc:
                    'Visit Auto24.ci for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    "AUTO24 Blog, Auto News, Car Tips, Auto Market Côte d'Ivoire, Car Tricks",
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.ci',
                blogPageDescOg:
                    'Visit Auto24.ci for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    "AUTO24 Blog, Auto News, Car Tips, Auto Market Côte d'Ivoire, Car Tricks",
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Abidjan, Ivory Coast',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Abidan, Ivory Coast. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    "{{ BRAND }} cars for sale in Côte d'Ivoire | Get the best price in AUTO24 Côte d'Ivoire",
                bodyTypePageTitle:
                    "{{ BODY_TYPE }} cars for sale in Côte d'Ivoire | Get the best price in AUTO24 Côte d'Ivoire",
                carsUnderPageTitle:
                    "Buys cars under {{ PRICE }} in Côte d'Ivoire | Get the best price in AUTO24 Côte d'Ivoire",
                carsUnderPageDesc:
                    "Explore a wide range of certified used cars under {{ PRICE }} at AUTO24.ci. Simplifying your search for the ideal car that fits your budget and lifestyle in Côte d'Ivoire.",
                fuelPriceTrackerTitle:
                    "Latest fuel prices in Côte d'Ivoire - AUTO24.ci",
                fuelPriceTrackerDescMeta:
                    "Stay up-to-date with the latest fuel prices across Côte d'Ivoire. Check current petrol, diesel, and LPG rates",
                fuelPriceTrackerKeywords:
                    "Latest fuel prices in Côte d'Ivoire, Côte d'Ivoire fuel news, fuel price tracker, fuel price comparison, Côte d'Ivoire fuel price 2025",
                electricityPriceTrackerTitle:
                    "Latest Electricity Prices in Côte d'Ivoire - AUTO24.ci",
                electricityPriceTrackerDescMeta:
                    "Stay up-to-date with the latest electricity prices in Côte d'Ivoire. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.",
                electricityPriceTrackerKeywords:
                    "Latest electricity prices in Côte d'Ivoire, electricity tariff updates, electricity price tracker, energy cost comparison, Côte d'Ivoire electricity rates 2025",
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.ci',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.ci',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.ci',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.ci. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.ci',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    "Best Selling Cars of {{ CurrentYear }} in Côte d'Ivoire - Auto24.ci",
                bestSellerDescMeta:
                    "Discover the best-selling cars of {{ CurrentYear }} in Côte d'Ivoire. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!",
                bestSellerKeywords:
                    "Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Côte d'Ivoire, Most sold cars in Côte d'Ivoire, Car rankings of {{ CurrentYear }}, Best vehicles in Côte d'Ivoire, Auto market {{ CurrentYear }}, Car sales data in Côte d'Ivoire",
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Ivory Coast - Estimate Your Monthly Payments & Interest - Auto24.ci',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Ivory Coast, total interest, and overall loan costs. Plan your next car purchase in Ivory Coast with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    "Vehicle Technical Documents - AUTO24.ci | Certified Used Cars in Côte d'Ivoire",
                techDocsDesc:
                    "Explore a comprehensive collection of technical documents for certified used cars at AUTO24.ci. Find specifications, manuals, and guides to help you make informed decisions in Côte d'Ivoire.",
                techDocsKeyword:
                    "technical documents, car manuals, used cars, AUTO24 Côte d'Ivoire, car specifications, vehicle guides"
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.coke:
            return {
                homePageTitle:
                    'AUTO24.co.ke - Certified Used Cars in Kenya | Buy & Sell Cars',
                homePageDesc:
                    'Looking for a certified used car in Kenya? AUTO24.co.ke is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywords:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Kenya, Quality Used Cars, Auto Sales Kenya',
                homePageTitleOg:
                    'AUTO24.co.ke - Certified Used Cars in Kenya | Buy & Sell Cars',
                homePageDescOg:
                    'Looking for a certified used car in Kenya? AUTO24.co.ke is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Kenya, Quality Used Cars, Auto Sales',
                buyPageTitle:
                    'Cars for Sale in Kenya| Get the best prices on cars today-auto24.co.ke',
                buyPageDesc:
                    'Explore a wide range of certified used {{ BRAND }} cars at AUTO24.co.ke. Simplifying your search for the ideal car that fits your budget and lifestyle in Kenya.',
                buyPageKeywords:
                    'Buy Cars, Certified Used Cars, AUTO24, Car Shopping, Affordable Cars in Kenya',
                buyPageTitleOg:
                    'Cars for Sale in Kenya| Get the best prices on cars today-auto24.co.ke',
                buyPageDescOg:
                    'Explore a wide range of certified used cars at AUTO24.co.ke. Simplifying your search for the ideal car that fits your budget and lifestyle in Kenya.',
                buyPageKeywordsOg:
                    'Buy Cars Kenya, Certified Used Cars, AUTO24 Kenya, Car Shopping, Affordable Cars Kenya',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.co.ke',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.co.ke! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Car Kenya, Used Car Sales Kenya, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Kenya',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.co.ke',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.co.ke! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    'Sell Car Kenya, Used Car Sales Kenya, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Kenya',
                aboutPageTitle:
                    'About AUTO24.co.ke - Your Trusted Local Used Car Marketplace in Kenya',
                aboutPageDesc:
                    "Learn more about AUTO24.co.ke , Kenya's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywords:
                    'About AUTO24 Kenya, Certified Used Car Marketplace, Trusted Car Sales, Kenya Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.co.ke - Your Trusted Local Used Car Marketplace in Kenya',
                aboutPageDescOg:
                    "Learn more about AUTO24.co.ke , Kenya's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywordsOg:
                    'About AUTO24 Kenya, Certified Used Car Marketplace, Trusted Car Sales, Kenya Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.co.ke',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.co.ke-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Kenya, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.co.ke',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.co.ke-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Kenya, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.co.ke',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.co.ke. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Kenya, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.co.ke',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.co.ke. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Kenya, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.co.ke',
                blogPageDesc:
                    'Visit Auto24.co.ke for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Kenya, Car Tricks',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.co.ke',
                blogPageDescOg:
                    'Visit Auto24.co.ke for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Kenya, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Kenya',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Kenya. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Kenya | Get the best price in AUTO24 Kenya',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Kenya | Get the best price in AUTO24 Kenya',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Kenya | Get the best price in AUTO24 Kenya',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24.co.ke. Simplifying your search for the ideal car that fits your budget and lifestyle in Kenya.',
                fuelPriceTrackerTitle:
                    'Latest fuel prices in Kenya - AUTO24.co.ke',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Kenya. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Kenya, Kenya fuel news, fuel price tracker, fuel price comparison, Kenya fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Kenya - AUTO24.co.ke',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Kenya. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Kenya, electricity tariff updates, electricity price tracker, energy cost comparison, Kenya electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.co.ke',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.co.ke',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.co.ke',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.co.ke. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.co.ke',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in Kenya - Auto24.co.ke',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in Kenya. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Kenya, Most sold cars in Kenya, Car rankings of {{ CurrentYear }}, Best vehicles in Kenya, Auto market {{ CurrentYear }}, Car sales data in Kenya',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Kenya - Estimate Your Monthly Payments & Interest - Auto24.co.ke',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Kenya, total interest, and overall loan costs. Plan your next car purchase in Kenya with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.co.ke | Certified Used Cars in Kenya',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.co.za. Find specifications, manuals, and guides to help you make informed decisions in Kenya.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 Kenya, car specifications, vehicle guides'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.coza:
            return {
                homePageTitle:
                    'AUTO24.co.za - Certified Used Cars in South Africa | Buy & Sell Cars Locally',
                homePageDesc:
                    'Looking for a certified used car in South Africa? AUTO24.co.za is your go-to local marketplace for buying and selling quality used cars.',
                homePageKeywords:
                    'Certified Used Cars, Buy Cars South Africa, Sell Cars South Africa, Car Marketplace, AUTO24 South Africa, Quality Used Cars',
                homePageTitleOg:
                    'AUTO24.co.za - Certified Used Cars in South Africa | Buy & Sell Cars Locally',
                homePageDescOg:
                    'Looking for a certified used car in South Africa? AUTO24.co.za is your go-to local marketplace for buying and selling quality used cars.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Cars South Africa, Sell Cars South Africa, Car Marketplace, AUTO24 South Africa, Quality Used Cars',
                buyPageTitle:
                    'Cars for Sale in South Africa| Get the best prices on cars today-auto24.co.za',
                buyPageDesc:
                    'Explore a wide range of certified used {{ BRAND }} cars at AUTO24.co.za. Simplifying your search for the ideal car that fits your budget and lifestyle.',
                buyPageKeywords:
                    'Buy Cars South Africa, Certified Used Cars, Quality Cars, Car Shopping, Affordable Cars',
                buyPageTitleOg:
                    'Cars for Sale in South Africa| Get the best prices on cars today-auto24.co.za',
                buyPageDescOg:
                    'Explore a wide range of certified used cars at AUTO24.co.za. Simplifying your search for the ideal car that fits your budget and lifestyle.',
                buyPageKeywordsOg:
                    'Buy Cars South Africa, Certified Used Cars, Quality Cars, Car Shopping, Affordable Cars',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.co.za',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.co.za! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Cars South Africa, Used Car Sales, Quick Car Sales, Car Evaluation, Hassle-free Sales',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.co.za',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.co.za! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordOg:
                    'Sell Cars South Africa, Used Car Sales, Quick Car Sales, Car Evaluation, Hassle-free Sales',
                aboutPageTitle:
                    'About AUTO24.co.za - Your Trusted Local Used Car Marketplace',
                aboutPageDesc:
                    "Learn more about AUTO24.co.za, South Africa's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience.",
                aboutPageKeywords:
                    'About AUTO24, Certified Used Car Marketplace, Trusted Car Sales, Local Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.co.za - Your Trusted Local Used Car Marketplace',
                aboutPageDescOg:
                    "Learn more about AUTO24.co.za, South Africa's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience.",
                aboutPageKeywordsOg:
                    'About AUTO24, Certified Used Car Marketplace, Trusted Car Sales, Local Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.co.za',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.co.za-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Finance, AUTO24 Finance, Easy Financing, Car Loans South Africa',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.co.za',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.co.za-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordOg:
                    'Car Finance, AUTO24 Finance, Easy Financing, Car Loans South Africa',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.co.za',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.co.za. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    'Car Insurance, AUTO24 Insurance, Vehicle Coverage, South Africa Insurance',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.co.za',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.co.za. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordOg:
                    'Car Insurance, AUTO24 Insurance, Vehicle Coverage, South Africa Insurance',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.co.za',
                blogPageDesc:
                    'Visit Auto24.co.za for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Car Tips, Car News, South African Car Market',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.co.za',
                blogPageDescOg:
                    'Visit Auto24.co.za for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Car Tips, Car News, South African Car Market',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} R - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} R with AUTO24, South Africa',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in South Africa. Refund policy, warranty, insurance, maintenance, and all included!',
                buyCarTitle:
                    'Buy Certified Quality Used Cars in South Africa | AUTO24.co.za',
                buyCarDesc:
                    'Explore a wide range of certified used cars at AUTO24.co.za. Simplifying your search for the ideal car that fits your budget and lifestyle.',
                carFinanceFormPageTitle:
                    'Finance your used car with AUTO24.co.za',
                carFinanceFormPageDesc:
                    'Discover the best used car financing plans with AUTO24.co.za, and get our consultants to assist you now! Fast applications!',
                carFinanceFormPageTitleOg:
                    'Finance your used car with AUTO24.co.za',
                carFinanceFormPageDescOg:
                    'Discover the best used car financing plans with AUTO24.co.za, and get our consultants to assist you now! Fast applications!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in South Africa | Get the best price in AUTO24 South Africa',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in South Africa | Get the best price in AUTO24 South Africa',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in South Africa | Get the best price in AUTO24 South Africa',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24.co.za. Simplifying your search for the ideal car that fits your budget and lifestyle in South Africa.',
                fuelPriceTrackerTitle:
                    'Latest fuel prices in South Africa - AUTO24.co.za',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across South Africa. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in South Africa, South Africa fuel news, fuel price tracker, fuel price comparison, South Africa fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in South Africa - AUTO24.co.za',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in South Africa. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in South Africa, electricity tariff updates, electricity price tracker, energy cost comparison, South Africa electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.co.za',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.co.za',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.co.za',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.co.za. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.co.za',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in South Africa - Auto24.co.za',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in South Africa. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in South Africa, Most sold cars in South Africa, Car rankings of {{ CurrentYear }}, Best vehicles in South Africa, Auto market {{ CurrentYear }}, Car sales data in South Africa',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in South Africa - Estimate Your Monthly Payments & Interest - Auto24.co.za',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in South Africa, total interest, and overall loan costs. Plan your next car purchase in South Africa with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.co.za | Certified Used Cars in South Africa',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.co.za. Find specifications, manuals, and guides to help you make informed decisions in South Africa.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 South Africa, car specifications, vehicle guides'
            };

        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            return {
                homePageTitle:
                    'AUTO24.ma - Voiture Occasion Maroc | Buy & Sell Certified Used Cars',
                homePageDesc:
                    'Your go-to marketplace for buying and selling certified used cars in Morocco. Free evaluations, quick sales, and quality assurance nationwide.',
                homePageKeywords:
                    'Certified Used Cars, Voiture Occasion Maroc, Buy & Sell Cars Morocco, AUTO24 Morocco, Quality Used Cars',
                homePageTitleOg:
                    'AUTO24.ma - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide',
                homePageDescOg:
                    'Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco',
                buyPageTitle:
                    'Cars for Sale in Morocco| Get the best prices on cars today-auto24.ma',
                buyPageDesc:
                    'Explore a wide selection of certified used {{ BRAND }} cars at AUTO24.ma . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.',
                buyPageKeywords:
                    'Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco',
                buyPageTitleOg:
                    'Cars for Sale in Morocco| Get the best prices on cars today-auto24.ma',
                buyPageDescOg:
                    'Explore a wide selection of certified used cars at AUTO24.ma . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.',
                buyPageKeywordsOg:
                    'Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.ma',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.ma! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.ma',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.ma! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    'Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco',
                aboutPageTitle:
                    'About AUTO24.ma - Your Trusted Nationwide Used Car Marketplace in Morocco',
                aboutPageDesc:
                    "Learn about AUTO24.ma , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.",
                aboutPageKeywords:
                    'About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.ma - Your Trusted Nationwide Used Car Marketplace in Morocco',
                aboutPageDescOg:
                    "Learn about AUTO24.ma , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.",
                aboutPageKeywordsOg:
                    'About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.ma',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.ma-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.ma',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.ma-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.ma',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.ma. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.ma',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.ma. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.ma',
                blogPageDesc:
                    'Visit Auto24.ma for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.ma',
                blogPageDescOg:
                    'Visit Auto24.ma for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} DH - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} DH with AUTO24, Morocco',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Morocco. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Morocco | Get the best price in AUTO24 Morocco',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Morocco | Get the best price in AUTO24 Morocco',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Morocco | Get the best price in AUTO24 Morocco',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24 Morocco. Simplifying your search for the ideal car that fits your budget and lifestyle in Morocco.',
                fuelPriceTrackerTitle:
                    'Latest fuel prices in Morocco - AUTO24.ma',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Morocco. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Morocco, Morocco fuel news, fuel price tracker, fuel price comparison, Morocco fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Morocco - AUTO24.ma',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Morocco. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Morocco, electricity tariff updates, electricity price tracker, energy cost comparison, Morocco electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.ma',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.ma',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.ma',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.ma. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.ma',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in Morocco - Auto24.ma',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in Morocco. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Morocco, Most sold cars in Morocco, Car rankings of {{ CurrentYear }}, Best vehicles in Morocco, Auto market {{ CurrentYear }}, Car sales data in Morocco',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Morocco - Estimate Your Monthly Payments & Interest - Auto24.ma',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Morocco, total interest, and overall loan costs. Plan your next car purchase in Morocco with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.ma | Certified Used Cars in Morocco',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.ma. Find specifications, manuals, and guides to help you make informed decisions in Morocco.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 Morocco, car specifications, vehicle guides'
            };

        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            return {
                homePageTitle:
                    'AUTO24.rw - Certified Used Cars in Kigali, Rwanda | Buy & Sell Locally',
                homePageDesc:
                    'Your local marketplace in Kigali for buying and selling certified used cars. Experience free evaluations, fast transactions, and top-quality cars.',
                homePageKeywords:
                    'Certified Used Cars, Buy Cars Kigali, Sell Cars Rwanda, AUTO24 Rwanda, Quality Cars Kigali, Car Marketplace Rwanda',
                homePageTitleOg:
                    'AUTO24.rw - Certified Used Cars in Kigali, Rwanda | Buy & Sell Locally',
                homePageDescOg:
                    'Your local marketplace in Kigali for buying and selling certified used cars. Experience free evaluations, fast transactions, and top-quality cars.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Cars Kigali, Sell Cars Rwanda, AUTO24 Rwanda, Quality Cars Kigali, Car Marketplace Rwanda',
                buyPageTitle:
                    'Cars for Sale in Rwanda| Get the best prices on cars today-auto24.rw',
                buyPageDesc:
                    'Browse through a broad selection of certified used {{ BRAND }} cars at AUTO24.rw . Simplifying your search for the perfect car in Kigali.',
                buyPageKeywords:
                    'Buy Cars Rwanda, Certified Used Cars, AUTO24 Rwanda, Car Shopping, Affordable Cars Kigali',
                buyPageTitleOg:
                    'Cars for Sale in Rwanda| Get the best prices on cars today-auto24.rw',
                buyPageDescOg:
                    'Browse through a broad selection of certified used cars at AUTO24.rw . Simplifying your search for the perfect car in Kigali.',
                buyPageKeywordsOg:
                    'Buy Cars Rwanda, Certified Used Cars, AUTO24 Rwanda, Car Shopping, Affordable Cars Kigali',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.rw',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.rw! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Car Rwanda, Used Car Sales Kigali, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Kigali',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.rw',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.rw! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    'Sell Car Rwanda, Used Car Sales Kigali, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Kigali',
                aboutPageTitle:
                    'About AUTO24.rw - Your Trusted Local Used Car Marketplace in Kigali',
                aboutPageDesc:
                    "Learn about AUTO24.rw , Kigali's leading certified used car marketplace. We offer quality cars and a seamless buying and selling experience.",
                aboutPageKeywords:
                    'About AUTO24 Rwanda, Certified Used Car Marketplace, Trusted Car Sales, Rwanda Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.rw - Your Trusted Local Used Car Marketplace in Kigali',
                aboutPageDescOg:
                    "Learn about AUTO24.rw , Kigali's leading certified used car marketplace. We offer quality cars and a seamless buying and selling experience.",
                aboutPageKeywordsOg:
                    'About AUTO24 Rwanda, Certified Used Car Marketplace, Trusted Car Sales, Rwanda Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.rw',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.rw-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Financing Rwanda, AUTO24 Finance, Affordable Car Loans, Kigali Car Financing',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.rw',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.rw-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    'Car Financing Rwanda, AUTO24 Finance, Affordable Car Loans, Kigali Car Financing',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.rw',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.rw. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    'Car Insurance Rwanda, AUTO24 Insurance, Vehicle Insurance Kigali, Affordable Insurance',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.rw',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.rw. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordsOg:
                    'Car Insurance Rwanda, AUTO24 Insurance, Vehicle Insurance Kigali, Affordable Insurance',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.rw',
                blogPageDesc:
                    'Visit Auto24.rw for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Car Tips Rwanda, Car News Rwanda, Used Car Insights, Rwanda Car Market',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.rw',
                blogPageDescOg:
                    'Visit Auto24.rw for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Car Tips Rwanda, Car News Rwanda, Used Car Insights, Rwanda Car Market',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} RWF - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} RWF with AUTO24, Rwanda',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Rwanda. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Rwanda | Get the best price in AUTO24 Rwanda',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Rwanda | Get the best price in AUTO24 Rwanda',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Rwanda | Get the best price in AUTO24 Rwanda',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24 Rwanda. Simplifying your search for the ideal car that fits your budget and lifestyle in Rwanda.',
                fuelPriceTrackerTitle:
                    'Latest fuel prices in Rwanda - AUTO24.rw',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Rwanda. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Rwanda, Rwanda fuel news, fuel price tracker, fuel price comparison, Rwanda fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Rwanda} - AUTO24.rw',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Rwanda}. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Rwanda}, electricity tariff updates, electricity price tracker, energy cost comparison, Rwanda} electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.rw',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.rw',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.rw',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.rw. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.rw',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in Rwanda - Auto24.rw',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in Rwanda. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Rwanda, Most sold cars in Rwanda, Car rankings of {{ CurrentYear }}, Best vehicles in Rwanda, Auto market {{ CurrentYear }}, Car sales data in Rwanda',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Rwanda - Estimate Your Monthly Payments & Interest - Auto24.rw',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Rwanda, total interest, and overall loan costs. Plan your next car purchase in Rwanda with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            return {
                homePageTitle:
                    "Voitures d'Occasion Certifiées à Dakar, Sénégal | Achetez et Vendez Localement",
                homePageDesc: `Vous cherchez une voiture d'occasion certifiée à Dakar, Sénégal? AUTO24.sn est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Nous offrons des évaluations gratuites, des ventes rapides et une certification complète.`,
                homePageKeywords: `Voitures d'Occasion Certifiées, Acheter Voiture Dakar, Vendre Voiture Sénégal, Plateforme Automobile, AUTO24 Sénégal, Voitures de Qualité, Vente Auto Sénégal`,
                homePageTitleOg:
                    "Voitures d'Occasion Certifiées à Dakar, Sénégal | Achetez et Vendez Localement",
                homePageDescOg: `Vous cherchez une voiture d'occasion certifiée à Dakar, Sénégal? AUTO24.sn est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Nous offrons des évaluations gratuites, des ventes rapides et une certification complète.`,
                homePageKeywordsOg: `Voitures d'Occasion Certifiées, Acheter Voiture Dakar, Vendre Voiture Sénégal, Plateforme Automobile, AUTO24 Sénégal, Voitures de Qualité, Vente Auto Sénégal`,
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.sn',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.sn! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.sn',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.sn! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                buyPageTitle:
                    'Cars for Sale in Senegal| Get the best prices on cars today-auto24.sn',
                buyPageDesc:
                    'Find the best prices for used {{ BRAND }} cars to buy in Dakar, Senegal, with 100 points inspection, 5 days refund policy, 6 months warranty and more.',
                buyPageTitleOg:
                    'Cars for Sale in Senegal| Get the best prices on cars today-auto24.sn',
                buyPageDescOg:
                    'Find the best prices for used cars to buy in Dakar, Senegal, with 100 points inspection, 5 days refund policy, 6 months warranty and more.',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.sn',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.sn-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.sn',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.sn-100% online, competitive rates and flexible terms. Secure your dream car today!',
                aboutPageTitle:
                    ' AUTO24, a new way to buy and sell cars in Senegal',
                aboutPageDesc:
                    ' AUTO24, a new way to buy and sell cars in Senegal',
                aboutPageTitleOg:
                    ' AUTO24, a new way to buy and sell cars in Senegal',
                aboutPageDescOg:
                    ' AUTO24, a new way to buy and sell cars in Senegal',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.sn',
                blogPageDesc:
                    'Visit Auto24.sn for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.sn',
                blogPageDescOg:
                    'Visit Auto24.sn for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsAndusePageTitle: 'AUTO24 - Terms & conditions',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.sn',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.sn. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.sn',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.sn. Here to provide stress-free coverage tailored for you - protect your car today!',
                carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Dakar, Senegal',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Dakar, Senegal. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Senegal | Get the best price in AUTO24 Senegal',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Senegal | Get the best price in AUTO24 Senegal',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Senegal | Get the best price in AUTO24 Senegal',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24.sn. Simplifying your search for the ideal car that fits your budget and lifestyle in Senegal.',
                fuelPriceTrackerTitle:
                    'Latest fuel prices in Senegal - AUTO24.sn',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Senegal. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Senegal, Senegal fuel news, fuel price tracker, fuel price comparison, Senegal fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Senegal - AUTO24.sn',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Senegal. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Senegal, electricity tariff updates, electricity price tracker, energy cost comparison, Senegal electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.sn',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.sn',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.sn',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.sn. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.sn',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in Senegal - Auto24.sn',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in Senegal. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Senegal, Most sold cars in Senegal, Car rankings of {{ CurrentYear }}, Best vehicles in Senegal, Auto market {{ CurrentYear }}, Car sales data in Senegal',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Senegal - Estimate Your Monthly Payments & Interest - Auto24.sn',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Senegal, total interest, and overall loan costs. Plan your next car purchase in Senegal with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.sn | Certified Used Cars in Senegal',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.sn. Find specifications, manuals, and guides to help you make informed decisions in Senegal.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 Senegal, car specifications, vehicle guides'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.tg:
            return {
                homePageTitle:
                    'AUTO24.tg - Certified Used Cars in Togo | Buy & Sell Cars',
                homePageDesc:
                    'Looking for a certified used car in Togo? AUTO24.tg is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywords:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Togo, Quality Used Cars, Auto Sales Togo',
                homePageTitleOg:
                    'AUTO24.tg - Certified Used Cars in Togo | Buy & Sell Cars',
                homePageDescOg:
                    'Looking for a certified used car in Togo? AUTO24.tg is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Togo, Quality Used Cars, Auto Sales',
                buyPageTitle:
                    'Cars for Sale in Togo| Get the best prices on cars today-auto24.tg',
                buyPageDesc:
                    'Explore a wide range of certified used {{ BRAND }} cars at AUTO24.tg. Simplifying your search for the ideal car that fits your budget and lifestyle in Togo.',
                buyPageKeywords:
                    'Buy Cars, Certified Used Cars, AUTO24, Car Shopping, Affordable Cars in Togo',
                buyPageTitleOg:
                    'Cars for Sale in Togo| Get the best prices on cars today-auto24.tg',
                buyPageDescOg:
                    'Explore a wide range of certified used cars at AUTO24.tg. Simplifying your search for the ideal car that fits your budget and lifestyle in Togo.',
                buyPageKeywordsOg:
                    'Buy Cars Togo, Certified Used Cars, AUTO24 Togo, Car Shopping, Affordable Cars Togo',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.tg',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.tg! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Car Togo, Used Car Sales Togo, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Togo',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.tg',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.tg! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    'Sell Car Togo, Used Car Sales Togo, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Togo',
                aboutPageTitle:
                    'About AUTO24.tg - Your Trusted Local Used Car Marketplace in Togo',
                aboutPageDesc:
                    "Learn more about AUTO24.tg , Togo's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywords:
                    'About AUTO24 Togo, Certified Used Car Marketplace, Trusted Car Sales, Togo Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.tg - Your Trusted Local Used Car Marketplace in Togo',
                aboutPageDescOg:
                    "Learn more about AUTO24.tg , Togo's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywordsOg:
                    'About AUTO24 Togo, Certified Used Car Marketplace, Trusted Car Sales, Togo Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.tg',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.tg-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Togo, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.tg',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.tg-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Togo, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.tg',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.tg. Here to provide stress-free coverage tailored for you - protect your car today!.',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Togo, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.tg',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.tg. Here to provide stress-free coverage tailored for you - protect your car today!.',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Togo, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.tg',
                blogPageDesc:
                    'Visit Auto24.tg for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Togo, Car Tricks',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.tg',
                blogPageDescOg:
                    'Visit Auto24.tg for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Togo, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Togo',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Togo. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Togo | Get the best price in AUTO24 Togo',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Togo | Get the best price in AUTO24 Togo',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Togo | Get the best price in AUTO24 Togo',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24 Togo. Simplifying your search for the ideal car that fits your budget and lifestyle in Togo.',
                fuelPriceTrackerTitle: 'Latest fuel prices in Togo - AUTO24.tg',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Togo. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Togo, Togo fuel news, fuel price tracker, fuel price comparison, Togo fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Togo - AUTO24.tg',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Togo. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Togo, electricity tariff updates, electricity price tracker, energy cost comparison, Togo electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.tg',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.tg',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.tg',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.tg. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.tg',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in {{ Country_name }} - Auto24.tg',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in {{ Country_name }}. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in {{ Country_name }}, Most sold cars in {{ Country_name }}, Car rankings of {{ CurrentYear }}, Best vehicles in {{ Country_name }}, Auto market {{ CurrentYear }}, Car sales data in {{ Country_name }}',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Togo - Estimate Your Monthly Payments & Interest - Auto24.tg',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Togo, total interest, and overall loan costs. Plan your next car purchase in Togo with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.tg | Certified Used Cars in Togo',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.tg. Find specifications, manuals, and guides to help you make informed decisions in Togo.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 Togo, car specifications, vehicle guides'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.tz:
            return {
                homePageTitle:
                    'AUTO24.tz - Certified Used Cars in Tanzania | Buy & Sell Cars',
                homePageDesc:
                    'Looking for a certified used car in Tanzania? AUTO24.tz is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywords:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Tanzania, Quality Used Cars, Auto Sales Tanzania',
                homePageTitleOg:
                    'AUTO24.tz - Certified Used Cars in Tanzania | Buy & Sell Cars',
                homePageDescOg:
                    'Looking for a certified used car in Tanzania? AUTO24.tz is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Tanzania, Quality Used Cars, Auto Sales',
                buyPageTitle:
                    'Cars for Sale in Tanzania| Get the best prices on cars today-auto24.tz',
                buyPageDesc:
                    'Explore a wide range of certified used {{ BRAND }} cars at AUTO24.tz. Simplifying your search for the ideal car that fits your budget and lifestyle in Tanzania.',
                buyPageKeywords:
                    'Buy Cars, Certified Used Cars, AUTO24, Car Shopping, Affordable Cars in Tanzania',
                buyPageTitleOg:
                    'Cars for Sale in Tanzania| Get the best prices on cars today-auto24.tz',
                buyPageDescOg:
                    'Explore a wide range of certified used cars at AUTO24.tz. Simplifying your search for the ideal car that fits your budget and lifestyle in Tanzania.',
                buyPageKeywordsOg:
                    'Buy Cars Tanzania, Certified Used Cars, AUTO24 Tanzania, Car Shopping, Affordable Cars Tanzania',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.tz',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.tz! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Car Tanzania, Used Car Sales Tanzania, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Tanzania',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.tz',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.tz! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    'Sell Car Tanzania, Used Car Sales Tanzania, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Tanzania',
                aboutPageTitle:
                    'About AUTO24.tz - Your Trusted Local Used Car Marketplace in Tanzania',
                aboutPageDesc:
                    "Learn more about AUTO24.tz , Tanzania's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywords:
                    'About AUTO24 Tanzania, Certified Used Car Marketplace, Trusted Car Sales, Tanzania Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.tz - Your Trusted Local Used Car Marketplace in Tanzania',
                aboutPageDescOg:
                    "Learn more about AUTO24.tz , Tanzania's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywordsOg:
                    'About AUTO24 Tanzania, Certified Used Car Marketplace, Trusted Car Sales, Tanzania Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.tz',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.tz-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Tanzania, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.tz',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.tz-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Tanzania, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.tz',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.tz. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Tanzania, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.tz',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.tz. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Tanzania, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.tz',
                blogPageDesc:
                    'Visit Auto24.tz for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Tanzania, Car Tricks',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.tz',
                blogPageDescOg:
                    'Visit Auto24.tz for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Tanzania, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Tanzania',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Tanzania. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Tanzania | Get the best price in AUTO24 Tanzania',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Tanzania | Get the best price in AUTO24 Tanzania',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Tanzania | Get the best price in AUTO24 Tanzania',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24 Tanzania. Simplifying your search for the ideal car that fits your budget and lifestyle in Tanzania.',
                fuelPriceTrackerTitle:
                    'Latest fuel prices in Tanzania - AUTO24.tz',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Tanzania. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Tanzania, Tanzania fuel news, fuel price tracker, fuel price comparison, Tanzania fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Tanzania - AUTO24.tz',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Tanzania. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Tanzania, electricity tariff updates, electricity price tracker, energy cost comparison, Tanzania electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.tz',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.tz',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.tz',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.tz. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.tz',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in Tanzania - Auto24.tz',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in Tanzania. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Tanzania, Most sold cars in Tanzania, Car rankings of {{ CurrentYear }}, Best vehicles in Tanzania, Auto market {{ CurrentYear }}, Car sales data in Tanzania',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Tanzania - Estimate Your Monthly Payments & Interest - Auto24.tz',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Tanzania, total interest, and overall loan costs. Plan your next car purchase in Tanzania with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.ug | Certified Used Cars in Tanzania',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.tz. Find specifications, manuals, and guides to help you make informed decisions in Tanzania.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 Tanzania, car specifications, vehicle guides'
            };

        case COUNTRY_TOP_LEVEL_DOMAIN.ug:
            return {
                homePageTitle:
                    'AUTO24.ug - Certified Used Cars in Uganda | Buy & Sell Cars',
                homePageDesc:
                    'Looking for a certified used car in Uganda? AUTO24.ug is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywords:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Uganda, Quality Used Cars, Auto Sales Uganda',
                homePageTitleOg:
                    'AUTO24.ug - Certified Used Cars in Uganda | Buy & Sell Cars',
                homePageDescOg:
                    'Looking for a certified used car in Uganda? AUTO24.ug is your go-to local marketplace for buying and selling quality used cars. Enjoy free evaluations, quick sales, and comprehensive certification.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars, Sell Used Cars, Car Marketplace, AUTO24 Uganda, Quality Used Cars, Auto Sales',
                buyPageTitle:
                    'Cars for Sale in Uganda| Get the best prices on cars today-auto24.ug',
                buyPageDesc:
                    'Explore a wide range of certified used {{ BRAND }} cars at AUTO24.ug. Simplifying your search for the ideal car that fits your budget and lifestyle in Uganda.',
                buyPageKeywords:
                    'Buy Cars, Certified Used Cars, AUTO24, Car Shopping, Affordable Cars in Uganda',
                buyPageTitleOg:
                    'Cars for Sale in Uganda| Get the best prices on cars today-auto24.ug',
                buyPageDescOg:
                    'Explore a wide range of certified used cars at AUTO24.ug. Simplifying your search for the ideal car that fits your budget and lifestyle in Uganda.',
                buyPageKeywordsOg:
                    'Buy Cars Uganda, Certified Used Cars, AUTO24 Uganda, Car Shopping, Affordable Cars Uganda',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.ug',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.ug! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Car Uganda, Used Car Sales Uganda, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Uganda',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.ug',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.ug! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    'Sell Car Uganda, Used Car Sales Uganda, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Uganda',
                aboutPageTitle:
                    'About AUTO24.ug - Your Trusted Local Used Car Marketplace in Uganda',
                aboutPageDesc:
                    "Learn more about AUTO24.ug , Uganda's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywords:
                    'About AUTO24 Uganda, Certified Used Car Marketplace, Trusted Car Sales, Uganda Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.ug - Your Trusted Local Used Car Marketplace in Uganda',
                aboutPageDescOg:
                    "Learn more about AUTO24.ug , Uganda's premier certified used car marketplace. We offer quality cars and a seamless buying and selling experience for our local customers.",
                aboutPageKeywordsOg:
                    'About AUTO24 Uganda, Certified Used Car Marketplace, Trusted Car Sales, Uganda Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.ug',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.ug-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Uganda, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.ug',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.ug-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Uganda, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.ug',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.ug. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Uganda, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.ug',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.ug. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Uganda, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.ug',
                blogPageDesc:
                    'Visit Auto24.ug for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Uganda, Car Tricks',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.ug',
                blogPageDescOg:
                    'Visit Auto24.ug for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Auto Market Uganda, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} FCFA - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Uganda',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars in Uganda. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Uganda | Get the best price in AUTO24 Uganda',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Uganda | Get the best price in AUTO24 Uganda',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Uganda | Get the best price in AUTO24 Uganda',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24 Uganda. Simplifying your search for the ideal car that fits your budget and lifestyle in Uganda.',
                fuelPriceTrackerTitle:
                    'Latest fuel prices in Uganda - AUTO24.ug',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Uganda. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Uganda, Uganda fuel news, fuel price tracker, fuel price comparison, Uganda fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Uganda - AUTO24.ug',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Uganda. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Uganda, electricity tariff updates, electricity price tracker, energy cost comparison, Uganda electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.ug',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.ug',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily.',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.ug',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.ug. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.ug',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in Uganda - Auto24.ug',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in Uganda. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Uganda, Most sold cars in Uganda, Car rankings of {{ CurrentYear }}, Best vehicles in Uganda, Auto market {{ CurrentYear }}, Car sales data in Uganda',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Uganda - Estimate Your Monthly Payments & Interest - Auto24.ug',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Uganda, total interest, and overall loan costs. Plan your next car purchase in Uganda with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.ug | Certified Used Cars in Uganda',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.ug. Find specifications, manuals, and guides to help you make informed decisions in Uganda.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 Uganda, car specifications, vehicle guides'
            };
        default:
            return {
                homePageTitle:
                    'AUTO24.ma - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide',
                homePageDesc:
                    'Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.',
                homePageKeywords:
                    'Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco',
                homePageTitleOg:
                    'AUTO24.ma - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide',
                homePageDescOg:
                    'Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.',
                homePageKeywordsOg:
                    'Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco',
                buyPageTitle:
                    'Cars for Sale in Morocco| Get the best prices on cars today-auto24.ma',
                buyPageDesc:
                    'Explore a wide selection of certified used {{ BRAND }} cars at AUTO24.ma . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.',
                buyPageKeywords:
                    'Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco',
                buyPageTitleOg:
                    'Cars for Sale in Morocco| Get the best prices on cars today-auto24.ma',
                buyPageDescOg:
                    'Explore a wide selection of certified used {{ BRAND }} cars at AUTO24.ma . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.',
                buyPageKeywordsOg:
                    'Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco',
                sellPageTitle:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.ma',
                sellPageDesc:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.ma! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywords:
                    'Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco',
                sellPageTitleOg:
                    'Sell Your Car Quickly & Easily | Get Instant Cash Offers Today - auto24.ma',
                sellPageDescOg:
                    "Looking to sell your car?Get a top cash offer instantly at auto24.ma! With a quick and hassle-free process, you can sell your car today and get paid fast. Don't wait—see how much your car is worth now!",
                sellPageKeywordsOg:
                    'Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco',
                aboutPageTitle:
                    'About AUTO24.ma - Your Trusted Nationwide Used Car Marketplace in Morocco',
                aboutPageDesc:
                    "Learn about AUTO24.ma , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.",
                aboutPageKeywords:
                    'About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership',
                aboutPageTitleOg:
                    'About AUTO24.ma - Your Trusted Nationwide Used Car Marketplace in Morocco',
                aboutPageDescOg:
                    "Learn about AUTO24.ma , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.",
                aboutPageKeywordsOg:
                    'About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership',
                financePageTitle:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.ma',
                financePageDesc:
                    'Explore hassle-free car finance with Auto24.ma-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywords:
                    'Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan',
                financePageTitleOg:
                    'Car Finance Made Hassle-Free|100% Online & Competitive Rates - auto24.ma',
                financePageDescOg:
                    'Explore hassle-free car finance with Auto24.ma-100% online, competitive rates and flexible terms. Secure your dream car today!',
                financePageKeywordsOg:
                    'Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan',
                insurePageTitle:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.ma',
                insurePageDesc:
                    'Obtain the best car insurance policy at the best price with Auto24.ma. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywords:
                    'Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options',
                insurePageTitleOg:
                    'Secure Reliable Car Insurance, Stress-Free Coverage at the best price - auto24.ma',
                insurePageDescOg:
                    'Obtain the best car insurance policy at the best price with Auto24.ma. Here to provide stress-free coverage tailored for you - protect your car today!',
                insurePageKeywordsOg:
                    'Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options',
                blogPageTitle:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.ma',
                blogPageDesc:
                    'Visit Auto24.ma for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywords:
                    'AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks',
                blogPageTitleOg:
                    'Car Maintenance Tips & Auto Industry Insights-auto24.ma',
                blogPageDescOg:
                    'Visit Auto24.ma for expert car maintenance tips, industry trends, and insights on the latest automotive insights. Stay ahead on car care and updates!',
                blogPageKeywordsOg:
                    'AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks',
                qNaPageTitle: 'AUTO24 - Frequently asked questions ',
                contactUsPageTitle: ' AUTO24 - Contact us',
                privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
                termsNusePageTitle: 'AUTO24 - Terms & conditions',
                carDetailPageTitle: 'Buy {{make}},{{price}} DH - AUTO24',
                carDetailPageDesc:
                    'Buy a {{make}} certified used car at {{PRIX}} DH with AUTO24',
                buyPageDescWhenBrand:
                    'Best prices and services for {{BRAND}} used cars. Refund policy, warranty, insurance, maintenance, and all included!',
                brandPageTitle:
                    '{{ BRAND }} cars for sale in Morocco | Get the best price in AUTO24 Morocco',
                bodyTypePageTitle:
                    '{{ BODY_TYPE }} cars for sale in Morocco | Get the best price in AUTO24 Morocco',
                carsUnderPageTitle:
                    'Buys cars under {{ PRICE }} in Morocco | Get the best price in AUTO24 Morocco',
                carsUnderPageDesc:
                    'Explore a wide range of certified used cars under {{ PRICE }} at AUTO24.ma. Simplifying your search for the ideal car that fits your budget and lifestyle in Morocco.',
                fuelPriceTrackerTitle:
                    'Latest fuel prices in Morocco - AUTO24.ma',
                fuelPriceTrackerDescMeta:
                    'Stay up-to-date with the latest fuel prices across Morocco. Check current petrol, diesel, and LPG rates',
                fuelPriceTrackerKeywords:
                    'Latest fuel prices in Morocco, Morocco fuel news, fuel price tracker, fuel price comparison, Morocco fuel price 2025',
                electricityPriceTrackerTitle:
                    'Latest Electricity Prices in Morocco - AUTO24.ma',
                electricityPriceTrackerDescMeta:
                    'Stay up-to-date with the latest electricity prices in Morocco. Track electricity tariffs, monitor price fluctuations, and manage your energy costs effectively.',
                electricityPriceTrackerKeywords:
                    'Latest electricity prices in Morocco, electricity tariff updates, electricity price tracker, energy cost comparison, Morocco electricity rates 2025',
                currencyConverterTitle:
                    'Currency Converter - Live Exchange Rates Today in {{ Country_name }} - Auto24.ma',
                currencyConverterDescMeta:
                    'Calculate live currency and foreign exchange rates with the free Auto24 Currency Converter in {{ Country_name }}. Convert between all major global currencies with this currency calculator and view the live mid-market rates.',
                currencyConverterKeywords:
                    'Real-Time Exchange Rates, Forex Calculator, Currency Exchange Rates, AUTO 24 Currency Converter, Best Exchange Rates, Forex Rates, Currency Conversion Tool in {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.ma',
                distanceConverterDescMeta:
                    '{{ title }} in {{ Country_name }}. {{ howToConvert }} easily',
                distanceConverterKeywords:
                    '{{ title }}. Convert {{ op1 }} and {{ op2 }} in {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Driving Cost Calculator | Estimate Fuel Expenses for Any Trip in {{ Country_name }} - Auto24.ma',
                drivingCostCalculatorDescMeta:
                    'Calculate your driving costs in {{ Country_name }} with Auto24.ma. Estimate fuel expenses based on fuel price, distance, and vehicle fuel efficiency. Plan your trips wisely and save on fuel costs!',
                drivingCostCalculatorKeywords:
                    'Driving cost calculator, Fuel cost estimator, Trip cost calculator, Fuel consumption calculator, Fuel price calculator, Auto24 Road trip cost calculator, Fuel price in {{ Country_name }}, Car travel expenses {{ Country_name }}',
                emissionCalculatorTitle:
                    'CO2 Emission Calculator in {{ Country_name }} – Measure Your Carbon Footprint - Auto24.ma',
                emissionCalculatorDescMeta:
                    'Use our CO2 emission calculator in {{ Country_name }} to estimate your carbon footprint. Calculate emissions from transportation, energy use, and daily activities to reduce environmental impact.',
                emissionCalculatorKeywords:
                    'CO2 emission calculator {{ Country_name }}, carbon footprint calculator {{ Country_name }}, greenhouse gas emissions {{ Country_name }}, CO2 tracking {{ Country_name }}, emission reduction {{ Country_name }}',
                bestSellerTitle:
                    'Best Selling Cars of {{ CurrentYear }} in Morocco - Auto24.ma',
                bestSellerDescMeta:
                    'Discover the best-selling cars of {{ CurrentYear }} in Morocco. Explore top-rated vehicles based on sales, performance, and customer reviews. Find your next car with Auto24.ma today!',
                bestSellerKeywords:
                    'Best selling cars {{ CurrentYear }}, Top cars {{ CurrentYear }}, popular cars {{ CurrentYear }}, Best cars in Morocco, Most sold cars in Morocco, Car rankings of {{ CurrentYear }}, Best vehicles in Morocco, Auto market {{ CurrentYear }}, Car sales data in Morocco',
                carLoanCalculatorTitle:
                    'Car Loan Calculator in Morocco - Estimate Your Monthly Payments & Interest - Auto24.ma',
                carLoanCalculatorDescMeta:
                    'Use our car loan calculator to estimate your monthly payments in Morocco, total interest, and overall loan costs. Plan your next car purchase in Morocco with confidence and find the best loan for your budget',
                carLoanCalculatorKeywords:
                    'Car loan calculator, Auto loan calculator, Vehicle financing, Car loan interest, Monthly car payment, Loan repayment, Car financing tool, Car loan EMI, Car loan estimator',
                techDocsTitle:
                    'Vehicle Technical Documents - AUTO24.ma | Certified Used Cars in Morocco',
                techDocsDesc:
                    'Explore a comprehensive collection of technical documents for certified used cars at AUTO24.ma. Find specifications, manuals, and guides to help you make informed decisions in Morocco.',
                techDocsKeyword:
                    'technical documents, car manuals, used cars, AUTO24 Morocco, car specifications, vehicle guides'
            };
    }
};

export default getMetaEnglish;
