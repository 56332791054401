import { Api } from '../Api';
export interface CarSale {
    model: {
        _id: string;
        slug: string;
        model: string;
        brand: string;
        createdAt: string;
        updatedAt: string;
        __v: number;
    };
    brand: {
        _id: string;
        brand: string;
        image: string;
        slug: string;
        createdAt: string;
        updatedAt: string;
        __v: number;
        details?: {
            lang: string;
            description: string;
            _id: string;
        }[];
    };
    sold: number;
    _id: string;
}

export interface CarSalesData {
    _id: string;
    year: number;
    car_sales: CarSale[];
    createdAt: string;
    updatedAt: string;
    __v: number;
    status: boolean;
}

export const getBestSellingCars = async (): Promise<CarSalesData> => {
    try {
        const result = await Api.get(`/top-car-sales/latest`);
        return result.data;
    } catch (error) {
        throw new Error('Failed to fetch best selling cars');
    }
};
