import React from 'react';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import { CarSale } from '../redux/actions/bestSellingCarsAction';
import { IMAGE_URL } from '../constants';
import { formatNumberCompact } from '../utils/Helper/PriceFormatter';

const BestSellingCarsShowcase = ({
    bestSellingCars
}: {
    bestSellingCars: CarSale[];
}) => {
    const BestSellingBar = ({
        position,
        car
    }: {
        position: number;
        car: CarSale;
    }) => {
        const renderPosition = (position: number) => {
            return position === 1
                ? `/icon/best-selling/bar-2.svg`
                : position === 2
                ? `/icon/best-selling/bar-1.svg`
                : `/icon/best-selling/bar-3.svg`;
        };

        return (
            <div className="best-selling-bar-container">
                <div className="best-selling-bar-header">
                    <div className="best-vehicle-img">
                        <img
                            src={`${IMAGE_URL}/${car.brand.image}`}
                            alt="Brand preview"
                            onError={addImageFallback}
                        />
                    </div>
                    <div className="best-selling-desc">
                        <h4>
                            {car.brand.brand} - {car.model.model}
                        </h4>
                        <div className="icon-sold">
                            <span className="position-icon">
                                {position === 2
                                    ? '👑'
                                    : position === 1
                                    ? '🎉'
                                    : position === 3
                                    ? '😲'
                                    : ''}
                            </span>
                            <span className="sold-number">
                                {formatNumberCompact(car.sold)} Sold
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className={`best-selling-bar-${position} best-selling-bar`}
                >
                    <img
                        src={renderPosition(position)}
                        alt="Best selling position preview"
                        onError={addImageFallback}
                    />
                </div>
            </div>
        );
    };
    const BestSellingBarMobile = ({
        position,
        car
    }: {
        position: number;
        car: CarSale;
    }) => {
        return (
            <div className="best-selling-bar-container">
                <div
                    className={`best-selling-bar-${position}-sm best-selling-bar`}
                >
                    <div className="best-selling-desc">
                        <h4>
                            {car.brand.brand} - {car.model.model}
                        </h4>
                        <div className="icon-sold">
                            <span className="sold-number">
                                {formatNumberCompact(car.sold)} Sold
                            </span>
                        </div>
                    </div>
                </div>
                <div className="best-vehicle-img">
                    <img
                        src={`${IMAGE_URL}/${car.brand.image}`}
                        alt="Brand preview"
                        onError={addImageFallback}
                    />
                </div>
            </div>
        );
    };

    const showcaseData = [...bestSellingCars];
    if (showcaseData.length > 1) {
        const temp = showcaseData[0];
        showcaseData[0] = showcaseData[1];
        showcaseData[1] = temp;
    }

    return (
        <>
            <div className="best-selling-showcase">
                {showcaseData.map((car, index) => {
                    return (
                        <BestSellingBar
                            key={index}
                            position={index + 1}
                            car={car}
                        />
                    );
                })}
            </div>

            <div className="best-selling-showcase-sm">
                {bestSellingCars.map((car, index) => {
                    return (
                        <BestSellingBarMobile
                            key={index}
                            position={index + 1}
                            car={car}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default BestSellingCarsShowcase;
