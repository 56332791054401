const commonWolof = {
    and: 'and',
    or: 'or',
    buy: 'Jënd',
    sell: 'Jaay',
    send: 'Yonee',
    finance: 'Finance',
    about: 'About',
    blogs: 'Dibéeral',
    blog: 'Blog',
    tools: 'Ay jëfandikoo',
    callUsAt: 'Wooñu ci',
    signIn: 'Connectéwu',
    signUp: 'Bindu',
    insure: 'Assurer',
    cancel: 'Baayi',
    search: 'Seet',
    enteremailAddress: 'Dugëlële sa adresse email',
    enterPassword: 'Dugël mot de passe',
    enterFullName: 'Bindële sa tur yëp',
    enterEmailToGetResetLinkInfo:
        'Dugëlële sa email bi andak sa compte ñu mën la yoone email ak ay tektal nguir nga réinitialiser sa mot de passe',
    enterOldPassword: 'Dugëlële sa mot de passe bu yagg',
    callBackSubtitle:
        "Enter your name and phone number we'll get back to you soon",
    pleaseEnterFullName: 'Please Enter Full Name',
    pleaseprovideusyourdetails: 'Baal ñu nga jox ñu sey détails',
    pleaseprovideusyourcardetails: 'Baal ñu nga jox ñu sey détayu auto',
    desiredPleaseprovideusyourcardetails:
        'Baal ñu nga wax ñu détayu auto bi nga bëgg, tannale lumu neew neew ben.',
    enterCarDetails: 'Enter Car Details',
    pleaseInputYourName: 'Please input your Name!',
    pleaseInputYourEmail: 'Please input your Email Address!',
    pleaseInputValidEmail: 'Baal ñu nga dugël adress email bu jak!',
    pleaseInputYourPassword: 'Please input your password!',
    pleaseInputYourPhoneNumber: 'Please input your Phone number!',
    rememberMe: 'Fattelikuma',
    forgotPassword: 'Forgot Password',
    carsAvailable: 'Autos yi am jamono ji',
    termOfUse: 'Ngir liggéey bi',
    privacyPolicy: 'Lu neex kat',
    alreadyhaveanAccount: 'Already have an account?',
    howItWorks: 'Numuy doxe',
    howtoBuy: 'Nuñuy jënde',
    howtoSell: 'Nuñuy jaaye',
    exploreBlogs: 'Wërële blogs yi',
    car: 'Car',
    Certified: 'Yuñu woolu',
    Inspected: 'Yuñu seytu',
    petrol: 'Essence',
    hybrid: 'Hybrid',
    carburant: 'Carburant',
    phev: 'PHEV',
    ev: 'Electric',
    automatic: 'Automatic',
    phonenumberisnotvalid: 'Phone number is not valid',
    internalError: 'Erreuru biit, baal ñu nga jeemat wala nga diokko ak ñune',
    knowaboutyourcarbetterandgetevaluation:
        'Know about your car better and get valuation for a better deal.',
    cardetailssendSuccessfully: 'Car details sent sucessfully',
    thanyouforprovidingusdetail:
        'Thank you for provding us the details. We will give you a call back soon.',
    selectbrand: 'Select a brand',
    selectcountry: 'Select country',
    enterMileage: 'Enter Mileage',
    minMileage: 'Minimum Mileage',
    maxMileage: 'Maximum Mileage',
    selectmodel: 'Select a model',
    selectYear: 'Tannale att',
    selectorenterbrand: 'Select or enter a brand',
    selectorentermodel: 'Select or enter a model',
    selectorenterbodyType: 'Select or enter a body type',
    brand: 'Marque',
    model: 'Modèle',
    modelYear: 'Attu modèle',
    selectFuelType: 'Tannale zenru essence bi',
    selectACondition: 'Tannale condition',
    phoneNumber: 'Numéro téléphone',
    loanTitle: 'Deñuley jox njëg wi gën, am si intérêt 10%',
    loanHappyCusSubtitle:
        'Suñu mbekte ay cliane luñu soxale la moñuy doolele ñuy jooxe yeneeni ndimbële',
    loanButton: 'Calculer sama abb bi',
    calculateButton: 'Ñëb',
    blogTitle: 'Xoolale balla nguey jënd auto',
    blogSubtitle:
        'Yii ñoy mbirr yi nga wara xam balla nguey jënd beep xeetu auto ',
    viewAllCars: 'View All Cars',
    availableNowOn: 'Amena legui ci',
    account: 'Akawnt',
    myAccount: 'My Account',
    ourBrands: 'Suñuy marques',
    contactUs: 'Nuy jaaye',
    contactInformation: 'Coordonnées',
    aboutUs: `À propos`,
    addressLine: 'Address Line',
    contact: 'Contact',
    whyChooseAuto: 'Why Choose AUTO24?',
    allcarshavebeenthoroughlyinspectedandreconditionedHowweguaranteecarquality:
        'All cars have been thoroughly inspected and reconditioned. How we guarantee car quality',
    imInterested: "I'm interested",
    buyOnline: 'Buy Online',
    downPayment: 'Acompte',
    hotDeal: 'Hot deal',
    lowPrice: 'Low price',
    likeNew: 'Like new',
    moneybackSubtitle: "You don't like the car? We refund you",
    warrentyTitle: 'Guarantie 6 wer',
    warrentySubtitle:
        'Extendable to 12 months on engine, gearbox and powertrain',
    inspectionPointsSubtitle:
        'Inspected by our team of auto experts on all angles',
    roadSideAssistanceTitle: '1 year assistance',
    roadSideAssistanceSubtitle:
        'Roadside assistance included on all our vehicles, for one year',
    deliveryTitle: 'Home delivery',
    deliverySubtitle: 'We can deliver your car anywhere in Senegal',
    xDeliverySubtitle: 'We can deliver your car anywhere in {{country}}',
    Summary: 'Summary',
    engine: 'Engine',
    enginePower: 'Engine Power',
    fuelType: 'Fuel Type',
    bodyType: 'Mello yaram',
    numberofkeys: 'Number of keys',
    exteriorColor: 'Exterior Color',
    interiorColor: 'Interior Color',
    engineSize: 'Engine size',
    fiscalPower: 'Fiscal power',
    specifications: 'Specifications',
    lengthWidthHeight: 'Length,Width & Height',
    weight: 'weight',
    emissionCO2: 'Emission CO2',
    fuelConsumption: 'Fuel Consumption',
    enginepower: 'Engine Power',
    fuelTankCapacity: 'Fuel Tank Capacity',
    'performance&Economy': 'Dolle & ndincc',
    features: 'Features',
    'weight&Dimensions': 'Weight & Dimensions',
    showMoreFeatures: 'Show More Features',
    showLess: 'Show Less',
    serviceHistory: 'Service History',
    whyAUTO24: 'Why AUTO24',
    serviceCenter: 'Service Center',
    Kilometers: 'Km',
    lookingForaCarQtellUs: 'Auto nguey wêr? Wax ñu!',
    sellPageTitle: 'Jaayale sa auto ci ñaari étapes yu yomb',
    sellPageSubtitle:
        'Mën nga joxe sey détayu auto dineñula wowat pour yeneeni détails',
    findCarPricePageTitle: 'Find The Price of Your Car',
    findCarPricePageSubtitle:
        'You can provide details about your car and we will call you back for futher deals.',
    whyFinanceYourCarwithAuto24: 'Lutax nga wara financer sa auto ak AUTO24',
    financeSubtitle:
        'Deñuley jox offru financement yi gën andak intérêts yu neew ak ay acomptes',
    competitiveRates: 'Njëg yu gën yenen yi ',
    competitiveDesc: 'APR from 5% (indicative)',
    competitiveDescMA: 'Mën nañu leble te doo fay ci ay sart',
    flexiableDuration: 'Ay diir yu yollom',
    flexiableDes: 'Feyale sa auto ci ay versements yu 12 wala 72 weer',
    Percent100OnlineApproach: 'Approche 100% ci ligne bi',
    Percent100OnlineApproachDesc: 'Sign all you documents at home',
    loanCalculatorTitle: 'Nattukaayu allu auto bi',
    loadCalculatorSubtitle:
        'Njëggu intérêt par weer bi dafey wuute ndax deñuy liguey ak këri finance yu wuute',
    interestRate: 'Njëggu intérêt',
    years: 'years',
    ourFinancingPartners: 'Ñiñuy andale ci wallum finance',
    ourInsurePartners: 'Ñiñuy andale ci wallum assurance',
    ourPartners: 'Our Partners',
    copyrightfooter: 'Copyright Â© Africar Group Pty Ltd - All rights reserved',
    insureSubtitle: 'Beggele ci politiqu assurance bi gën ci njëk bi gën',
    aboutFinanceSub: 'Ñii ñen la ci ñiñuy andale ci wallum finance',
    selectMileageRange: 'Tannale gammu kilométrage bi',
    selectTransmissionType: 'Tannale typu transmission bi',
    backToHome: 'Dellu kër gua',
    getACallBack: 'Amal ñu woowat la',
    personalDetails: 'Sey détayu bop',
    carDetails: 'Détayu auto',
    ourBrandsSubtitle:
        'Marques yi gëna makk leñu lëkëlole yi am ay raaya yu bëri ndax liguey bu ame solo',
    ourTeam: 'Jëfandikoo bi',
    findCarPrice: 'Jàllugaañul bu noppu',
    teamMembers: 'Xale yi',
    ourTeamSubtitle: 'Xam ñu bu bax ci suñu xalaatu ikip yi',
    latestPosts: 'Mbind yi muj',
    lastestPostSubtitle: 'Lii blogs yi muj la ci ay seetkat yu wuute',
    featuredPosts: 'Mbind yi siiw',
    featuredPostSubtitle: 'Lii blogs yi gënë siiw la ci ay seetkat yu wuute',
    newsLetterTitle: 'Bula ben leep raw',
    newsLetterSubtitle:
        'Bindule ci blogu AUTO24 nguir wëy di jot bep xibaar ci wallum autos',
    buySellTitle: 'Pare nga?',
    buySellSubtitle:
        "Whether it's selling your used car or buying your next ride, we can do it all.",
    sellYourCar: 'Jaayale sa auto',
    buyaCar: 'Jënd auto',
    footerInfo:
        "AUTO24.ci ben filiale la bu Africar Group Pty Ltd, ñu bind ko ci Côte d'Ivoire ci turru l'IDU CI-2022-0027564 O.\n",
    FAQ: 'FAQ',
    buyCars: 'Jënd ay autos',
    sellCars: 'Jaay ay autos',
    categories: 'Catégories',
    topStories: 'Xibaar yi ci kaw',
    donthaveanaccount: "Don't have an account",
    resetPassword: 'Réinitialiser mot de passe bi',
    editProfile: 'Tëggaat sa profil',
    createNewPassword: 'Fintale mot de passe bu bess',
    passwordresetsuccessfully: 'Password reset successfully',
    passwordisRequired: 'Password is required!',
    minimum8chars: 'Minimum 8 characters are required',
    usersuccessfullyregistered: 'User successfully registered !',
    shortBio: 'As tuut ci yaw',
    Save: 'Enregistrer',
    delete: 'Tecc',
    saveChanges: 'Save Changes?',
    youcanstillchangetheseinformation: 'Ba legui mën nga sopi sey information',
    changessavedsuccessfully: 'Sopiku enregistré wu neñu ak jam',
    yourchangessavedsuccessfully: 'Sa sopiku yi enregistré wu neñu ak jam',
    deleteyourAccount: 'Tecc sa compte?',
    deleteAccountsubtitile:
        'Once you delete your account your all data will be lost and you cant  get access to your account.',
    accountdeletedsuccessfully: 'Compte bi tediuna ak jam',
    youcanstillexpolrecarwithouthavinganaccount:
        'Mën nga wëy di djiss autos yi nga ame compte am deet',
    logout: 'Deconnectéwu',
    sortBy: 'Sort By:',
    filterBy: 'Filter By:',
    lowestPriceFirst: 'Njëg wu gëna neew ci kaw',
    highestMileageFirst: 'Highest Mileage First',
    lowestMileageFirst: 'Lowest Mileage First',
    price: 'price',
    mileage: 'Mileage',
    selectBodyType: 'Tannale mello yaram bi',
    filter: 'Filters',
    moreFilters: 'Yeneen filtres',
    color: 'Culoor',
    transmission: 'Transmission',
    highestPriceFirst: 'Highest Price First',
    provideUsYourDetail: 'Provide Us Your Detail',
    interestcartitle: 'Da nga am yeene jënd auto bii?',
    interestcarsubtitle:
        'Tannale ben option, yeneen yi dineñu wëy di feñ ci sey limu jënd',
    bookThisCar: 'Denclu auto bii',
    bookThisCarSubtitle:
        'Proceed to purchase this car and book it right now. Finish your purchase in 7 days. 10% refundable section',
    scheduleAVisit: 'Jappale kañ ngey setsi',
    scheduleAVisitSubtitle:
        'Demale ci suñu bitik nguir djiss auto bi nga bëg. Dafey wëy di jappandi ci yenen jënd kat yi',
    popularblog: 'Blogs yi gëna siiw',
    popularblogSub: 'Here are Popular blogs from different users',
    category: 'Catégorie',
    carOverview: 'Car Overview',
    Detailssentsuccessfully: 'Details sent successfully',
    DetailChangeSubtitle:
        'Thank you for providing us your details. We will give you a call back soon.',
    continue: 'Yeggali',
    carnotfoundSubtitle:
        'Ben auto amule ci fane yi nga tanne. Dimbëliñu ci sey détails ba noppi ñu dimbêli la nga djiss auto bi nga yellole.',
    logoutModelTitle: 'Woor nala ni danga bëgë decconectéwu?',
    french: 'French',
    QuickLinks: 'Liens yu gaaw',
    similarResults: 'Similar Results ',
    copyrightInfo:
        "AUTO24.CI is a subsidiary of Africar Group Pty Ltd, registered under the unique identification number CI-2022-0027564 O in the Republic of Cote d'Ivoire",
    recentlyAdded: 'Yiñu yokk bu yaagule',
    recentlyAddedSubtitle:
        'Fii mën nga fe jiss autos yu wuute yuñu yokk bu yaagule',
    newCarList: 'Ñaay auto bi fenn',
    newCarListSubtitle: 'Ci, defar yi dafa wone ñaay auto bi juulit',
    electricCarList: `Ñaay ñettali ñaari`,
    electricCarListSubtitle: `Ci, defar yi dafa wone ñaay auto bi eléktrik`,
    importedCarList: `Ñaay auto bi neexkatulu`,
    importedCarListSubtitle: `Ci, defar yi dafa wone ñaay auto bi ñuy saxar`,
    cheapestCarList: 'Ñaay auto bi du {{X}}',
    cheapestCarListSubtitle:
        ' Ci, defar yi dafa wone ñaay auto bëg benn xarale {{X}} ak ñu',
    hereyoucanseedifferentsimilarcars:
        'Here you can see different similar cars',
    carNotFound: 'Djissuñu auto bi',
    whatsappUrlText: 'Hello! I am interested in this car',
    latestService: 'Latest Service',
    resetSuccessMsg:
        'Now you can sign in with your new password or you can still explore cars',
    bothPasswordMustMatch: 'Both passwords must match',
    mustBeAtleast8Characters: 'Must be at least 8 characters',
    sendResetLink: 'Yoonema lien réinitialisation',
    returnTo: 'Return to',
    resetPasswordModalTitle: 'Are you sure you want reset your password?',
    yourPasswordChangedSuccessfully: 'Sa mot de passe bi sopi nga ko ak jam',
    confirmPassword: 'Confirm Password',
    shortDescription: 'Melokaan bu gatt',
    carImages: 'Nataalu autos',
    uploadImage: 'Upload Image',
    browse: 'Browse',
    listAnother: 'Lister benen',
    listCarRequestSentSucessfully: 'List Car Request Sent Successfully',
    basicDetails: 'Détails yu yemamaay yi',
    manual: 'Manual',
    postYouHaveLiked: 'Mbind yi nga bëgg',
    enterNewPassword: 'Mot de passe bu bess',
    confirmNewPassword: 'Confirmele sa mot de passe bu bess',
    passwordChangeSuccessfully: 'Mot de passe bi sopeku na ak jam',
    verificationTitle:
        'We have sent you a verification link to you email address',
    viewLink: 'Xol lien bi',
    resendingLink: 'Yonnee lien',
    tokenExpired: 'Your token is expired please create new one',
    tokenExpiredTitle: 'Account Verification Link Expired',
    provideUsYourDetails: 'Provide us Your Detail',
    diesel: 'Diesel',
    other: 'Other',
    showCars: 'Wanel autos',
    accountVerifiedSuccessfully: 'Compte bi seytu neñuko jaar na yoon',
    engineSound: 'Engine Sound',
    showAllImage: 'Wanee nataal yëp',
    ourBenefits: 'Suñuy avantages',
    inspectionPoints: '100 bërëbu seytukaay yu suñu ñi xarañ ci auto',
    warranties: '6 months warranty (engine,gearbox and axle)',
    maintenances: '1 year maintenance package with AUTO24.sn',
    assistance: 'Remorquage ak ndimbël ci frein ci diirup 12 weer',
    multiCurrency: 'Multi-currency payment FCFA, €, $, £',
    moneyBack: 'Guarantie wu dello xaliss ci 5 fan',
    financing: 'Financing ',
    dragAndDrop: 'Drag & drop image or',
    imageFormat: 'Supported formats: JPEG, PNG',
    sellNow: 'Jaay legui',
    confirm: 'Dëgële',
    didntFindQuestion: "Didn't find the question you're looking for?",
    yourMessage: 'Sa bataaxal',
    sendMessage: 'Send Message',
    loanTenure: 'Dirrub all bi',
    selectLoanTenure: 'Ndëddu Xalam Yoon',
    interestInput: 'Degg naata ci ({{X}} ak {{Y}})',
    months: 'Wer',
    from: 'From',
    upto: 'Upto',
    rateOfInterest: 'Taux wu intérêt',
    tenure: 'Mandat',
    monthlyEmi: 'EMI par weer',
    loadMore: 'Wannele lu ëp.',
    contactSubtitle: 'Da nga loy lacc ? Mën nga ñu yoonee bataaxal sayo bëgge',
    inspector: 'Inspecteur',
    inspectorSubtitle: 'Laccele ben inspecteur',
    insurance: 'Assurance',
    insuranceSubtitle: 'Laccele assurance bi gën',
    financeSubtitles: 'Laccele finance bi gën',
    diagnostic: 'Diagnostique',
    minPrice: 'Njëg wi gënë neew',
    maxPrice: 'Njëg wi ëp',
    minDistance: 'Sorriway bi gëna neew',
    maxDistance: 'Max Distance',
    min: 'Min',
    max: 'Max',
    diagonosticSubtitle: 'Laccele diagnostique',
    VINVerify: 'Vérifier VIN',
    VINVerifySubtitle: 'Laccele vérification VIN bu gaaw',
    learnMore: 'Xam lu ëpp',
    speedRegular: 'Speed Regulator',
    airCondition: 'Air Condition',
    sequential: 'Sequential',
    CDPlayer: 'CD Player',
    reversingCamera: 'Reversing Camera',
    reversingRadar: 'Reversing Radar',
    GPSNavigation: 'GPS Navigation',
    parkAssist: 'Park Assist',
    startAndStop: 'Start and Stop',
    airbag: 'Airbag',
    ABS: 'ABS',
    computer: 'Computer',
    rims: 'Rims',
    sunroof: 'Sun Roof',
    electricMirrors: 'Electric mirrors',
    electricWindows: 'Electric windows',
    dateFirstUse: 'Date first use',
    seats: 'Seats',
    certifiedUsedCars: 'Autos yu daw yuñu woolu',
    certifiedUsedCarsSubtitle: 'Jëndële autos yu daw ci kolute ak lu leer',
    inpectionsPoints: '100 bërëbu seytu',
    inpectionsPointsSubtitle: 'Suñu autos yëp leñu seytu bu baax',
    refundPolicy: 'Politiqu dello ci 5 fan',
    refundPolicySubtitle: "You don't like it, get refunded!",
    warrentySubtitles:
        'Moteur, boïtu vitesse ak groupe motopropulseur, guarantie 6 wer',
    excellentCondition: 'Excellent condition',
    goodCondition: 'Good condition',
    averageCondition: 'Average condition',
    damagedCar: 'Damaged car',
    lessThan10: 'Less than 10 000 Km',
    moreThan100: 'More than 100 000 Km',
    serviceTitle: 'Jënd wala jaay? Mën neñula dimbëli',
    serviceSubtitle:
        'Explore our services and make your car buying / selling experience seamless ',
    verifyAccount: 'Seytu compte',
    verifyNumber: 'Seytu numero',
    didntGetTheCode: "Didn't get the code?",
    resendCode: 'Re-send code',
    bluetooth: 'Bluetooth',
    home: 'Kër gua',
    lookingForBestInsurance: 'Assurance bi gën nguey sett?',
    ourCustomerRepresentativesWillGetBackInTouchWithYou:
        'Ñifi teewale suñuy cliane dineñula woowat.',
    upload: 'Upload',
    profileUpload: 'Dugële nataal bu bess',
    remove: 'Dindi',
    number: 'Number',
    backToCarDetail: 'Back To Detail Page',
    backToTheCarDescription: 'Back to the car description',
    backToTheCarListing: 'Back To Car Listing',
    phoneVerifiedSuccesfully: 'Phone Verified successfully',
    signWithPhone: 'Sign in with Phone',
    signWithEmail: 'Sign in with Email',
    newPriceFrom: 'New Car Price From',
    theySpeakAboutUs: 'Ñu ngui wax ci ñune',
    theySpeakAboutUsSubTitle:
        'Ben séléctionwu pressu aduna bi di wax ci AUTO24',
    carLoanSubTitle: 'Fey par weer bimu nara doone',
    carLoanTotalRepayment: 'Total Repayment Amount',
    forgotTokenExpired: 'Password Reset Link is Expired',
    discover: 'Discover the car',
    paperWork: 'Vehicles paperwork are up to date and verified',
    verifyhistory: 'We verify history of all the vehicles we buy',
    buyBack: 'We buy back your old car',
    additionalPhotos: 'Additional Photos',
    technicalVisit: 'Technical visit certificate',
    keysPhotos: 'Keys photo',
    seeKeyPhotos: 'See the photo',
    pleaseTryAfterSomeTime: 'Please try after some time',
    favourites: 'Yiñu gëna bëgg',
    electric: 'Electric',
    autonomy: 'Autonomy',
    productionYear: 'Production Year',
    payWithOtherCurrencytitle: 'Pay in an other currency with AUTO24',
    payWithOtherCurrencySubtitle:
        'With AUTO24, you can pay on one of our international bank accounts in any of the currencies below. Here is the price you would need to transfer.',
    convertedPrice: 'Converted Price',
    favouritesNotFoundSubtitle:
        "You haven't added any cars to favourites. Explore cars and add your favourites",
    noFavouritesFound: 'No Favourites Found',
    frontCrankOilSeal: 'Front crank oil seal',
    engineOverHaul: 'Engine overhaul',
    rearCrankOilSeal: 'Rear crank oil seal',
    engineTuneUp: 'Engine tune up',
    engineMount: 'Engine Mount',
    oilPanGasket: 'Oil Pan Gasket',
    valveCoverGasket: 'Valve cover gasket',
    oilFilterBracketOring: 'Oil filter bracket oring',
    intakeManifoldGasket: 'Intake Manifold Gasket',
    waterLeak: 'Water leak',
    radiator: 'Radiator',
    engineTimingCoverGasket: 'Engine Timing Cover Gasket',
    waterPump: 'Water Pump',
    belts: 'Belts',
    fuelFilter: 'Fuel Filter',
    airFilter: 'Air Filter',
    underChassis: 'Under Chassis',
    tieRod: 'Tie Rod',
    ballJointLandR: 'Ball Joint L&R',
    idlerArm: 'idler Arm',
    pitmanArm: 'Pitman Arm',
    steeringPump: 'Steering Pump',
    steeringHoses: 'Steering Hoses',
    steeringBox: 'Steering Box',
    steeringRackAndPinion: 'Steering Rack & Pinion',
    frontShockLandR: 'Front Shock L&R',
    rearShockLandR: 'Rear Shock L&R',
    stabilizerLinkage: 'Stabilizer Linkage',
    crossJointPropellerShaft: 'Cross Joint Propeller Shaft',
    upperArmBushing: 'Upper Arm Bushing',
    rearLowerArmBushing: 'Rear Lower Arm Bushing',
    suspensionSpring: 'Suspension Spring',
    breakSystem: 'Break System',
    brakePad: 'Brake Pad',
    brakeDiscRotor: 'Brake Disc Rotor',
    wheelBearing: 'wheel Bearing',
    brakeMasterCLY: 'Brake Master CLY',
    backBooster: 'Back Booster',
    brakeWheelCall: 'Brake Wheel Call',
    handBrakeCable: 'Hand Brake Cable',
    brakeHoses: 'Brake Hoses',
    rearAxlesAndDriveShafts: 'Rear Axles & Drive Shafts',
    frontAxle: 'Front Axle',
    rearAxle: 'Rear Axle',
    axleDifference: 'Axle Difference',
    differenceFrontOrRear: 'Difference Front Or Rear',
    axleSealFrontOrRear: 'Axle Seal Front Or Rear',
    oilPumpGasket: 'Oil Pump Gasket',
    frontOilSeal: 'Front Oil Seal',
    rearOilSeal: 'Rear Oil Seal',
    transmissionMount: 'Transmission Mount',
    gearStickSouta: 'Gear Stick Souta',
    oilCollerPipe: 'Oil Cooler Pipe',
    gearPerformance: 'Gear Performance',
    liquidSystem: 'Liquid System',
    engineOil: 'Engine Oil',
    transmissionOil: 'Transmission Oil',
    brakeFluid: 'Brake Fluid',
    engineCoolant: 'Engine Coolant',
    washerFluid: 'Washer Fluid',
    electricitySystem: 'Electricity System',
    battery: 'Battery',
    alternator: 'Alternator',
    starler: 'Starler',
    lights: 'Lights',
    ac: 'A/C',
    centerLock: 'Center Lock',
    wiperBlades: 'Wiper Blades',
    troubleCode: 'Trouble Code',
    computerCods: 'Computer Cods',
    acCondensor: 'A/C Condenser',
    acRadiator: 'A/C Radiator',
    interior: 'Interior',
    seatAndBelts: 'Seats And Belts',
    interiorControlsAndSwitches: 'Interior Controls And Switches',
    sunRoofAndWindows: 'Sun Roof And Windows',
    fuelAndTemperatureGauge: 'Fuel And Temperature Gauge',
    dashboardAndGauges: 'Dashboard And Gauges',
    radioOrMusicSystem: 'Radio Or Music System',
    sRSAirbags: 'SRS Airbags',
    steeringWheelTiltOrlock: 'Steering Wheel Tilt Or Lock',
    mirrors: 'Mirrors',
    bodyInspection: 'Body Inspection',
    inspectionDate: 'Inspection Date',
    carInspectionReport: 'Car Inspection Report',
    showPicture: 'Show Picture',
    pageNotFound: 'Page Not Found',
    pageRemoved: "This page doesn't exist or was removed!",
    askQuestionOnWhatsapp: 'Questions? Ask us on whatsapp!',
    whatsappsPlaceholder: 'Ask anything!',
    customerSupport: 'Customer Support',
    needHelpChatWithUs: 'Need Help? Chat with us on Whatsapp',
    instaTitle: 'Topp ñu ci Instagram',
    instaSubTitle:
        'Jottale suñu offres yu muj, ay produits ak suñuy xibaar ci sassi!',
    questionSentSuccessfully: 'Yonee nga lacc yi ak jam',
    questionSentSuccessfullySubtitle:
        'Your question has been sent successfully. We will get back to you for your answer',
    unSubscribeMessage:
        'You have been successfully unsubscribed from the Auto24 Blogs.',
    numberMustBeUnique: 'Number must be unique',
    saving: 'Saving',
    sending: 'Sending',
    signingIn: 'Signing In',
    signingUp: 'Signing Up',
    loggingout: 'Logging out',
    deleting: 'Deleting',
    querySubmittedSuccessfully: 'Query submitted successfully',
    somethingWentWrong: 'Something went wrong!',
    removedFromWishlist: 'Removed from wishlist !!',
    addedToWishlist: 'Added to wishlist !!',
    subscribedSuccessfully: 'Subscribed successfully',
    alreadySubscribed: 'Already subscribed',
    messageIsRequired: 'Message is required',
    fullName: 'Full Name',
    phoneNumberVerifiedSuccess:
        'Phone number verified successfully, Please login.',
    accountVerifiedSuccessfullySubtitle:
        'Your account has been verified successfully. Do you want to login?',
    phoneVerifiedSubtitle:
        'Your phone number has been verified successfully. Do you want to login',
    doyouwantDeleteAccount: 'Danga bëga dindi sa compte ap diir?',
    verifyEmail:
        'Baal ñu nga settaat sa email ci Auto24. Yonee neñula ben lien nguir nga seytuwat ko ci sa email.',
    betweenXAndY: 'Between {{X}} and {{Y}} km',
    xIsrequired: '{{name}} is required',
    xInspectionPoints: '{{number}} inspection points',
    xlistingPageResultTitle: '{{number}} Cars Found',
    xlistingPageResultsTitle: '{{number}} Cars Found',
    carViewsXTimes: 'Viewed {{number}} times today',
    maximumXdigitsisallowed: 'Maximum {{X}} digits is allowed',
    minimumXdigit: 'Minimum {{X}} digits is allowed',
    byClickingXButton: 'By clicking {{name}} Button, you agree to our',
    otpVerificationSubtitle:
        'Enter the 5 digit code sent to {{code}} {{number}}',
    otpVerificationEmail: 'Enter the 5 digit code sent to {{email}}',
    sold: 'Jaay',
    new: 'New',
    used: 'Benn',
    popular: 'Nuyoo',
    makes: 'Yenn',
    carTypes: 'Noon nax',
    explore: 'Jàngal',
    followUS: 'Sooñu',
    none: 'None',
    all: 'All',
    discount: 'Discount',
    careers: 'Kër mu neex',
    year: 'Year {{X}} and above',
    discountOff: '{{X}} Remise',
    financeYourCarWith: 'Finance Your Car With',
    creditButton: 'CREDIT GRATUIT',
    loanPaymentSimulator: 'Kalkilateuru jumtuka caaraan',
    loanPaymentContent:
        ' Nuñu defe ko yomb nga am caaraan bi nga bëgg. Ngen ñu la jappale ci génne jumtuka, dale ko ci défar jëfandikukat yi ba gis ngir fekk ndombu jumtuka bu yéeme.',
    vehiclePrice: 'Vehicle price',
    noOfMonthlyPayments: 'Number of monthly payments',
    monthlyPaymentAmount: 'Monthly payment amount',
    creditRequestedAmount: 'Credit Requested Amount',
    overallCostOfCredit: 'Jumtuka jéggi',
    simulatorContribution: 'Simulator contribution',
    percentageOfContribution: 'Percentage of contribution',
    applicationFees: 'Application fees',
    monthlyInsurance: 'Monthly insurance',
    noOfOwners: 'Number Of Owners',
    condition: 'Condition',
    maisonMere: 'Maison Mere',
    imported: 'Imported',
    tamperedOdometer: 'Tampered Odometer',
    carIssue: 'Car Issues',
    evaluateAgain: 'Evaluate Again',
    marketValue: 'Market Value',
    downloadAuto24App: `Deejerul App Auto24`,
    browseMore: `Wara, jeuk, ak neex - sa yoon motor mbaajji xam xam`,
    saveTimeAndEffort: 'Bersaaw Waktu Ak Jëm',
    saveTimeAndEffortSubtitle:
        'Defaralu yàlla, dem yàlla fii nit, mu doxal yàlla bët, noppalu jokk na sant a yépp',
    freeNoCost: 'Jaamul Moytu Ndax Kuwotlu',
    freeNoCostSubtitle: `Féegulñu kuutunxu, féebulñu, kanamul ñoon axtuna, ak prosesuna bi - mu fii ngir wuut`,
    competetivePrice: 'Xamu Am Këyit',
    competetivePriceSubtitle:
        'Mbaa defar noppalu mu ngir daf mbaa; mu feccikelu nitulñu ju ñëwlañu ak dëggëti wut',
    carTitle: `Sumbu Jiit`,
    carDesc: `Dafay neñ benn sumbu jiit`,
    personalDetail: `Sumbu Nataal`,
    personalDetailDesc: `Dafay neñ benn sumbu nataal`,
    sellCarHere: `Jëmm Leen Jiit`,
    sellCarHereDesc: `Nga def njëm, am baat yi nga len sumbu jiit`,
    buySellUsedCar: `Jëfë ak jàppale ci biir naari tappal lu`,
    auto24Press: `Auto24 Umurongo`,
    auto24PressDesc1: `Auto24 yavutse mu birori by'ibitaramo birimo igisobanuro mu byanditse n'abandi.`,
    auto24PressDesc2: `Auto24 yatangije kwandikwa mu mikorere ibiri hagati y'abanyamahanga mu Mujyi wa Paris/France, Lisbon/Portugal, Barcelona/Spain, Sydney/Australia, London/UK, Kigali/Rwanda, Bangkok/Thailand, Abidjan/Ivory Coast, Johannesburg/South Africa, Dakar/Senegal, Nairobi/Kenya, Casablanca/Morocco…`,
    mediaExposure: `Amul yoonu laaftu bi`,
    mediaExposureDesc: `Neexu daa faw mu yoonu laaftu bi`,
    press: `Benno`,
    enterValidPhoneNumber: 'Nëkk natalu nataalul way',
    sellCar: 'Jox oto',
    findUsGoogle: 'Séén leen ci Google Map',
    locateUs: 'Waajal nu',
    whereFindUs: 'Fan nga bés nu?',
    aroundLocationText: 'Danga nu gis ci këru yii',
    facebook: 'Facebook',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    twitter: 'Twitter',
    youtube: 'Youtube',
    linkedin: 'LinkedIn',
    carsUnderPriceDescription:
        'Auto24 mooy sa buntu bees ngir xool ay turu auto yu bari ci àddina si. Bu fekkee dama la soxla auto bu ndaw ngir dëkk, SUV bu wa kër am, walla sedan bu seer, sunu platform dina la jàppale ak ay jëmmu auto yu nekk ci lo soxla ak lo bëgg. Bëkk-nañu auto yépp ci Auto24 ci seen ñoñ ak seen matukaay, seen teknoloj, ak seen yërmande, ngir may la mënal ci sa wut auto bu wóor.',
    fuelPriceTracker: 'Suivi bu prix yi essence',
    fuelPriceTrackerDesc:
        'Gis gis ci njëg yi essence ak sunu jumtukaay bu yomb! Amal ay xibaar bu bees ci njëg yi essence ci sa zone, déggal ay prix yi ci ay station yu wuute, te seet lu gën rëy ci wàll bu njëg. Sàmmal sa xaalis te jël ay ndam ci sa yittewo. Kontrole sa njàngoro—gis njëg yi essence tey!',
    fuelPriceList: 'Liist bi jiitu ci mbir yi',
    lastUpdateDate: 'Bokk ci jamono ju ëpp ci wàll gi',
    electricityPriceTracker: 'Jëfandikukat Biir Ndamli',
    electricityPriceTrackerDesc:
        'Njit ndamli ci {{ Country_name }} dina yëngu ci ndogal, génnay ndamli, ak bes bu sell. Jëfandikukat Biir Ndamli jox na la xibaar bu yàgg ci ndogal yi ci Eskom ak ndamli, ngir nga man a doxal say njit bu rafet.',
    electricityPriceList: 'Ndamli Biir Li',
    currencyConverter: 'Jumtu Jëfandikukat Yoonu Lëkkale Xaalis',
    currencyConverterDesc:
        'Xam lii nga war a xam ci njumté lëkkale xaalis. Boo amee saytu, dem seen, wala dawal bopp ci Forex, lii dina la tax a jot bu baax.',
    liveExchangeRates: 'Njitu Lëkkale Xaalis Ci Géej Géej',
    updatedAsOf: 'Mis à jour le',
    kmToMi: 'Kilomèt ba Mile',
    miToKm: 'Mile ba Kilomèt',
    kmToMiConverter: 'Tool bu Ñu Jëmale Kilomèt ci Mile',
    miToKmConverter: 'Tool bu Ñu Jëmale Mile ci Kilomèt',
    distanceConverterDesc:
        'Jëmale ci yomb ak sañ-sañ mile ba kilomèt ak ci li ci des—mu nërmëtu ci safara, daw ak xaalis bu bees!',
    mile: 'Mile',
    kilometer: 'Kilomèt',
    howToConvert: 'Naka laa ko jëmale {{ unit }}?',
    benin: 'Benin',
    coteDIvora: "Côte d'Ivoire",
    kenya: 'Kenya',
    southAfrica: 'Afrig',
    morocco: 'Moroko',
    rwanda: 'Rwanda',
    senegal: 'Senegal',
    tanzania: 'Tanzania',
    togo: 'Togo',
    uganda: 'Uganda',
    vehicleTaxCalculator: 'Waññekaay galag u watiir',
    topCarSales: 'Watiir yu bees yi gëna jaay',
    drivingCostCalculator: 'Waññekaay njëgu watu',
    insuranceEstimator: 'Seetlukaay njëgu asuraas',
    carLoanCalculator: 'Waññekaay lebu watiir',
    exploreOtherTools: 'Wutal ay jumtuka yu nekk',
    drivingCostCalculatorDesc:
        'Xam njëriñ yi nga wara fay ngir dawal mooy lu am solo ci planing ak optimisation dépenses yi nga def ngir tukki. Boo demee liggéey bés bu nekk, walla nga dem tukki bu yagg ci auto, walla nga yor ay auto yu bari, sunu Calculateur bu Njëriñ yi Dawal dina la dimbali ngir estimer bu baax dépenses yi nga def ci carburant. Defal sa planing bi, comparé yoon yi, te jël decisions yu baax ci tukki bi ci lu yomb.',
    distance: 'Distance',
    fuelPrice: 'Prix carburant',
    fuelEconomy: 'Économie carburant',
    estimatedDrivingCost: 'Sa estimation bu coût dawal mooy',
    enterAllValues: 'Dugalal mboolem ay njëriñ ngir xayma',
    emissionEstimator: 'Waññikaay CO2',
    emissionEstimatorDesc:
        'Natt sa diggante ak mbir-àdduna te gis yoon yi ngay wàññi sa CO2 ci transport, jëfandikoo xargy ak sa jëf yi bés bu nekk. Saytu say jëf ngir dund gu yaatu te bokk ci def àdduna mu am wér.',
    estimatedEmission: 'Sa CO2 bi ñu xalaat mooy',
    emissionFactor: 'Faktëru CO2',
    efficiency: 'Njariñ',
    bestSellingCarsOfYear: 'Njaaykat yi gën a jëfandikoo ci {{ CurrentYear }}',
    discoverBestSellingCarsOfYear:
        'Dëggal njaaykat yi gën a jëfandikoo ci {{ CurrentYear }}: Njàngat bu am solo ci jàmmu auto, njaaykat yi gën a jëfandikoo ak jàmmu njaaykat yi am ci elektriki.',
    loanAmount: 'Jëmaleem ngir am mburu',
    carLoanCalculatorDesc:
        'Paré na yéené ci saytu say mooyuka yu ubli, ndimbal gu jar, ak laa dioor. Naka ngir jénd ci néw wala mboq, jógkat bii dina yééné la ci jëmaleem.',
    yourEstimatedMonthlyPayment: 'Sa mooyuka yu ubli ci ayu weer',
    vehicleTechnicalDocuments: 'Dokument yu Teknike yi Njituka yi',
    vehicleTechnicalDocumentsDesc:
        'Dinañu gis njituka yépp ak dokument yu teknike yi.',
    vehicleName: 'Tur wi Njituka',
    fileSize: 'Sutura fayil',
    modifiedDate: 'Bésu soppi',
    action: 'Jëf',
    downloadDoc: 'Telecharge dokument bi',
    technicalDocs: 'Dokkument yu teknikal',
    dataNotAvailable: 'Dokkument yi amul.',
    documentsAvailable: 'dokkument yi am na',
    downloadError: 'Jaxalul telechaaji',
    failedToDownload:
        'Amna lu moytu ci telechaaji bi. Ndaxte cigënale wala nekkal ci yeneen waay ndimbal.',
    close: 'Tëj'
};

export default commonWolof;
