import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { List } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Dropdown from '../components/ui/Dropdown';
import LanguageDropdown from '../components/ui/LanguageDropDown';

import i18n from '../localization';
import { getAllCategories } from '../redux/actions/categoryAction';
import { ICombineReducerType } from '../redux/reducers';
import { ICategoryType } from '../redux/types/categoryTypes';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import { DEFAULT_LANG } from '../utils/translation';

const NavWithSearch = () => {
    const [sidebar, setSidebar] = useState(false);
    const cat = useSelector(
        (state: ICombineReducerType) => state.categories.categories
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = i18n.language;

    useEffect(() => {
        getAllCategories(dispatch);
    }, []);
    const navigate = useNavigate();
    function categoryList(item: ICategoryType) {
        let cat = contentAccordToCurrentLang(item);
        return {
            key: item?._id,
            label: (
                <div
                    onClick={() =>
                        navigate(
                            `${t('/category', { ns: 'routes' })}/${item?.slug}`,
                            {
                                state: cat?.category
                            }
                        )
                    }
                >{`${cat?.category}   (${item?.totalBlog})`}</div>
            )
        };
    }
    const items = cat.map((item, index) =>
        item.totalBlog === 0 ? null : categoryList(item)
    );
    const isMobileorTab = useMediaQuery({ query: '(max-width: 1023px)' });

    const showSidebar = () => {
        setSidebar(!sidebar);
    };

    return (
        <div className="nav-with-search">
            <div className="padding padding-container">
                <Link to={lang === DEFAULT_LANG ? '/' : `/${lang}`}>
                    <div className="logo-container">
                        <img
                            src="/logos/logo-text-blue.svg"
                            alt="auto24"
                            loading="lazy"
                        />
                    </div>
                </Link>
                <div className="navbar-middle">
                    <List
                        size={32}
                        className="hamburger"
                        onClick={showSidebar}
                    />

                    <ul
                        className={
                            isMobileorTab && sidebar
                                ? 'navbar-middle-links-mobile'
                                : 'navbar-middle-links'
                        }
                    >
                        <li>
                            <NavLink
                                to={t('/blog', { ns: 'routes' })}
                                className={({ isActive }) =>
                                    isActive
                                        ? 'navbar-middle-link-active'
                                        : 'navbar-middle-link'
                                }
                            >
                                {t('topStories')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={t('/buy-cars', { ns: 'routes' })}
                                className={({ isActive }) =>
                                    isActive
                                        ? 'navbar-middle-link-active'
                                        : 'navbar-middle-link'
                                }
                            >
                                {t('buyCars')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={t('/sell-car', { ns: 'routes' })}
                                className={({ isActive }) =>
                                    isActive
                                        ? 'navbar-middle-link-active'
                                        : 'navbar-middle-link'
                                }
                            >
                                {t('sellCars')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={t('/vehicle-technical-documents', {
                                    ns: 'routes'
                                })}
                                className={({ isActive }) =>
                                    isActive
                                        ? 'navbar-middle-link-active'
                                        : 'navbar-middle-link'
                                }
                            >
                                {t('technicalDocs')}
                            </NavLink>
                        </li>
                        <Dropdown items={items} type="other" blogsPage />

                        {!isMobileorTab && (
                            <>
                                <div className="border-light"></div>
                                <LanguageDropdown class="active noborder" />
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NavWithSearch;
