import { RcFile } from 'antd/lib/upload';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { formatMoney } from '../../components/SearchFilter';
import { $FIXME, COUNTRY_TOP_LEVEL_DOMAIN, emailRegs } from '../../constants';
import i18n from '../../localization';
import { validateNumber } from '../../redux/actions/countryActions';
import { getTopLevelDomain } from '../translation';

export const RemoveWhiteSpacesFromString = (str: string | any) => {
    if (str) {
        return str.split(' ').join('');
    }
    return '';
};
export const ReplaceWhiteSpaceWithDashString = (str: string | any) => {
    if (str) {
        return str.split(' ').join('-');
    }
    return '';
};
interface InumcheckUserType {
    code?: string;
    number?: string;
}
export const numcheck = (phoneNumber: InumcheckUserType, setError: $FIXME) => {
    if (phoneNumber?.code && phoneNumber?.number) {
        return validateNumber(phoneNumber?.code, phoneNumber?.number)
            .then((response) => {
                if (Object.hasOwn(response, 'valid')) {
                    if (response.valid) {
                        setError((prev: $FIXME) => ({
                            ...prev,
                            phoneNumber: ''
                        }));
                        return true;
                    } else {
                        setError({
                            phoneNumber: 'phonenumberisnotvalid'
                        });
                        return false;
                    }
                } else {
                    setError({
                        phoneNumber: 'pleaseTryAfterSomeTime'
                    });
                    return false;
                }
            })
            .catch((error) => {
                setError({
                    phoneNumber: 'pleaseTryAfterSomeTime'
                });
                return false;
            });
    }
};
export const returnOnlyNumber = (str: string) => str.replace(/\D/g, '');

export const returnNumberWithDecimals = (str: string) =>
    str.replace(/[^\d.-]/g, '');
export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

// content filter from backend according to current lang
export const currentLang = function () {
    return i18n.language === 'en' ? [i18n.language, 'eng'] : [i18n.language];
};
export const contentAccordToCurrentLang = function (content: any) {
    let result;

    if (content && Array.isArray(content)) {
        result = content.map((item: $FIXME) => {
            return (
                item?.details?.find((fqs: any) =>
                    currentLang().includes(fqs.lang)
                ) ?? item?.details[0]
            );
        });
    } else {
        result =
            content?.details?.find((item: any) =>
                currentLang().includes(item.lang)
            ) ?? content.details[0];
    }
    return result?.length === 1 ? result[0] : result;
};
export const contentAccordToCurrentLangs = function (content: any) {
    let result;
    if (Array.isArray(content)) {
        result = content.map((item: $FIXME) => {
            return (
                item.openingFields?.find((fqs: any) =>
                    currentLang().includes(fqs.lang)
                ) ?? item.openingFields[0]
            );
        });
    } else {
        result =
            content?.openingFields?.find((item: any) =>
                currentLang().includes(item.lang)
            ) ?? content.openingFields[0];
    }
    return result?.length === 1 ? result[0] : result;
};
export const contentAccordToCurrentLangTime = function (content: any) {
    let result;
    if (Array.isArray(content)) {
        result = content.map((item: $FIXME) => {
            return (
                item.openingFields?.find((fqs: any) =>
                    currentLang().includes(fqs.lang)
                ) ?? item.openingFields[0]
            );
        });
    } else {
        result =
            content?.openingFields?.find((item: any) =>
                currentLang().includes(item.lang)
            ) ?? content.openingFields[0];
    }
    return result?.length === 1 ? result[0] : result;
};

export const formatKm = function (n: any) {
    return (
        (Math.round(n * 100) / 100).toLocaleString().replaceAll(',', '  ') +
        ' KM'
    );
};

export const Stringfy = function (data: any) {
    if (Array.isArray(data) && data?.length) {
        return JSON.stringify(data);
    }
    return null;
};
export const useOutsideClick = (callback: any) => {
    const ref = useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const handleClick = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    return ref;
};

export const SetMeta = function SetMeta(props: {
    title?: string;
    desc?: string;
    keywords?: string;
    image?: string;
    properties?: {key: string, value: string}[]
}) {
    
    const location = useLocation();
    const { t, i18n } = useTranslation(['meta']);
    const isArabic = location.pathname.includes("/ar");
    return (
        <Helmet>
            <title dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>{props?.title ? isArabic ? t(props?.title).split("").reverse().join("") : t(props?.title) : ''}</title>
            <meta name="title" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} content={props?.title ? isArabic ? t(props?.title).split("").reverse().join("") : t(props?.title) : ''}></meta>
            <meta name="description" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} content={props?.desc ? isArabic ? t(props?.desc).split("").reverse().join("") : t(props?.desc) : ''}></meta>
            <meta property="og:title" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} content={props?.title ? isArabic ? t(props?.title).split("").reverse().join("") : t(props?.title) : ''}></meta>
            <meta property="og:description" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} content={props?.desc ? isArabic ? t(props?.desc).split("").reverse().join("") : t(props?.desc) : ''}></meta>
            <meta property="og:image" content={props?.image ?? 'https://dev-auto24.asterdio.xyz/images/hero_image.png'} />
            <meta property="twitter:title" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} content={props?.title ? isArabic ? t(props?.title).split("").reverse().join("") : t(props?.title) : ''}></meta>
            <meta property="twitter:description" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} content={props?.desc ? isArabic ? t(props?.desc).split("").reverse().join("") : t(props?.desc) : ''}></meta>
            <meta property="twitter:image" content={props?.image ?? 'https://dev-auto24.asterdio.xyz/images/hero_image.png'} />
            <meta name="keywords" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} content={props?.keywords ? isArabic ? t(props?.keywords).split("").reverse().join("") : t(props?.keywords) : ''}></meta>
            {
                props.properties && props.properties.map((item) => (
                    <meta key={item?.key} name={item.key} content={item.value ? isArabic ? t(item.value).split("").reverse().join("") : t(item.value) : ''} />
                ))
            }
        </Helmet>
    );
};
export const nameListFilter = (name: string, input: string) => {
    return name.toLowerCase().startsWith(input.toLowerCase());
};
export const fullNameValidate = function (fullName: string) {
    return fullName.split(' ').filter((item: any) => item).length > 1;
};
export const emailValidate = function (email: string) {
    return emailRegs.test(email)
};

export const getEMI = (P: number, R: number, N: number) => {
    //[P x R x (1+R)^N]/[(1+R)^N-1]
    let rate = R / 12 / 100;
    let time = N;
    const emi =
      (P * rate * Math.pow(1 + rate, time)) / (Math.pow(1 + rate, time) - 1);
    const interest = time * emi - P;
  
    return { emi: String(emi) === "NaN" ? 0 : emi, interest: String(interest) === "NaN" ? 0 : interest };
  };

  const topLevelDomain = getTopLevelDomain();

  export const getCurrencyFormat = (num: number | string) => {
    //
    let value;
    try {
      if (typeof num !== "number") {
        value = parseInt(num);
      }
    } catch (err) {
      value = 0;
    }
    if (!num) return `${topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''} 0 ${topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}`;
    value = num.toLocaleString("en-IN");
  
    return `${topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''} ${value} ${topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}`;
  };

  export function appendCurrency (value: any) {
    let amountVal: any = formatMoney(value);
    if(amountVal === 'NaN') {
        amountVal = 0;
    }
    return `${topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''} ${amountVal} ${topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza ? process.env.REACT_APP_DEFAULT_CURRENCY : ''}`;
  }

export const toSlug = (str: string) => {
    return str
      .toLowerCase()                  // Convert to lowercase
      .trim()                          // Remove leading and trailing whitespace
      .replace(/[^a-z0-9]+/g, '-')     // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, '');        // Remove leading and trailing hyphens
  }

 export const checkRequiredParams = (params: string[], state: $FIXME): string[] => {
    return params.filter(param => {
        const keys = param.split('.');
        if (keys.length === 1) {
            // Simple property check
            return !state[keys[0]];
        } else if (keys.length === 2) {
            // Nested property check
            return !state[keys[0]] || !state[keys[0]][keys[1]];
        }
        return false;
    });
};

export const toCapitalCase = (str: string | null | undefined) => {
    if (typeof str !== 'string' || str.trim() === '') {
        return ''; // Handle invalid or empty input
    }

    // Define the words to be escaped
    const escapedWords = new Set(['AUTO24']);

    // Split the string into words
    const words = str.split(' ');

    // Process each word
    const capitalizedWords = words.map(word => {
        // Remove any punctuation for accurate comparison
        const cleanWord = word.replace(/[.,?!]/g, '').toLowerCase();

        // If the word is in the set of escaped words, leave it unchanged
        if (escapedWords.has(cleanWord)) {
            return word;
        }
        
        // Capitalize the first letter of the word otherwise
        return word.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    });

    // Join the words back into a single string
    return capitalizedWords.join(' ');
}


export function formatMemorySize(bytes:number, decimals = 2) {
    if (!bytes) return '0 Bytes';
  
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
  }