import { useCallback, useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import { appendCurrency, SetMeta } from '../utils/Helper/jsHelpers';
import { useTranslation } from 'react-i18next';
import TrackerLayout from '../components/ui/TrackerLayout';
import {
    getCitizenDemonym,
    getCountryNameFromDomain
} from '../utils/translation';
import { CaretDown, CarSimple, GasPump, Money, Path } from 'phosphor-react';
import { Input, Select, Space } from 'antd';

const DrivingCostCalculator = () => {
    const defaultCurrency = process.env.REACT_APP_DEFAULT_CURRENCY;

    const [distanceUnit, setDistanceUnit] = useState('km');
    const [fuelType, setFuelType] = useState('petrol');
    const [fuelPrice, setFuelPrice] = useState(13.53);
    const [fuelPriceUnit, setFuelPriceUnit] = useState(
        `${defaultCurrency}/ltr`
    );
    const [fuelEconomyUnit, setFuelEconomyUnit] = useState('ltr/100km');
    const [distance, setDistance] = useState(100);
    const [fuelEconomy, setFuelEconomy] = useState(10);
    const [calculatedPrice, setCalculatedPrice] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();

    useEffect(() => {
        if (distanceUnit === 'km') {
            setFuelEconomyUnit('ltr/100km');
        }
        if (distanceUnit === 'mi') {
            setFuelEconomyUnit('mpg');
        }
    }, [distanceUnit]);

    const calculateDrivingCost = useCallback(() => {
        if (!distance || !fuelPrice || !fuelEconomy) {
            setCalculatedPrice(0);
            return;
        }

        let calculatedCost: number;
        let convertedDistance: number;
        let convertedFuelEconomy: number;
        let convertedFuelPrice: number;

        // Convert distance to kilometers
        convertedDistance =
            distanceUnit === 'mi' ? distance * 1.60934 : distance;

        // Convert fuel economy to L/100km
        if (fuelEconomyUnit === 'mpg') {
            convertedFuelEconomy = 235.214 / fuelEconomy; // Convert MPG to L/100km
        } else {
            convertedFuelEconomy = fuelEconomy;
        }
        // Convert fuel price to $/L
        convertedFuelPrice = fuelPriceUnit.includes('gallon')
            ? fuelPrice / 3.78541
            : fuelPrice;

        // Calculate cost: (distance * fuel economy / 100) * fuel price
        calculatedCost =
            ((convertedDistance * convertedFuelEconomy) / 100) *
            convertedFuelPrice;
        calculatedCost = Math.round(calculatedCost * 100) / 100;

        setCalculatedPrice(calculatedCost);
    }, [
        distance,
        fuelPrice,
        fuelEconomy,
        distanceUnit,
        fuelEconomyUnit,
        fuelPriceUnit
    ]);

    useEffect(() => {
        calculateDrivingCost();
    }, [calculateDrivingCost]);

    const formatCalculatedPrice = () => {
        return calculatedPrice === 0 ? (
            <span>{t('enterAllValues')}</span>
        ) : (
            <h3>{appendCurrency(calculatedPrice)}</h3>
        );
    };

    const contentData = () => {
        return (
            <div className="tracker-content">
                <div
                    className="tracker-content-data"
                    dangerouslySetInnerHTML={{
                        __html: t('drivingCostCalculatorContent', {
                            ns: 'content'
                        })
                    }}
                />
            </div>
        );
    };

    const converterNode = () => {
        const { Option } = Select;

        const selectAfter = (
            <Select
                value={distanceUnit}
                className="select-after"
                popupClassName="calculator-options"
                onChange={(value) => setDistanceUnit(value)}
                suffixIcon={
                    <CaretDown size={24} color="#454545" weight="bold" />
                }
            >
                <Option value="mi">mi</Option>
                <Option value="km">km</Option>
            </Select>
        );

        const selectAfterFuelPriceUnit = (
            <Select
                value={fuelPriceUnit}
                className="select-after"
                popupClassName="calculator-options"
                onChange={(value) => setFuelPriceUnit(value)}
                suffixIcon={
                    <CaretDown size={24} color="#454545" weight="bold" />
                }
            >
                <Option
                    value={`${defaultCurrency}/ltr`}
                >{`${defaultCurrency}/ltr`}</Option>
                <Option
                    value={`${defaultCurrency}/gallon`}
                >{`${defaultCurrency}/gallon`}</Option>
            </Select>
        );

        const selectAfterFuelEconomyUnit = (
            <Select
                value={fuelEconomyUnit}
                className="select-after"
                popupClassName="calculator-options"
                onChange={(value) => setFuelEconomyUnit(value)}
                suffixIcon={
                    <CaretDown size={24} color="#454545" weight="bold" />
                }
            >
                <Option value="ltr/100km">ltr/100km</Option>
                <Option value="mpg">MPG</Option>
            </Select>
        );

        return (
            <div className="calculator-ui-container">
                <div className="calculator-ui">
                    <div className="calc-form" style={{ width: '100%' }}>
                        <div className="calc-form-row">
                            <Space direction="vertical">
                                <label>{t('fuelType')}</label>
                                <div className="custom-select">
                                    <div className="cus-icon">
                                        <GasPump size={20} weight="bold" />
                                    </div>
                                    <Select
                                        value={fuelType}
                                        onChange={(value) => setFuelType(value)}
                                        suffixIcon={
                                            <CaretDown
                                                size={24}
                                                color="#454545"
                                                weight="bold"
                                            />
                                        }
                                        popupClassName="converter-options"
                                    >
                                        <Option value="petrol">Petrol</Option>
                                        <Option value="diesel">Diesel</Option>
                                    </Select>
                                </div>
                            </Space>
                            <Space direction="vertical">
                                <label>{t('distance')}</label>
                                <Input
                                    prefix={<Path size={20} weight="bold" />}
                                    addonAfter={selectAfter}
                                    value={distance}
                                    onChange={(e) =>
                                        setDistance(Number(e.target.value))
                                    }
                                    type="number"
                                />
                            </Space>
                        </div>
                        <div className="calc-form-row">
                            <Space direction="vertical">
                                <label>{t('fuelPrice')}</label>
                                <Input
                                    prefix={<Money weight="bold" size={20} />}
                                    addonAfter={selectAfterFuelPriceUnit}
                                    value={fuelPrice}
                                    onChange={(e) =>
                                        setFuelPrice(Number(e.target.value))
                                    }
                                    type="number"
                                />
                            </Space>
                            <Space direction="vertical">
                                <label>{t('fuelEconomy')}</label>
                                <Input
                                    prefix={
                                        <CarSimple weight="bold" size={20} />
                                    }
                                    addonAfter={selectAfterFuelEconomyUnit}
                                    value={fuelEconomy}
                                    onChange={(e) =>
                                        setFuelEconomy(Number(e.target.value))
                                    }
                                    type="number"
                                />
                            </Space>
                        </div>
                    </div>
                    <div className="calc-result">
                        <h6>{t('estimatedDrivingCost')}</h6>
                        {formatCalculatedPrice()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <SetMeta
                title={t('drivingCostCalculatorTitle', {
                    ns: 'meta',
                    Country_name: getCountryNameFromDomain()
                })}
                desc={t('drivingCostCalculatorDescMeta', {
                    ns: 'meta',
                    Country_name: getCountryNameFromDomain()
                })}
                keywords={t('drivingCostCalculatorKeywords', {
                    ns: 'meta',
                    Country_name: getCountryNameFromDomain()
                })}
                properties={[
                    {
                        key: 'og:title',
                        value: t('drivingCostCalculatorTitle', {
                            ns: 'meta',
                            Country_name: getCountryNameFromDomain()
                        })
                    },
                    {
                        key: 'og:description',
                        value: t('drivingCostCalculatorDescMeta', {
                            ns: 'meta',
                            Country_name: getCountryNameFromDomain()
                        })
                    },
                    {
                        key: 'og:keywords',
                        value: t('drivingCostCalculatorKeywords', {
                            ns: 'meta',
                            Country_name: getCountryNameFromDomain()
                        })
                    }
                ]}
            />
            <Layout showWhiteNavbar>
                <TrackerLayout
                    effectiveDate={''}
                    tableData={[]}
                    tableHeading={''}
                    coverImage="/images/hero/driving-cost-calc.webp"
                    title={t('drivingCostCalculator')}
                    description={t('drivingCostCalculatorDesc', {
                        Country_name: getCitizenDemonym()
                    })}
                    content={contentData()}
                    converterNode={converterNode()}
                />
            </Layout>
        </>
    );
};
export default DrivingCostCalculator;
