import { useEffect, useState } from 'react';

import Layout from '../layout/Layout';

import { useTranslation } from 'react-i18next';
import TrackerLayout from '../components/ui/TrackerLayout';
import { getCountryNameFromDomain } from '../utils/translation';
import { Input } from 'antd';
import { ArrowsLeftRight, Path } from 'phosphor-react';
import { SetMeta } from '../utils/Helper/jsHelpers';

const DistanceConverter = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation();
    const [convertedDistance, setConvertedDistance] = useState('');
    const [distanceToConvert, setDistanceToConvert] = useState(1);
    const [distanceChangeFrom, setdistanceChangeFrom] = useState('mi');
    const [distanceChangeTo, setdistanceChangeTo] = useState('km');

    useEffect(() => {
        let result = 0;

        if (distanceChangeFrom === 'mi' && distanceChangeTo === 'km') {
            result = distanceToConvert * 1.60934;
        } else if (distanceChangeFrom === 'km' && distanceChangeTo === 'mi') {
            result = distanceToConvert * 0.621371;
        } else {
            result = distanceToConvert;
        }

        setConvertedDistance(result.toFixed(4));
    }, [distanceChangeFrom, distanceChangeTo, distanceToConvert]);

    const contentData = () => {
        return (
            <div className="tracker-content">
                <div
                    className="tracker-content-data"
                    dangerouslySetInnerHTML={{
                        __html: t('kmAndMileContent', {
                            ns: 'content'
                        })
                    }}
                />
            </div>
        );
    };

    const converterNode = () => {
        return (
            <div className="converter-ui-container">
                <div className="converter-ui">
                    <div className="" style={{ width: '100%' }}>
                        <label>
                            {distanceChangeTo === 'km'
                                ? t('mile')
                                : t('kilometer')}
                        </label>
                        <Input
                            prefix={<Path size={20} weight="bold" />}
                            suffix={distanceChangeFrom}
                            defaultValue={distanceToConvert}
                            value={distanceToConvert}
                            onChange={(e) =>
                                setDistanceToConvert(
                                    Number(e.target.value) || 0
                                )
                            }
                        />
                    </div>

                    <div
                        style={{
                            height: 16,
                            width: 16,
                            marginTop: 16,
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setdistanceChangeFrom(distanceChangeTo);
                            setdistanceChangeTo(distanceChangeFrom);
                        }}
                    >
                        <ArrowsLeftRight weight="bold" />
                    </div>

                    <div className="" style={{ width: '100%' }}>
                        {distanceChangeTo === 'km' ? t('kilometer') : t('mile')}
                        <Input
                            prefix={<Path size={20} weight="bold" />}
                            suffix={distanceChangeTo}
                            defaultValue={distanceToConvert}
                            value={convertedDistance}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <SetMeta
                title={t('distanceConverterTitle', {
                    ns: 'meta',
                    Name:
                        distanceChangeTo === 'km'
                            ? t('miToKmConverter')
                            : t('kmToMiConverter')
                })}
                desc={t('distanceConverterDescMeta', {
                    ns: 'meta',
                    title:
                        distanceChangeTo === 'km'
                            ? t('miToKmConverter')
                            : t('kmToMiConverter'),
                    Country_name: t(getCountryNameFromDomain()),
                    howToConvert: t('howToConvert', {
                        unit:
                            distanceChangeTo === 'km'
                                ? t('miToKm')
                                : t('kmToMi')
                    })
                })}
                keywords={t('distanceConverterKeywords', {
                    ns: 'meta',
                    Country_name: t(getCountryNameFromDomain()),
                    title:
                        distanceChangeTo === 'km'
                            ? t('miToKmConverter')
                            : t('kmToMiConverter'),
                    op1: t('miToKm'),
                    op2: t('kmToMi')
                })}
                properties={[
                    {
                        key: 'og:title',
                        value: t('distanceConverterTitle', {
                            ns: 'meta',
                            Name:
                                distanceChangeTo === 'km'
                                    ? t('miToKmConverter')
                                    : t('kmToMiConverter')
                        })
                    },
                    {
                        key: 'og:description',
                        value: t('distanceConverterDescMeta', {
                            ns: 'meta',
                            title:
                                distanceChangeTo === 'km'
                                    ? t('miToKmConverter')
                                    : t('kmToMiConverter'),
                            Country_name: t(getCountryNameFromDomain()),
                            howToConvert: t('howToConvert', {
                                unit:
                                    distanceChangeTo === 'km'
                                        ? t('miToKm')
                                        : t('kmToMi')
                            })
                        })
                    },
                    {
                        key: 'og:keywords',
                        value: t('distanceConverterKeywords', {
                            ns: 'meta',
                            Country_name: t(getCountryNameFromDomain()),
                            title:
                                distanceChangeTo === 'km'
                                    ? t('miToKmConverter')
                                    : t('kmToMiConverter'),
                            op1: t('miToKm'),
                            op2: t('kmToMi')
                        })
                    }
                ]}
            />
            <Layout showWhiteNavbar>
                <TrackerLayout
                    effectiveDate={''}
                    tableData={[]}
                    tableHeading={''}
                    coverImage="/images/hero/distance-converter.webp"
                    title={
                        distanceChangeTo === 'km'
                            ? t('miToKmConverter')
                            : t('kmToMiConverter')
                    }
                    description={t('distanceConverterDesc')}
                    content={contentData()}
                    hideTableHeader
                    converterNode={converterNode()}
                />
            </Layout>
        </>
    );
};
export default DistanceConverter;
